import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
const json = {
	1: {
		unit: 'Introduction',
		id: 'FGF11-I-P4-E1',
		audio: '',
		video: '',
		component: T6,
		inputSize: 175,
		isHiddenCheck: true,
		maxLength: 13,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page4/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				Label the pictures with the words below.
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 22,
			height: 27,
			// textTransform: 'uppercase',
		},
		hintBox: [
			{
				src: [
					'castle',
					'harbour',
					'monument',
					'opera house',
					'ruins',
					'square',
					'statue',
					'zoo',
				],
				borderColor: 'none',
				width: 900,
				fontColor: '#93338e',
				fontSize: 23,
			},
		],
		questions: [
			{
				title: `
        <b><hintbox id=0></hintbox></b>
        <div style=" position: relative; padding-top:10px; ">
          <div><img style="width: 25cm" src='img/FriendsPlus/Page4/E1/1.jpg';/></div>

          <div style=" position: absolute; top: 151px; left: 39px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 151px; left: 275px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 151px; left: 537px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 151px; left: 761px"><input id='3' ></input></div>
          
          <div style=" position: absolute; top: 312px; left: 39px"><input id='4' ></input></div>
          <div style=" position: absolute; top: 312px; left: 275px"><input id='5' ></input></div>
          <div style=" position: absolute; top: 312px; left: 537px"><input id='6' ></input></div>
          <div style=" position: absolute; top: 312px; left: 761px"><input id='7' ></input></div>

        </div>

				`,
				answer: [
					`statue`,
					`zoo`,
					`opera house`,
					`square`,
					`harbour`,
					`castle`,
					`ruins`,
					`monument`,
				],
			},
		],
	},
	2: {
		unit: 'Introduction',
		id: 'FGF11-I-P4-E2',
		audio: '',
		video: '',
		component: T6,
		inputSize: 150,
		isHiddenCheck: true,
		maxLength: 10,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page4/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Where can you do these things? Complete the words.
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 27,
			// textTransform: 'uppercase',
		},
		questions: [
			{
				title: `
        <div style=" position: relative; padding-top:10px; ">
          <div><b>1 </b> see animals from places like Africa in natural, open spaces: w# p#</div>
          <div><b>2 </b> see unusual fish: a#</div>
          <div><b>3 </b> buy fruit, vegetables, etc.: m#</div>
          <div><b>4 </b> find a wide variety of shops: s# d#</div>
          <div><b>5 </b> see beautiful countryside: n# p#</div>
          <div><b>6 </b> see where kings and queens live or lived: p#</div>
          <div><b>7 </b> see ships and boats: h#</div>
          <div><b>8 </b> see a play: t#</div>
          <div><b>9 </b> see a lot of beautiful, old buildings: o# t#</div>
          <div><b>10 </b> see interesting, valuable, old objects: m#</div>
          <div><b>11 </b> climb up high and get a good view: t#</div>
          <div><b>12 </b> go on rides: t# p#</div>
        </div>

				`,
				answer: [
					`ildlife`,
					`ark`,
					`quarium`,
					`arket`,
					`hopping`,
					`istrict`,
					`ational`,
					`ark`,
					`alace`,
					`arbour`,
					`heatre`,
					`ld`,
					`own`,
					`useum`,
					`ower`,
					`heme`,
					`ark`,
				],
			},
		],
	},
	3: {
		unit: 'Introduction',
		id: 'FGF11-I-P4-E3',
		audio: '',
		video: '',
		component: T6,
		inputSize: 150,
		isHiddenCheck: true,
		maxLength: 16,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page4/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `
				Complete the postcard with the verbs below. Use the past simple.
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 19,
			height: 25,
			// textTransform: 'uppercase',
		},
		hintBox: [
			{
				src: [
					'not be',
					'not buy',
					'go',
					'go',
					'have',
					'play',
					'sunbathe',
					'not want',
				],
				borderColor: 'none',
				width: 800,
				fontColor: '#93338e',
			},
		],
		questions: [
			{
				title: `
       <b><hintbox id=0></hintbox></b>
        <div style=" position: relative;">
          <div><img style="width: 19.5cm; height: 14cm" src='img/FriendsPlus/Page4/E3/1.jpg';/></div>

          <div style=" position: absolute; top: 103px; left: 286px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 133px; left: 135px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 163px; left: 99px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 194px; left: 167px"><input id='6' ></input></div>

          <div style=" position: absolute; top: 225px; left: 99px"><input id='3' ></input></div>
          <div style=" position: absolute; top: 255px; left: 235px"><input id='4' ></input></div>
          <div style=" position: absolute; top: 286px; left: 226px"><input id='5' ></input></div>
          <div style=" position: absolute; top: 316px; left: 278px"><input id='7' ></input></div>

        </div>

				`,
				answer: [
					`went`,
					`didn't want`,
					`sunbathed`,
					`wasn't`,
					`went`,
					`didn't buy`,
					`played`,
					`had`,
				],
			},
		],
	},
	4: {
		unit: 'Introduction',
		id: 'FGF11-I-P4-E4',
		audio: '',
		video: '',
		component: T6,
		inputSize: 150,
		isHiddenCheck: true,
		checkUppercase: true,
		maxLength: 8,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page4/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
				Complete the dialogue with the verbs below. Use the past simple.
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 27,
			// textTransform: 'uppercase',
		},
		hintBox: [
			{
				src: [
					'be',
					'do',
					'go',
					'go',
					'go away',
					'have',
					'hire',
					'stay',
					'stay',
					'visit',
					'visit',
				],
				borderColor: 'none',
				width: 800,
				fontColor: '#93338e',
			},
		],
		questions: [
			{
				title: `
				<b><hintbox id=0></hintbox></b>
        <div style="display: flex; margin-top: 30px">
          <div style='margin-right: 40px'>
            <b>James</b><br>
            <b>Lucy</b><br>
            <b>James</b><br>
            <b>Lucy</b><br><br><br>
            <b>James</b><br>
          </div>
          <div>
           <div><sup>1 </sup># you # a good holiday?</div>
           <div>Yes, it <sup>2 </sup># good. We <sup>3 </sup>#  my 
           cousin in Germany.</div>
           <div>Cool. What <sup>4 </sup># you # ?</div>
           <div>We <sup>5 </sup># on the Baltic coast. We <sup>6 </sup># bikes and<br> we <sup>7 </sup>#
           on a boat trip. What about you? <sup>8 </sup># you<br> # ?</div>
           <div>No, I <sup>9 </sup># here. I <sup>10 </sup># on
           a couple of excursions<br> with my family, and
           I  <sup>11 </sup># a theme park with Josh.</div>
          </div>

        </div>

				`,
				answer: [
					`Did`,
					`have`,
					`was`,
					`visited`,
					`did`,
					`go`,
					`stayed`,
					`hired`,
					`went`,
					`Did`,
					`go away`,
					`stayed`,
					`went`,
					`visited`,
				],
			},
		],
	},
	5: {
		unit: 'Introduction',
		id: 'FGF11-I-P4-E5',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		maxLength: 300,
		textAlign: 'left',
		exerciseKey: 'img/FriendsPlus/Page4/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
				What did you do in the summer? Write three sentences. Use the past simple.
				`,
				color: 'black',
			},
		],
		textareaStyle: {
			// background: 'none',
			width: 850,
			fontSize: 22,
			height: 120,
		},

		questions: [
			{
				title: `
        <div style=" margin-top: 30px">
          <div style='display: flex'><b>1 </b><textarea id='0' rows='3'></div>
          <div style='display: flex'><b>2 </b><textarea id='1' rows='3'></div>
          <div style='display: flex'><b>3 </b><textarea id='2' rows='3'></div>
        </div>

				`,
				answer: [``],
			},
		],
	},
}

export default json
