import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
// import Circle_Write2 from "../../components/ExcerciseTypes/Circle_Write2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E1",
    exerciseKey: "img/FriendsPlus/Page47/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct words to complete the concession clauses.					`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
          border: '2px solid white',
          padding:'0px 4px',
      
          },
          selectWordStyle: {
          padding:'0px 4px',
          border: '2px solid black',
          }, 
        limitSelect: 1,
        listWords: [
          `in_spite_of / even_though`, // 0
          `Despite / Although`, // 1
          `even_though / despite`, // 2
          `in_spite_of / although`, // 3
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: {},
      },
      Layout: `
    <div style='line-height:2'>
    <div style=';margin: 10px 10px;'>
    <span style="font-weight: bold;">1. </span>
    The image isn’t very clear <input id='0' type='Circle' /> the size of the screen. 
  </div>

  <div style=';margin: 10px 10px;'>
    <span style="font-weight: bold;">2. </span>
    <input id='1' type='Circle' /> I don’t like video games, I bought the games console anyway.  
  </div>

  <div style=';margin: 10px 10px;'>
    <span style="font-weight: bold;">3. </span>
    It was quite expensive  <input id='2' type='Circle' />  it was in  the sale. 
  </div>

  <div style=';margin: 10px 10px;'>
    <span style="font-weight: bold;">4. </span>
    I don’t agree, <input id='3' type='Circle' />  it’s obviously not a good idea to play for more than a few hours at a time. 
  </div>

 

  
    </div>
			
				`,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E2",
    exerciseKey: "img/FriendsPlus/Page47/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 650,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
      background: "none",
    },
    isHiddenCheck: true,
    // maxLength: 5,
    textareaStyle: { width: 590, fontSize: "25px",padding:5 },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the internet forum post with three of the sentences in exercise 1.


				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
     

      
      
       
      
        <div style=" position: relative; ">
        <div>  <img src='img/FriendsPlus/Page47/E2/1.jpg' style='width: 85%;' /> </div>
        
        <div style=" position: absolute;  top:507px; left: 60px; width:650px; ">
        I bought a new games console last week from a website. <br/><div style='display:flex;margin-top:10px'> <sup>1</sup> <textarea id='0' rows='2'></textarea></div> <br/> 
<div style='margin-top:-60px'>
It’s a handheld console with a large screen and controls <br/> for your thumbs on each end. 
Fifty games are built into the console, and you can download more from the internet.  Overall, I’m very happy with it. The games are certainly <br/> fun. I have one criticism:
</div>  <div style='display:flex;margin-top:10px'> <sup>2</sup> <textarea id='1' rows='2'></textarea></div> <br/>
<div style='margin-top:-60px'>
Some people think that teenagers should spend less time playing computer games because it’s bad for their health.
</div> <div style='display:flex;margin-top:20px'> <sup>3</sup> <textarea id='2' rows='2'></textarea></div> <br/>
        </div>
       
    </div>



       
        `,
        answer: [
          `It was quite expensive even though it was in the sale.`,
          `The image isn't very clear in spite of the size of the screen.`,
          `I don't agree, although it's obviously not a good idea to play for more than a few hours at a time.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 220,

    selectOptionValues: ["_", "1", "2", "3", "4"],
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      borderRadius: "3px",
      width: 35,
      height: 35,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      fontWeight: "bold",
    },
    isHiddenCheck: true,
    maxLength: 1,

    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(87,154,208)'>Writing Strategy</span>. Then read the points a–e below. Number four points in the order they appear in the text in exercise 2. There is one extra point. 		`,
        color: "#000000",
      },
    ],
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    questionImage: [],
    questions: [
      {
        title: `
      <div style='padding: 10px; border: 5px dotted rgb(87,154,208); border-radius: 20px; width: 750px; background-color: rgb(229, 239, 230);'>
        <span style='color: rgb(87,154,208); font-weight: bold;'>Writing Strategy</span> <br> 
        <div style='margin-top: 10px;'>
        Each point in the task will ask you to do a different thing 
        such <br/> as <i>describe, recommend, relate, express an opinion, suggest,</i> etc. 
        Read the task carefully and make sure that you understand exactly
        <br/> what you are being asked to do.

        </div>
      </div>

       <b>The writer: </b>
      <div style='margin-top:10px;line-height:1.75'>
      <div style='display:flex'>
      <b>a</b>&ensp;compares it with another console. <select id=0 initialValue='0'></select>

      </div>
      <div style='display:flex'>
      <b>b</b>&ensp;presents the opinions of people who think teenagers spend too much time playing video games.  <select id=1 initialValue='0'></select>
      </div>
      <div style='display:flex'>
      <b>c</b>&ensp;describes some of its features.  <select id=2 initialValue='0'></select>
      </div>
      <div style='display:flex'>
      <b>d</b>&ensp;compares it with another console. <select id=3 initialValue='0'></select>
      </div>
      <div style='display:flex'>
      <b>e</b>&ensp;says when and where she bought the games console.  <select id=4 initialValue='0'></select>
      </div>
      </div>
      `,
        answer: [`_`, `4`, `3`, `2`, `1`],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E4",
    exerciseKey: "img/FriendsPlus/Page47/Key/E4answerKey.png",
    component: Circle_Write,
    inputSize: 70,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 8,
    // hideBtnFooter: true,
    textareaStyle: { width: 820 },
    titleQuestion: [
      {
        num: "4",
        title: `
				Read the task above. Underline the verb in each point that describes what you should do. The first one is already underlined.  
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "-40px",
          color: "",
          // borderRadius: "50%",
          // fontWeight: "700",
        },
        selectWordStyle: { borderBottom: "3px solid black" },
        limitSelect: 1,
        listWords: [
          `compare it with another phone which you have used.`, // 0
          `present the opinions of people who think that mobile <br/>
          phones should be banned in school.`, // 1
          `ask other forum contributors to react to your forum post.`, // 2
        ],
        answers: ["0-0", "1-0", "2-0"],
        initialValue: {},
      },
      Layout: `
        <div style='border-radius:10px;width:700px;padding:10px 20px;background-color:rgb(234,238,259)'>
        <b>You recently bought a new phone. Write a forum post in </b> <br/>
        <b>which you: </b>
        <ul>
        <li><span style='border-bottom:3px solid black'>describe</span> to other forum contributors what you use it for.  </li>
        <li><input id='0'  type='Circle' /> </li>
        <li><input id='1'  type='Circle' />  </li>
        <li><input id='2'  type='Circle' /> </li>

        </ul>
        </div>
			
				`,
    },
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E5",
    // exerciseKey: 'img/FriendsPlus/Page44/Key/E5answerKey.png',
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    maxLength: 10,
    hideBtnFooter: true,
    textareaStyle: { width: 820,padding:5 },
    titleQuestion: [
      {
        num: "5",
        title: `
        Make notes for each of the four points in the task. 
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b>1</b>
        <textarea id='0' rows='2'></textarea>
        </div>
        <div style='display:flex'>
        <b>2</b>
        <textarea id='1' rows='2'></textarea>
        </div>
        <div style='display:flex'>
        <b>3</b>
        <textarea id='2' rows='2'></textarea>
        </div>
        <div style='display:flex'>
        <b>4</b>
        <textarea id='3' rows='2'></textarea>
        </div>


       
        `,

        answer: [`adjective`, `something`, `somebody`, `opposite`, `adverb`],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P47-E6",
    // exerciseKey: 'img/FriendsPlus/Page44/Key/E5answerKey.png',
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    maxLength: 10,
    hideBtnFooter: true,
    textareaStyle: { width: 820 ,padding:5 },
    titleQuestion: [
      {
        num: "6",
        title: `
        Write your forum post using your notes.

				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
        
        <textarea id='0' rows='7'></textarea>
       


       
        `,

        answer: [`adjective`, `something`, `somebody`, `opposite`, `adverb`],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "FGF11-U5-P47-E7",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 3,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page47/E7/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page47/E7/2.jpg" },
        { url: "img/FriendsPlus/Page47/E7/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page47/E7/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page47/E7/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page47/E7/6.jpg" },
        { url: "img/FriendsPlus/Page47/E7/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page47/E7/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page47/E7/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page47/E7/10.jpg" },
        { url: "img/FriendsPlus/Page47/E7/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page47/E7/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page47/E7/13.jpg" }],
    ],
  },
};

export default json;
