import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P13-E1",
    // audio: 'Audios/1.02.mp3',
    video: "",
    component: T6,
    inputSize: 180,
    isHiddenCheck: true,
    maxLength: 16,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page13/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				<span style='font-size: 24px'>Complete the table.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 22px; width: max-content">
          <div><img style="width: 17cm" src='img/FriendsPlus/Page13/E1/1.jpg';/></div>

					<div style=" position: absolute; top: 134px; left: 86px">1</div>
          <div style=" position: absolute; top: 134px; left: 125px">adolescence</div>
					<div style=" position: absolute; top: 134px; left: 339px">#</div>
					
          <div style=" position: absolute; top: 174px; left: 86px">2</div>
          <div style=" position: absolute; top: 174px; left: 125px">#</div>
					<div style=" position: absolute; top: 174px; left: 339px">dependent</div>

          <div style=" position: absolute; top: 214px; left: 86px">3</div>
          <div style=" position: absolute; top: 214px; left: 125px">freedom</div>
					<div style=" position: absolute; top: 214px; left: 339px">#</div>

          <div style=" position: absolute; top: 253px; left: 86px">4</div>
          <div style=" position: absolute; top: 253px; left: 125px">#</div>
					<div style=" position: absolute; top: 253px; left: 339px">emotional</div>

          <div style=" position: absolute; top: 292px; left: 86px">5</div>
          <div style=" position: absolute; top: 292px; left: 125px">privacy</div>
					<div style=" position: absolute; top: 292px; left: 339px">#</div>

          <div style=" position: absolute; top: 332px; left: 86px">6</div>
          <div style=" position: absolute; top: 332px; left: 125px">impatience</div>
					<div style=" position: absolute; top: 332px; left: 339px">#</div>

          <div style=" position: absolute; top: 372px; left: 86px">7</div>
          <div style=" position: absolute; top: 372px; left: 125px">#</div>
					<div style=" position: absolute; top: 372px; left: 339px">concerned</div>

          <div style=" position: absolute; top: 411px; left: 86px">8</div>
          <div style=" position: absolute; top: 411px; left: 125px">safety</div>
					<div style=" position: absolute; top: 411px; left: 339px">#</div>

          <div style=" position: absolute; top: 450px; left: 86px">9</div>
          <div style=" position: absolute; top: 450px; left: 125px">#</div>
					<div style=" position: absolute; top: 450px; left: 339px">irritated</div>
					

        </div>

				`,
        answer: [
          `adolescent`,
          `dependence`,
          `free`,
          `emotion`,
          `private`,
          `impatient`,
          `concern`,
          `safe`,
          `irritant`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P13-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 12,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page13/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style='font-size: 25px'>Complete the sentences with the correct form of the 
        nouns and adjectives in exercise 1.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      width: 170,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        
        <div style="display: flex; margin-top: 30px; line-height: 50px; font-size: 22px">
        <img style="width:17cm; height:10cm" src='img/FriendsPlus/Page13/E2/1.png' />
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
						<b>5&ensp;</b><br>
          </div>
          <div>
            <div>My diary is # – I don’t let anyone else read it.</div>
						<div>Mark is always complaining that his parents don’t 
            give him enough # .He wants to 
            do what he likes when he likes.</div>
						<div>Kathy hates waiting for people. She’s so # !</div>
						<div># can be a difficult stage in life.</div>
						<div>Strong # such as love and anger are 
            sometimes difficult to deal with.</div>
						
					</div>
        </div>

				`,
        answer: [`private`, `freedom`, `impatient`, `Adolescence`, `emotions`],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P13-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page13/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `<div style='font-size:22px'>Read the text. Choose the best summary.</div>
  		  `,
        color: "black",
        // prefix: { icon: [''], text: '' },
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `1 <br> 2 <br> 3`, //0 c
        ],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
			<div><img style='width:28cm; height: 20cm' src='img/FriendsPlus/Page13/E3/1.jpg'></div>
       <div style='display:flex; flex-direction:column; background-color: white; height:150px'>
          <div style='position:relative; height:fit-content'>
          <div style=" position: absolute; top: 15px; left: 28px; font-size:20px;">
            <div style="display: flex;">
              <div><input id='0' type='Circle'/></div>
              <div style='margin-left:20px'>
                The Kardashians agreed to make a TV show, but they think it’s had a bad effect on the family.<br>
                The Kardashians’ show was successful, but the family are now too busy to make more shows.<br>
                The Kardashians’ show has many viewers, but it doesn’t appeal to everybody.
              </div>
            </div>
          </div>  
      </div>
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P13-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page13/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 24px'>Read the <span style='color: rgb(63,61,161)'>Reading Strategy</span>. Then match sentences A–F with 
        gaps 1–4 in the text. There are two extra sentences.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 50,
      textTransform: "uppercase",
    },

    questions: [
      {
        title: `
        <img style='width:14cm; height: 6cm' src='img/FriendsPlus/Page13/E4/1.jpg'>
        <img style='width:27cm; height: 17cm' src='img/FriendsPlus/Page13/E3/1.jpg'>
        
        <div style="display: flex; margin-top: 10px; line-height: 35px; font-size: 22px">
        
          <div style="margin-right: 20px; padding-left: 20px">
            <b>A&ensp;</b><br>
            <b>B&ensp;</b><br>
            <b>C&ensp;</b><br>
						<b>D&ensp;</b><br>
						<b>E&ensp;</b><br>
            <b>F&ensp;</b><br>
          </div>
          <div>
            <div>She has three girls – Kourtney, Kim and Khloé – and one son, Rob.</div>
						<div>Throughout the series, many family events have been shown.</div>
						<div>But who are they and why are they famous?</div>
						<div>Other reality TV shows are about singing, dancing, or cooking.</div>
						<div>A lot of Americans disapprove of the family’s behaviour on TV and in the press.</div>
						<div>Kim married rap star Kanye West and has a daughter called North.</div>
					</div>
          

        </div>
        <div style='margin-top: 20px; display:flex;'>
              <b>Answer:&ensp;</b><br>
              &ensp;<b>1 - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>2 - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>3 - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>4 - #&ensp;&ensp;&ensp;</b>
          </div>
				`,
        answer: [`C`, `A`, `B`, `E`],
      },
    ],
  },
};

export default json;
