import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 100,
    isHiddenCheck: true,
    maxLength: 8,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				<span style='font-size: 24px'>Complete the labels for the pictures with the words 
        below. There are three extra words.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "curry",
          "pie",
          "pudding",
          "<s>risotto</s>",
          "salad",
          "sandwich",
          "soup",
          "stew",
          "stir-fry",
        ],
        borderColor: "none",
        width: 300,
        fontColor: "#93338e",
        fontSize: 18,
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; position: relative'>
        <hintbox id=0></hintbox>
        <div style=" position: relative; padding-top:10px; font-size: 19px; width: max-content">
          <div><img style="width: 14cm" src='img/FriendsPlus/Page18/E1/1.jpg';/></div>

          <div style=" position: absolute; top: 208px; left: 336px">#</div>

          <div style=" position: absolute; top: 387px; left: 130px">#</div>
          <div style=" position: absolute; top: 387px; left: 355px">#</div>
          
          <div style=" position: absolute; top: 573px; left: 150px">#</div>
          <div style=" position: absolute; top: 573px; left: 366px">#</div>

        </div>
        </div>
				

				`,
        answer: [`salad`, `stir-fry`, `pie`, `curry`, `soup`],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 350,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 30,
    textAlign: "center",
    // exerciseKey: 'img/FriendsPlus/Page18/Key/E1answerKey.png',
    titleQuestion: [
      {
        num: "2",
        title: `
				<span style='font-size: 25px'>Think about dishes which are popular in your country. 
        Write down.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        
        <div style=" position: relative; padding-top:10px; font-size: 23px; width: max-content; line-height: 50px">
          <div>two types of curry or stew.</div>
          <div># , #</div>
          <div>two types of salad or sandwich.</div>
          <div># , #</div>
          <div>two types of soup or pie.</div>
          <div># , #</div>
        </div>
				`,
        answer: [``],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E3",
    audio: "Audios/1.08 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='1.08' src='Audios/1.08 tieu de.mp3'></headphone> Read the <span style='color: rgb(217,106,137)'>Listening Strategy</span>. Then listen and circle the correct answer. 
				`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          color: "rgb(88,147,198)",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `after / before`, //0
          `inside / outside`, // 1
          `day / restaurant`, // 2
          `father / son`, // 3
        ],
        answers: ["0-4", "1-4", "2-4", "3-0"],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>a&ensp;</b>shortly <input id='0' type='Circle' /> dinner</div>
            <div style='margin-bottom:20px'><b>b&ensp;</b><input id='1' type='Circle' /> a restaurant</div>
            <div style='margin-bottom:20px'><b>c&ensp;</b>They might have the wrong <input id='2' type='Circle' /></div>
            <div style='margin-bottom:20px'><b>d&ensp;</b>a woman and her <input id='3' type='Circle' /></div>
            
					</div>
          
        `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E4",
    audio: "Audios/1.09 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E4answerKey.png",
    component: Circle_Write,
    totalInput: 4,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.09' src='Audios/1.09 tieu de.mp3'></headphone> Listen to two short dialogues. Read the questions 
				about context and circle the correct answers.
			  `,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
          border: '2px solid white',
          padding:'0px 4px',
      
          },
          selectWordStyle: {
          padding:'0px 4px',
          border: '2px solid black',
          }, 
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1

        ],
        answers: ['0-4', '1-8', '2-0', '3-0'],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
     
        <div>
        <div><b>Dialogue 1</b></div>
            <b>1</b>&ensp;
            Who is the woman talking to? <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              A waiter<br>
              The man she’s having dinner with<br>
              A man at the next table
                    </div>
                       </div>

            <b>2</b>&ensp;
            Why is the woman unhappy with her food?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      It isn’t what she ordered.<br>
              It doesn’t look very tasty. <br>
              She can’t eat it
		          </div>
            </div>
           </div>
           <div style='margin-left:100px'>
           <div><b>Dialogue 2</b></div>
           <b>3</b>&ensp;
              Where is the conversation taking place?
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      At home <br>
              In a restaurant <br>
              In the town centre
		          </div>
            </div>
                        <b>4</b>&ensp;
                        What time is it, approximately?
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      7 p.m.<br>
              9 p.m.<br>
              11 p.m.

		          </div>
            </div>
            </div>
      </div>

                        </div>


        
      `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 8,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page18/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `<span style='font-size: 24px'>Complete the useful phrases with the words below.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 170,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["bit", "nothing", "pretty", "real", "up", "world"],
        borderColor: "none",
        width: 500,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
						<b>5&ensp;</b><br>
						<b>6&ensp;</b><br>
          </div>
          <div>
            <div>a # special</div>
						<div>a # let-down</div>
						<div># special</div>
						<div># average</div>
						<div>not # to standard</div>
						<div>out of this #</div>
          </div>
        </div>

				`,
        answer: [`bit`, `real`, `nothing`, `pretty`, `up`, `world`],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FGF11-U2-P18-E6",
    audio: "Audios/1.10 audio.mp3",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 8,
    textAlign: "center",
    // exerciseKey: 'img/FriendsPlus/Page18/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title: `<span style='font-size: 24px'><headphone name='1.10' src='Audios/1.10 tieu de.mp3'></headphone> Listen to a dialogue between two friends. Check 
				your answers to exercise 5.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 170,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <img style="width:17cm" src='img/FriendsPlus/Page18/E6/1.png' />

				`,
        answer: [``],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit 2",
    id: "FGF11-U2-P18-E7",
    audio: "Audios/1.10 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E7answerKey.png",
    inputSize: 1,
    isHiddenCheck: true,
    checkUppercase: true,
    component: T6,
    textAlign: "left",
    // totalInput: 2,
    titleQuestion: [
      {
        num: "7",
        title:
          '<span style="font-size: 25px"><headphone name="1.10" src="Audios/1.10 tieu de.mp3"></headphone> Listen again. For each question, write the correct speaker: Tom (T) or Zoë (Z).</span>',
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 26,
      textTransform: "uppercase",
    },

    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    // selectOptionRandom: true,
    selectOptionValues: ["T", "Z"],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='font-size:23px'>
				<b>Which person …</b>
          <div style='display: flex'><b>1&ensp;</b>is planning to book a restaurant? &ensp;<select id=0></select></div>
          
          <div style='display: flex'><b>2&ensp;</b>can’t remember last year’s meal? &ensp;<select id=1></select></div>
          
          <div style='display: flex'><b>3&ensp;</b>always checks online reviews for restaurants? &ensp;<select id=2></select></div>
          
          <div style='display: flex'><b>4&ensp;</b>recommended an Italian restaurant? &ensp;<select id=3></select></div>
         
          <div style='display: flex'><b>5&ensp;</b>is going to ask about a special diet? &ensp;<select id=4></select></div>
          
        </div> 
        `,
        answer: ["Z", "T", "T", "T", "Z"],
      },
    ],
  },
};

export default json;
