import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P17-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page17/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct answers.
				`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          color: "rgb(88,147,198)",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `went / has_gone`, //0
          `Did_you_finish / Have_you_finished`, // 1
          `had / have_had`, // 2
          `read / have_read`, // 3
          `already_ate / has_already_eaten`, // 4
          `Did_you_go / Have_you_been`, // 5
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-0"],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>Fran <input id='0' type='Circle' /> ballroom dancing last night.</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b><input id='1' type='Circle' /> vlogging yet?</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b>I <input id='2' type='Circle' /> this camera for over a year.</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b>I <input id='3' type='Circle' /> the whole magazine in an hour.</div>
            <div style='margin-bottom:20px'><b>5&ensp;</b>Sam isn’t hungry because he <input id='4' type='Circle' />.</div>
						<div style='margin-bottom:20px'><b>6&ensp;</b><input id='5' type='Circle' /> cycling last weekend?</div>
					</div>
          
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P17-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 50,
    inputSize: 750,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page17/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style=''>Correct the mistakes in the sentences.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
				<div><i style='font-size:20px; color: rgb(152,198,204)'>*Rewrite the full sentence.</i></div>
        <div style="display: flex; font-size: 22px; line-height: 45px">
				
          <div style='font-size: 22px'>
            <div><b>1 </b> I didn’t go rollerblading before. Is it fun?</div>
              <div>#</div>
						<div><b>2 </b> I’ve fallen over while I was ice skating.</div>
              <div>#</div>
						<div><b>3 </b> Did Jasmine text you yet?</div>
              <div>#</div>
						<div><b>4 </b> Dan has gone bowling on his birthday.</div>
              <div>#</div>
						<div><b>5 </b> I didn’t go camping since last summer.</div>
              <div>#</div>
        </div>

				`,
        answer: [
          `I haven't been rollerblading before. Is it fun?`,
          `I fell over while I was ice skating.`,
          `Has Jasmine texted you yet?`,
          `Dan went bowling on his birthday.`,
          `I haven't been camping since last summer.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P17-E3",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 15,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page17/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `<span style='font-size: 24px'>Complete the sentences with the verbs in brackets. Use 
				the past simple in one sentence and the present perfect in the other.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      width: 200,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 30px; font-size: 22px; line-height: 45px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br><br>
						<b>4&ensp;</b><br><br>
          </div>
          <div>
            <div><b>a&ensp;</b>I # skateboarding lots of times. (go)</div>
            <div><b>b&ensp;</b>Katie # shopping yesterday. (go)</div>
            <div><b>a&ensp;</b>When # you # that cake? (bake)</div>
            <div><b>b&ensp;</b>I # cakes since I was ten. (bake)</div>
            <div><b>a&ensp;</b>Joe loves karate. He # it for ages. (do)</div>
            <div><b>b&ensp;</b>I # ballet for a year, but I gave it up. (do)</div>
						<div><b>a&ensp;</b><i>Northern Lights</i> is a great book.# you # it? (read)</div>
            <div><b>b&ensp;</b>I # four novels last month. (read)</div>
            </div>
        </div>

				`,
        answer: [
          `'ve gone`,
          `went`,
          `did`,
          `bake`,
          `'ve baked`,
          `'s done`,
          `did`,
          `Have`,
          `read`,
          `read`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FGF11-U2-P17-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 500,
    maxLength: 65,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page17/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 24px'><span style='color: orange'>USE OF ENGLISH </span>Complete the second sentence so that it has 
				a similar meaning to the first. Use the words in brackets and 
				the past simple or present perfect. You will need to add other 
				words.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 10px; font-size: 22px">
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br><br>
            <span><b>2 </b></span><br><br>
            <span><b>3 </b></span><br><br>
            <span><b>4 </b></span><br><br>
            <span><b>5 </b></span><br><br>
						<span><b>6 </b></span><br><br>
						<span><b>7 </b></span><br>
          </div>
        
        <div>
					<span>I can still remember my tenth birthday party. (never)</span><br>
          <span style='color: rgb(152,198,204)'>I have never forgotten my tenth birthday party.</span><br>
          <span>Tomorrow, Jason will go horse riding for the first time. (never)</span><br>
          <span>Jason # horse riding before</span><br>
          <span>I haven’t played table tennis for a year. (ago)</span><br>
          <span>I last #.</span><br>
          <span>Tom has just texted me. (a moment ago)</span><br>
					<span>Tom #.</span><br>
					<span>The last time I vlogged was a year ago. (for)</span><br>
					<span>I # a year.</span><br>
					<span>Is this your first time at this gym? (ever)</span><br>
					<span># before?</span><br>
					<span>My brother has collected stamps since he was six. (collecting)</span><br>
					<span>My # when he was six.</span>
        </div>

        </div>

				`,
        answer: [
          `has never been`,
          `played table tennis a year ago`,
          `texted me a moment ago`,
          `haven't vlogged for`,
          `Have you ever been to this gym`,
          `brother started collecting stamps`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FGF11-U2-P17-E5",
    audio: "",
    video: "",
    component: T6,
    inputSize: 250,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 25,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page17/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `
				Complete the interview with the past simple or present perfect form of the verbs in brackets.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 30px; font-size: 22px; line-height:2">
          <div style='margin-right: 40px'>
            <b>Interviewer</b><br>
            <b>Tiffany</b><br><br>

            <b>Interviewer</b><br>
            <b>Tiffany</b><br><br>

            <b>Interviewer</b><br>
						<b>Tiffany</b><br><br>

						<b>Interviewer</b><br>
						<b>Tiffany</b><br><br>

						<b>Interviewer</b><br>
						
          </div>
          <div style='font-size: 22px'>
           	<div>When <sup>1 </sup># you # (start) rollerblading?</div>
           
						<div>I first <sup>2 </sup># (go) rollerblading when I was nine. I <sup>3 </sup># (stop) 
						for a few years, then I <sup>4 </sup># (take) it up again when I was in my teens.</div>

						<div>How often do you practise?</div>

						<div>As often as I can. But it <sup>5 </sup># (be) very wet recently, so<br> I <sup>6 </sup>#
						(not be able) to get out on the streets much, which is very frustrating. </div>

						<div>Who are your favourite rollerbladers?</div>

						<div>I love watching Chris Haffey. I 
						<sup>7 </sup># (always / admire) him.<br> In 
						2011, he <sup>8 </sup># (break) the world 
						record for the longest jump – 30 metres!</div>

						<div><sup>9 </sup># you # (have) much success in competitions?</div>

						<div>Yes, I <sup>10 </sup># (win) quite a few competitions. I <sup>11 </sup># (come) 
						first in the 2014 Street Rollerblading Open Championship.</div>

						<div> That’s fantastic!</div>

				</div>

				`,
        answer: [
          `did`,
          `start`,
          `went`,
          `stopped`,
          `took`,
          `has been`,
          `haven't been able to`,
          `'ve always admired`,
          `broke`,
          `Have`,
          `had`,
          `'ve won`,
          `came`,
        ],
      },
    ],
  },
};

export default json;
