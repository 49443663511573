import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-CR-P71-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page71/E1/key/E1answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 140,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "24px",
      // fontWeight: "bold",
      textAlign: "center",
      borderBottom: "2px solid gray",
      // borderRadius: "5px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Read <span style= "color: rgb(29,134,202)">Writing Strategy 1</span>.
         Then write the two missing words at the start and end of the letter.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page71/E1/1.jpg" width="600px"/>
        <div style= "margin-top: 30px; width: 850px; background-color: rgb(206,207,209)">
         <div style= "padding: 40px 30px 35px 50px">
           <span>Dear Sir or</span>&ensp;<sup>1</sup>
           <input id='0'/>
           <span>,</span>
           <div>I am writing to enquire about the possibility of renting Hillside Cottage for three weeks in the summer.</div>
           <div>I will be travelling to the Lake District with my parents and brother. We plan to arrive in London on 14 July, but do notwish to hire a car. I would be grateful if you could inform me of the best way to reach the cottage by public transport.</div>
           <div>Although the advertisement states that the cottage has two bedrooms, it is not clear whether the second room is large enough for two people to share. I wonder if you could clarify this point.</div>
           <div>Finally, I would like to know what leisure activities are available in the area surrounding the cottage. We are particularly interested in horse riding.</div>
           <div>I look forward to receiving your reply in due course.</div>
           <span>Yours</span>&ensp; <sup>2</sup>
           <input id='1'/>
           <span>,</span> <br>
           <span>Tom Baker</span>
         </div>
        </div>
					`,
        answer: ["Madam", "faithfully"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-CR-P71-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page71/E2/key/E2answerKey.png",
    component: T6v2,
    maxLength: 50,
    inputSize: 300,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      borderBottom: "2px solid gray",
      // borderRadius: "5px",
      height: 29,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Underline in the letter more formal ways of saying:
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style= "display: flex; width: 850px; margin-left: 50px; font-size: 22px">
         <div style= "width: 565px; display: flex">
           <div style= "width: 780px">
              <div><span style= "font-weight: bold; margin left: 15px">1</span> <span>to ask about</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">2</span> <span>mum and dad</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">3</span> <span>we don't want to</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">4</span> <span>it would be good if</span></div>
           </div>
           <div>
              <input id="0"></input>
              <input id="1"></input>
              <input id="2"></input>
              <input id="3"></input>
           </div>
         </div>
         <div style= "width: 565px; display: flex">
           <div  style= "width: 834px">
              <div><span style= "font-weight: bold; margin left: 15px">5</span> <span>tell me about</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">6</span> <span>make clear</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">7</span> <span>getting your answer</span></div>
              <div><span style= "font-weight: bold; margin left: 15px">8</span> <span>soon</span></div>
           </div>
           <div>
              <input id="4"></input>
              <input id="5"></input>
              <input id="6"></input>
              <input id="7"></input>
           </div>
         </div>
        </div>
        <div style= "margin-top: 30px; width: 1150px; background-color: rgb(206,207,209); font-size: 22px">
         <div style= "padding: 20px">
           <span>Dear Sir or</span>
           <sup>1</sup>
           <span style="padding:0px 15px 0px 15px; color:green; border-bottom:2px solid gray">Madam</span>
           <span>,</span>
           <div>I am writing to enquire about the possibility of renting Hillside Cottage for three weeks in the summer.</div>
           <div>I will be travelling to the Lake District with my parents and brother. We plan to arrive in London on 14 July, but do notwish to hire a car. I would be grateful if you could inform me of the best way to reach the cottage by public transport.</div>
           <div>Although the advertisement states that the cottage has two bedrooms, it is not clear whether the second room is large enough for two people to share. I wonder if you could clarify this point.</div>
           <div>Finally, I would like to know what leisure activities are available in the area surrounding the cottage. We are particularly interested in horse riding.</div>
           <div>I look forward to receiving your reply in due course.</div>
           <span>Yours</span>
           <sup>2</sup>
           <span style="padding:0px 15px 0px 15px; color:green; border-bottom:2px solid gray">faithfully</span>
           <span>,</span> <br>
           <span>Tom Baker</span>
         </div>
        </div>
					`,
        answer: [
          "to enquire",
          "parents",
          "do not wish to",
          "I would be grateful if",
          "inform me",
          "clarify",
          "receiving your reply",
          "in due course",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-CR-P71-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page71/E3/key/E3answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 35,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 35,
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read <span style= "color: rgb(29,134,202)">Writing Strategy 2</span>
        . Then read the task and answer the questions below.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page71/E3/1.jpg" width="600px"/>
        <div>
           <span style="font-weight: bold; margin-right: 10px">1</span>
           <span>How many paragraphs are in the model letter?</span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">2</span>
           <span>Which two points in the task are covered in one paragraph in the model letter?</span>
           <input id="1">
           <span>and</span>
           <input id="2">
           </div>
					`,
        answer: ["5", "1 | 2", "2 | 1"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-CR-P71-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    // exerciseKey: "img/FriendsPlus/Page71/E4/key/E4answerKey.png",
    component: T6v2,
    maxLength: 200,
    // inputSize: 300,
    fontSize: 20,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "20px",
      // fontWeight: "bold",
      textAlign: "left",
      borderBottom: "2px solid gray",
      // borderRadius: "5px",
      height: 24,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        You are going to do the task above. Plan your letter. Make
        notes using the prompts below to help you.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page71/E4/1.jpg" width="600px"/>
        <div style= "width: 800px">
        <div> <span style= "font-weight: bold; font-size: 21px">People travelling:</span> <input id="0" width=502px> </div>
        <div> <span style= "font-weight: bold; font-size: 21px">Dates of holiday:</span> <input id="1" width=509px> </div>
        <div> <span style= "font-weight: bold; font-size: 21px">Polite request to ask about prices:</span> </div>
        <div><input id="2" width=685px></div>
        <div> <span style= "font-weight: bold; font-size: 21px">Polite request to ask about availability:</span> </div>
        <div><input id="3" width=685px></div>
        <div> <span style= "font-weight: bold; font-size: 21px">Leisure activities:</span> <input id="4" width=500px> </div>
        <div> <span style= "font-weight: bold; font-size: 21px">Campsite facility to ask about:</span> </div>
        <div><input id="5" width=685px></div>
        </div>
					`,
        answer: ["Madam", "faithfully"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U7-P71-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page55/key/E5answerKey.png',
    component: T6v2,
    // maxLength: 5,
    inputSize: 650,
    textareaStyle: { width: "800px", padding: 0 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Write the letter, using your notes from exercise 4.
          `,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questions: [
      {
        title: `
    <div style='line-height:2'>
      <div style="display: flex">
       <div style="background: rgb(225,229,240); width:95px; height:85px; border:5px solid rgb(31,133,199); border-radius:50%; margin-top:90px; margin-right: -47.5px"></div>
       <div style='background: rgb(225,229,240); width:100%; margin-top:20px; border-radius:20px; border:5px solid rgb(31,133,199)'>
        <div style='width:675px;line-height:2;margin:auto;padding:20px'>
          <div style='color:rgb(31,133,199);font-size:30px'><b>CHECK YOUR WORK</b></div>
          <b>Have you …</b> 
          <div style='display:flex'>
            <select id=0></select>&ensp; avoided contractions and informal language?
          </div>
          <div style='display:flex'>
            <select id=1></select>&ensp; divided the letter into paragraphs?
          </div>
          <div style='display:flex'>
            <select id=2></select>&ensp; checked your spelling and grammar?
          </div>
        </div>
       </div>
      </div>
      <div style='margin-top:10px'>
        <div><b><i><u>ANSWER:</u></i></b></div>
        <textarea id='5' rows='3'></textarea>
      </div>
    </div>
  
 
   
        `,
        answer: [],
      },
    ],
  },
};

export default json;
