import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P67-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    // totalInput: 5,
    textareaStyle: { width: 700 },
    inputSize: 1000,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    exerciseKey: "img/FriendsPlus/Page67/key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Circle the correct past or present participle form to complete the participle clauses.",
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          // color: "rgb(88,147,198)",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `belonged / belonging`, //1
          `powered / powering`, //1
          `travelled / travelling`, //1
          `decorated / decorating`, //1
          `known / knowing`,
          `taken / taking`,
          `written / writing`,
          `given / giving`,
        ],
        answers: ["0-4", "1-0", "2-4", "3-4", "4-0", "5-4", "6-0", "7-0"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:2.3'>
          <div><b>1</b>&ensp; We stayed in a villa <input id='0' type='Circle'/>  to my grandparents.</div>

          <div><b>2</b>&ensp; These scientists have designed a car <input id='1' type='Circle'/> by solar energy.</div>
          
          <div><b>3</b>&ensp; He jumped from a train <input id='2' type='Circle'/> at nearly 100 km/h.</div>
          
          <div><b>4</b>&ensp; The men <input id='3' type='Circle'/> our house have made a real mess of the furniture. </div>

          <div><b>5</b>&ensp; They spent the night in a traditional Mongolian tent <input id='4' type='Circle'/>  as a yurt. </div>

          <div><b>6</b>&ensp; The police arrested a man <input id='5' type='Circle'/> photos of the military base. </div>

          <div><b>7</b>&ensp; The tourists saw a message <input id='6' type='Circle'/>  in large, white letters on the cliff.</div>

          <div><b>8</b>&ensp; His flat is full of old furniture <input id='7' type='Circle'/>  to him by his grandfather. </div>

        </div>
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P67-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 900,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Underline one participle clause in each sentence. Then write it as a full <br>relative clause (defining or non-defining).

					`,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 700,
          fontSize: 22,
          borderBottom: "none",
          textAlign: "left",
        },
        answers: [
          `, who are not wearing helmets/,who are not wearing helmets`,
          `, who want to employ her/,who want to employ her`,
          `, which were stolen from the National Gallery/,which were stolen from the National Gallery`,
          `, which she has to read./,which she has to read.`,
          `, which was published in 1931/,which was published in 1931`,
          `, who were standing outside the hotel/,who were standing outside the hotel`,
          `, which has been left on the platform/,which has been left on the platform`,
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `I_can_see_three_cyclists&ensp; not_wearing_helmets.`,
          `She_keeps_getting_emails&ensp; from_companies&ensp; wanting_to_employ_her.`,
          `Three_paintings&ensp; stolen_from_the_National_Gallery&ensp; have_been_found.`,
          `Paula_still_has_3_books&ensp; on_nineteenth-century_literature&ensp; to_read.`,
          `His_autobiography,&ensp; published_in_1931,&ensp; was_called_Far_From_Home.`,
          `I_asked_some_girls&ensp; standing_outside_the_hotel&ensp; to_take_a_photo_of_us.`,
          `We_remind_passengers_that_any_luggage&ensp; left_on_he_platform&ensp; will_be_destroyed.`,
        ],
        answers: ["0-2", "1-4", "2-2", "3-4", "4-2", "5-2", "6-2"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; font-size: 22px'>
        <div><b>1</b>&ensp; I bought a copy of Twilight <u>signed by the author</u>.</div>
        <div style='color:rgb(55,111,131);margin-left:33px'>which had been signed by the author</div>
        <div><b>2</b>&ensp; <input id='0' type='Circle'/> </div>
          <input id='0' />
        <div><b>3</b>&ensp; <input id='1' type='Circle'/> </div>
          <input id='1' />
        <div><b>4</b>&ensp;  <input id='2' type='Circle'/> </div>
          <input id='2' />
        <div><b>5</b>&ensp; <input id='3' type='Circle'/> </div>
          <input id='3' />
        <div><b>6</b>&ensp; <input id='4' type='Circle'/> </div>
          <input id='4' />
        <div><b>7</b>&ensp; <input id='5' type='Circle'/> </div>
          <input id='5' />
        <div><b>8</b>&ensp; <input id='6' type='Circle'/> </div>
          <input id='6' />
        </div>
    `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P67-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page67/key/E3answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 200,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: 'bold',
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Complete the text with participle clauses with the same meaning as the clauses in brackets.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='background: white; width:100%; margin-top:20px;margin-left:50px; border-radius:20px;box-shadow: 10px 10px 10px rgb(207,207,207)'>
        <img src="img/FriendsPlus/Page67/E3/1.jpg" style='width:100%; margin:auto;border-radius:20px' />
        <div style='width:868px;line-height:2; margin:auto;padding:20px; font-size: 22px'>
        The largest man-made object in space is the International 
        Space Station (ISS), <sup><b>1</b></sup> # (which was built) 
        by the European Space Agency and the space agencies of 
        America, Canada, Japan and Russia. These five agencies, 
        <sup><b>2</b></sup> # (who worked) together for many 
        years, launched the ISS in 1998. Since then, they have 
        all been sending astronauts, <sup><b>3</b></sup> # (who 
        have been trained) to carry out important scientific work, 
        to the space station. Chris Hadfield, <sup><b>4</b></sup> #
        (who was chosen) as one of Canada’s astronauts, became 
        well known for uploading photos <br/> <sup><b>5</b></sup> #
        (which had been taken) in space to his social networking 
        pages. He also recorded videos, <sup><b>6</b></sup> #
        (which included) his own performance of the song Space 
        Oddity, <sup><b>7</b></sup> # (which was written) by pop 
        star David Bowie. The video, <sup><b>8</b></sup> # (which 
        has been watched) by about 25 million people so far, 
        made Chris Hadfield even more famous.
        </div>
      </div>
												`,
        answer: [
          `built`,
          `working`,
          `trained`,
          `chosen`,
          `taken`,
          `including`,
          `written`,
          `watched`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P67-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page67/key/E4answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 1100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // borderBottom: "dotted",
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Combine the two sentences using a participle clause. 
        Sometimes you need to add <br>the clause in the middle of the sentence.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='line-height:50px; font-size: 22px'>
   
            <div><b>1.</b>&ensp; We visited a palace. The palace was built 400 years ago. </div>
            <div style='color:rgb(55,111,131);margin-left:40px'>We visited a palace built 400 years ago.</div>

            <div><b>2.</b>&ensp; Astronauts get homesick. They spend weeks in space.</div>
           <div style='margin-left:22px'> #.</div>
            <div><b>3.</b>&ensp; I lost a watch. It belonged to my cousin.</div>
           <div style='margin-left:22px'> #.</div>
            <div><b>4.</b>&ensp; The scientists designed a device. It can help to detect things undersea.</div>
           <div style='margin-left:22px'> #.</div>
            <div><b>5.</b>&ensp; The debates will help voters to decide. They will be shown live on TV.</div>
           <div style='margin-left:22px'> #.</div>
            <div><b>6.</b>&ensp; He gave me a small box. It contained a key.</div>
           <div style='margin-left:22px'> #.</div>


          </div>
						
											`,
        answer: [
          `Astronauts, spending weeks in space, get homesick`,
          `I lost a watch belonging to my cousin`,
          `The scientists designed a device to help to detect things undersea / The scientists designed a device helping to detect things undersea`,
          `The debates, shown live on TV, will help voters to decide`,
          `He gave me a small box containing a key`,
        ],
      },
    ],
  },
};

export default json;
