import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Cumulative Review 5",
    id: "FGF11-WB-demo-2023-CR-P80-E1",
    audio: "Audios/2.13 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page80/E1/key/E1answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 950,
    fontSize: "22px",
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='2.13' src='Audios/2.13 tieu de.mp3'></headphone>
        Listen and choose the correct answers.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-8", "1-0", "2-8", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
          <b>1</b>&ensp;
          Listen to speaker 1. What is the reason for the announcement?
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  To explain what to do in an emergency <br>
                  To tell passengers how to fasten their seat belts <br>
                  To give the necessary safety information
                </div>
              </div>
            </div>

          <b>2</b>&ensp;
          Listen to dialogue 2. Where does the woman's train leave from?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  Platform 3 <br>
                  Platform 8 <br>
                  Platform 11
                </div>
              </div>
            </div>

          <b>3</b>&ensp;
          Listen to speaker 3. How long will the flight be delayed for?
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  Less than an hour <br>
                  About an hour <br>
                  More than an hour
                </div>
              </div>
            </div>
          <b>4</b>&ensp;
          Listen to dialogue 4. Where is the bus stop?
           the smartwatch?
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  Outside the cinema <br>
                  In the car park of the shopping mall <br>
                  Outside the front entrance of the shopping mall                
                </div>
              </div>
            </div>
          <b>5</b>&ensp;
          Listen to speaker 5. Which route only has one sailing per week?
            <div style="display: flex;margin-left:40px">
		          <div><input id='4' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  Portsmouth to Santander <br>
                  Plymouth to Santander <br>
                  Portsmouth to Bilbao
                </div>
              </div>
            </div>
      `,
    },
  },
  2: {
    unit: "Cumulative Review 5",
    id: "FGF11-WB-demo-2023-CR-P80-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page80/E2/key/E2answerKey.png",
    component: T6v2,
    fontSize: "22px",

    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      height: 100,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Work in pairs. Look at the photo and answer the questions.
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='max-width: 1000px'>
        <span style= "font-weight: bold; margin-right: 8px">1</span>
        <span>How do you think the people are feeling?</span><br>
        <textarea id='0' rows='3'>
        </div>
        <div>
        <span style= "font-weight: bold; margin-right: 8px">2</span>
        <span>How effective is public transport where you live?</span><br>
        <textarea id='1' rows='3'>
        </div>
        <div>
        <span style= "font-weight: bold; margin-right: 8px">3</span>
        <span>Have you been on a journey in which you experienced a
        similar situation to this? Why were there so many people?
        </span><br>
        <textarea id='2' rows='3'>
        </div>
        <img src="img/FriendsPlus/Page80/E2/1.jpg" width="600px" />
				`,
        answer: [],
      },
    ],
  },
};

export default json;
