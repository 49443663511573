import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P44-E1",
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct prepositions. 
					`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `at / to`, // 0
          `in / with`, // 1
          `for / with`, // 2
          `about / for`, // 3
          `to / with`, // 4,
          `from / with`,
        ],
        answers: ["0-4", "1-4", "2-4", "3-4", "4-4", "5-4"],
        initialValue: {},
      },
      Layout: `
    <div style='line-height:2'>
    <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">1 </span>
    His eyes are very sensitive <input id='0' type='Circle' /> bright lights. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">2 </span>
    She wasn’t very pleased <input id='1' type='Circle' /> her present. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">3 </span>
    I want 90%, but I’d be satisfied <input id='2' type='Circle' /> 75%. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">4 </span>
    Who is responsible <input id='3' type='Circle' /> all this mess? 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">5 </span>
    I’m not familiar <input id='4' type='Circle' /> this part of town, so it’s easy  to get lost. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">6 </span>
    If you were dissatisfied <input id='5' type='Circle' /> your hotel room, you should have complained. 
  </div>

  
    </div>
			
				`,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P44-E2",
    exerciseKey: "img/FriendsPlus/Page44/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
      background: "none",
    },
    isHiddenCheck: true,
    maxLength: 5,
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the text with <i>about, at, in, of, on, to,</i> or <i>with</i>.

				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
     <div style='background-color:rgb(239,239,240);width:900px;padding:10px 20px;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;'>
     Scientists have been aware <sup>1</sup># technology
    addictions for many years, but now there is a new
     kind: recently a man needed treatment after
    becoming addicted <sup>2</sup># Google Glass. <br/>
    The 31-year-old American man visited a clinic
     in the USA because he was worried <sup>3</sup># an alcohol addiction.
    He had to take off his Google Glass when he arrived and
      doctors were shocked <sup>4</sup># his reaction. He suffered withdrawal
      symptoms which were very similar <sup>5</sup># drug or alcohol withdrawal. <br/>
      Originally, the man had become keen <sup>6</sup># Google Glass because it
      helped him to become better  <sup>7</sup># his job. He gradually became more 
      and more obsessed <sup>8</sup># the device. However, he also began to feel
       unhappy <sup>9</sup># the effect that it was having on his mood: he became
         bad-tempered and forgetful when he was not wearing it. 
         The device was helping him to be successful  <sup>10</sup># his job,
          but it was harmful  <sup>11</sup># the rest of his life. <br/>
           At first, the doctors at the clinic were curious  <sup>12</sup># one 
           symptom: the man kept tapping the right side of his head
            with his finger. The man later explained that this was how
             you changed the view on Google Glass.
     
     </div>

       
        `,
        answer: [
          `of`,
          `to`,
          `about`,
          `at`,
          `to`,
          `on`,
          `at`,
          `with`,
          `about`,
          `in`,
          `to`,
          `about`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P44-E3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        Tick the best summary of the text in exercise 2.
              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 1,
        listWords: [`✓ <br> ✓ ✓ `],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;top:0px;left:20px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='display:flex'>
                <div style='margin-right: 20px'>
                  <div style='display:flex'>
                  <b>a</b>  
                  <div style='margin-left:10px'>
                  A man in the USA developed an addiction to Google Glass while he was receiving treatment for another addiction.  <br>
                  </div>
                  </div>
                  <div style='display:flex'>
                  <b>b</b>  
                  <div style='margin-left:10px'>
                  A man in the USA became addicted to Google Glass after using it at work.  <br>
                  </div>
                  </div>
                  <div style='display:flex'>
                  <b>c</b>  
                  <div style='margin-left:10px'>
                  A man in the USA found it so difficult to break his addiction to Google Glass that he developed an alcohol addiction.   <br>
                  </div>
                  </div>
                </div>
                <div>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div> <br/>
                  <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                </div>
              </div>
              
             
          </div>
        <div style='position:relative;top:-253px;left:1132px;color: white; display:flex; flex-direction: column;line-height:50px  '>
          <input id='0' type='Circle'>
          
        </div>
       
      </div>
    </div>
       
        
       
           
        
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P44-E4",
    exerciseKey: "img/FriendsPlus/Page44/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 70,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 8,
    // hideBtnFooter: true,
    textareaStyle: { width: 820,padding:5 },
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the questions with a preposition. Then write true answers about yourself. 
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
       <b>1</b>
       <div style='margin-left:10px'>
       In what ways can social media be harmful # teenagers?  
       <textarea id='3' rows='2'></textarea>
       </div>
       
       </div>

       <div style='display:flex'>
       <b>2</b>
       <div style='margin-left:10px'>
       Which gadget are you most likely to become addicted # , in your opinion? Why?  
  
       <textarea id='4' rows='2'></textarea>
       </div>
       
       </div>
        `,

        answer: [`to`, `to`, ``, ``],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P44-E5",
    exerciseKey: "img/FriendsPlus/Page44/Key/E5answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true,
    // textareaStyle: { width: 820 },
    titleQuestion: [
      {
        num: "5",
        title: `
        Read the <span style='color:rgb(142,15,177)'><i>Vocab boost!</i></span> box. Then study the dictionary entry and write the abbreviations below in full.
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
<div style='display:flex'>
<div>
<div style='width:600px;background-color:rgb(199,200,202);padding:20px 40px;border-radius:20px'>
<div style='background-color:rgb(255,255,255);padding:10px 20px;border-radius:20px'>
<b>appropriate</b> /əˈprəʊpriət/ adj <b>appropriate</b> (for/to sth/ sb) suitable or right for a particular situation, person,  use, etc.: The matter will be dealt with by the appropriate authorities. ◆ I don’t think this film is appropriate for young children. OPP <b>inappropriate</b> > <b>appropriately</b> adv
</div>

</div>

</div>

<div>
<div>
<img src='img/FriendsPlus/Page44/E5/1.jpg' width='100%'/>
</div>

</div>
</div>

       

      <div style='line-height:2;margin-top:10px'>
      <div style='display:flex'>
      <b>1</b>
      <div style='margin-left:10px'>
      adj
      <input/>
      </div>
      </div>

      <div style='display:flex'>
      <b>2</b>
      <div style='margin-left:10px'>
      sth 
      <input/>
      </div>
      </div>

      <div style='display:flex'>
      <b>3</b>
      <div style='margin-left:10px'>
      sb  
      <input/>
      </div>
      </div>

      <div style='display:flex'>
      <b>4</b>
      <div style='margin-left:10px'>
      OPP 
      <input/>
      </div>
      </div>

      <div style='display:flex'>
      <b>5</b>
      <div style='margin-left:10px'>
      adv 
      <input/>
      </div>
      </div>
      </div>

       
        `,

        answer: [`adjective`, `something`, `somebody`, `opposite`, `adverb`],
      },
    ],
  },
};

export default json;
