import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Cumulative Review 5",
    id: "FGF11-WB-demo-2023-CR-P81-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/E3/key/E3answerKey.png",
    component: T6,
    maxLength: 1,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        Match the texts with the questions below. Some questions match with more
        than one text.
					`,
        color: "#262324",
      },
    ],
    selectStyle: {
      border: "2px solid gray",
      marginTop: -5,
      marginLeft: 10,
      height: 36,
      width: 38,
      textAlign: "left",
      borderRadius: "5px",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: [`1`, `2`, `3`, `4`, `-`],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px'>
           <b>Which form(s) of transport</b>
           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">A</div>
           <div>employ(s) workers who move between vehicles selling tickets?</div>
           <select id=0></select>
           <select id=1></select>
           </div>
           
           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">B</div>
           <div>hold(s) a world record?</div>
           <select id=2></select>
           <select id=3></select>
           </div>

           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">C</div>
           <div>is / are no longer used by commuters?</div>
           <select id=4></select>
           <select id=5></select>
           </div>

           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">D</div>
           <div>date(s) back to the start of the 20th century?</div>
           <select id=6></select>
           <select id=7></select>
           </div>

           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">E</div>
           <div>carry / carries passengers downhill? </div>
           <select id=8></select>
           <select id=9></select>
           </div>

           <div style="display: flex">
           <div style="font-weight: bold; margin-right: 10px">F</div>
           <div>is / are not finished yet?</div>
           <select id=10></select>
           <select id=11></select>
           </div>

           <img src="img/FriendsPlus/Page81/E3/1.jpg" style='width: 30cm' />
           </div>
					`,
        answer: [
          `2 / -`,
          `- / 2`,
          `1 / 3`,
          `3 / 1`,
          `4 / -`,
          `- / 4`,
          `3 / -`,
          `- / 3`,
          `1 / 4`,
          `4 / 1`,
          `1 / -`,
          `- / 1`,
        ],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 5",
    id: "FGF11-WB-demo-2023-CR-P81-E4",
    exerciseKey: "img/FriendsPlus/Page81/E4/key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
        Choose the correct answers.
				`,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
          fontSize: 22,
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c`, //0
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //4

          `a b c`, //5
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //9
        ],
        answers: [
          "0-4",
          "1-2",
          "2-0",
          "3-2",
          "4-0",
          "5-4",
          "6-0",
          "7-2",
          "8-4",
          "9-2",
        ],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative; font-size: 25px; margin-right: 50px;'>
      <img style="width:20cm; height:14cm" src='img/FriendsPlus/Page81/E4/1.jpg' />
					<div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>1&ensp;</b><input id='0' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>2&ensp;</b><input id='1' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>3&ensp;</b><input id='2' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>4&ensp;</b><input id='3' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>5&ensp;</b><input id='4' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm; margin-right: 174px;'>
          <b>6&ensp;</b><input id='5' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>7&ensp;</b><input id='6' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>8&ensp;</b><input id='7' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>9&ensp;</b><input id='8' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>10</b><input id='9' type='Circle'>
					</div>
						
					
						
<div style='position:absolute;top:528px;left:202px; font-size: 25px'>
            visit <br>
						board<br>
            afford<br>
            enter<br>
            taking<br>
            would die<br>
            to reach<br>
            enjoy<br>
            thank<br>
            travel<br>
					</div>
					<div style='position:absolute;top:532px;left:415px; font-size: 25px'>
					  visiting<br>
            boarding<br>
            affording<br>
            to enter<br>
            take<br>
            had died<br>
            reaching<br>
            to enjoy<br>
            thanking<br>
            travelling<br>
				</div>
					<div style='position:absolute;top:532px; left: 618px; font-size: 25px'>
            to visit<br>
            to board<br>
            to afford<br>
            entering<br>
            to take<br>
            would have died<br>
            reach<br>
            enjoying<br>
            to thank<br>
            to travel<br>

					</div>
					
			</div>

			


        
         `,
    },
  },
  5: {
    unit: "Cumulative Review 5",
    id: "FGF11-WB-demo-2023-CR-P81-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/E5/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    fontSize: "22px",
    inputSize: 130,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "700",
      textAlign: "center",
      width: "80px",
    },
    textareaStyle: {
      // background: 'none',
      width: 1200,
      fontSize: 22,
      height: 270,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Imagine that you have recently returned from a holiday where you used one
        of the forms of transport mentioned in the Reading texts. Write an email to a
        friend in which you:
				`,
        color: "#262324",
      },
    ],
    hideBtnFooter: true,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 0px; width: 1000px">
         <ul style:"list-style-type: disc">
           <li>describe the country that you visited.</li>
           <li>relate your experience travelling on the vehicle.</li>
           <li>mention something that went wrong during the holiday.</li>
           <li>invite your friend to go on holiday with you next year.</li>
         </ul>
         <textarea id='0' row='3'>
		    </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
