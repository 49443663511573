import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P11-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 200,
    maxLength: 15,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page11/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style=''>Complete the sentences with the correct form of <i>used to</i>.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 10px; line-height: 50px">
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br>
            <span><b>2 </b></span><br>
            <span><b>3 </b></span><br>
            <span><b>4 </b></span><br><br>
            <span><b>5 </b></span><br>
            <span><b>6 </b></span><br>
            <span><b>7 </b></span>
          </div>
        
        <div>
          <span>I know this town well. We #(visit) here a lot when I was younger.</span><br>
          <span>You #(love) swimming. Why don’t you like it now?</span><br>
          <span>Your dad is fantastic at football. # he #(play) a lot?</span><br>
          <span>Our town #(have) good sports facilities, but now there’s a great new sports centre.</span><br>
          <span>She sounds American. # she #(live) in the USA?</span><br>
          <span>I #(argue) a lot with my dad, but 
          we get on really well now.</span><br>
          <span>He speaks Spanish really well because he #
          (go) to university in Madrid.</span><br>
          
        </div>

        </div>

				`,
        answer: [
          `used to visit`,
          `used to love`,
          `Did`,
          `use to play`,
          `used to have`,
          `Did`,
          `use to live`,
          `used to argue`,
          `used to go`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P11-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 350,
    maxLength: 50,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style=''>Complete the sentences with the correct form of <i>used to</i>. Use 
        the word in brackets and any other necessary word. </span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 10px; line-height: 50px">
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br>
            <span><b>2 </b></span><br>
            <span><b>3 </b></span><br>
            <span><b>4 </b></span><br>
            <span><b>5 </b></span><br>
          </div>
        
        <div>
          <span>(sea) <i style='color: rgb(152,198,204)'>We didn’t use to live near the sea </i>, but we live near it 
          now.</span><br>
          <span>(glasses)# , but I wear them now.</span><br>
          <span>(milk)# , but I don’t drink it now.</span><br>
          <span>(dogs)# , but I’m not afraid of them now.</span><br>
          <span>(stamps)# , but he doesn’t collect them now.</span><br>
          
        </div>

        </div>

				`,
        answer: [
          `I didn't use to wear glasses.`,
          `I used to drink milk.`,
          `I used to be afraid of dogs.`,
          `He used to collect stamps.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P11-E3",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    hideBtnFooter: true,
    inputSize: 750,
    maxLength: 70,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page11/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: "3",
        title: `<span style=''>Complete the sentence with <i>used to</i> (affirmative or negative) 
        and the verbs in brackets.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style='color: rgb(152,198,204)'>When I was eight years old, …</div>
        <div style="display: flex; margin-top: 10px; line-height: 50px">
          
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br>
            <span><b>2 </b></span><br>
            <span><b>3 </b></span><br>
            <span><b>4 </b></span><br>
            <span><b>5 </b></span><br>
          </div>
        
        <div>
          <span>(eat)</span>
          <span>#</span><br>
          <span>(wear)</span>
          <span>#</span><br>
          <span>(play)</span>
          <span>#</span><br>
          <span>(like)</span>
          <span>#</span><br>
          <span>(be afraid)</span>
          <span>#</span><br>
        </div>

        </div>

				`,
        answer: [``],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P11-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 550,
    maxLength: 80,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 24px'>Look at the pictures of Jackie twenty years ago. Complete 
        the questions with the correct form of <br><i>used to</i> and the verbs 
        below. Then write the answers.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["have", "<s>live</s>", "play", "wear", "work"],
        borderColor: "none",
        width: 600,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='display: flex'> 
        <div style='font-size: 22px'>
        <img style="width:13cm" src='img/FriendsPlus/Page11/E4/1.jpg' />
          <div style="display: flex; margin-top: 10px; line-height: 35px">

          <div style='margin-right: 10px'>
            <span><b>1 </b></span><br><br>
            <span><b>2 </b></span><br><br>
            <span><b>3 </b></span><br><br>
          </div>
        
          <div>
            <span><i style='color: rgb(152,198,204)'> Did she use to live</i> in London?</span><br>
            <span><i style='color: rgb(152,198,204)'> No, she used to live in Paris.</i></span><br>
            <span><input id='0' width='300px'> short hair?</span><br>
            <span><input id='1'></span><br>
            <span><input id='2' width='300px'> in a shop?</span><br>
            <span><input id='3'></span><br>
          </div>
        </div>
      </div> 
      <div>
        <div style='font-size: 22px'>
          <img style="width:13cm" src='img/FriendsPlus/Page11/E4/2.jpg' />
          <div style="display: flex; margin-top: 10px; line-height: 35px"> 
            <div style='margin-right: 10px'>
              <span><b>4 </b></span><br><br>
              <span><b>5 </b></span><br><br>
            </div>

          <div>
            <span><input id='4' width='300px'> football at weekends?</span><br>
            <span><input id='5'></span><br>
            <span><input id='6' width='300px'> a tracksuit?</span><br>
            <span><input id='7'></span><br>
          </div>
          </div>
        </div>
      </div>

				`,
        answer: [
          `Did she use to have`,
          `No, she used to have long hair.`,
          `Did she use to work`,
          `No, she used to work in a café. / No, she used to work in a cafe.`,
          `Did she use to play`,
          `No, she used to play tennis.`,
          `Did she use to wear`,
          `No, she used to wear shorts and a T-shirt.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FGF11-U1-P11-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page11/Key/E5answerKey.png",
    component: DesignUnderLine,
    totalInput: 5,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title: `
        Tick the correct phrase to complete the sentences.
			  `,
        color: "black",
      },
    ],
    questionImage: [
      // IMG
      //Q1
      [{ url: "img/FriendsPlus/Page11/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E5/2.jpg" },
        { url: "img/FriendsPlus/Page11/E5/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page11/E5/4.jpg" },
        { url: "img/FriendsPlus/Page11/E5/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E5/6.jpg" },
      ],
      //Q2
      [{ url: "img/FriendsPlus/Page11/E5/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E5/8.jpg" },
        { url: "img/FriendsPlus/Page11/E5/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E5/10.jpg" },
        { url: "img/FriendsPlus/Page11/E5/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E5/12.jpg" },
      ],
      //Q3
      [{ url: "img/FriendsPlus/Page11/E5/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E5/14.jpg" },
        { url: "img/FriendsPlus/Page11/E5/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E5/16.jpg" },
        { url: "img/FriendsPlus/Page11/E5/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page11/E5/18.jpg" },
      ],
      //Q4
      [{ url: "img/FriendsPlus/Page11/E5/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E5/20.jpg" },
        { url: "img/FriendsPlus/Page11/E5/21.jpg", input: 4 },
        { url: "img/FriendsPlus/Page11/E5/22.jpg" },
        { url: "img/FriendsPlus/Page11/E5/23.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E5/24.jpg" },
      ],
      //Q5
      [{ url: "img/FriendsPlus/Page11/E5/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E5/26.jpg" },
        { url: "img/FriendsPlus/Page11/E5/27.jpg", input: 5 },
        { url: "img/FriendsPlus/Page11/E5/28.jpg" },
        { url: "img/FriendsPlus/Page11/E5/29.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E5/30.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E5/31.jpg" }],
    ],
  },
};

export default json;
