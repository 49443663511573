import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E1answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      // borderBottom: 'dotted',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the words with <i>a, e, i, o, u</i> and <i>y</i>. Are they musical genres or aspects of music?
	      `,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          width: 40,
          fontSize: 22,
          borderBottom: "none",
          textAlign: "center",
          maxLength: 1,
        },
        answers: [
          `u`,
          `e`,
          `o`,
          `u`,
          `a`,
          `i`,
          `a`,
          `o`,
          `u`,
          `y`,
          `a`,
          `e`,
          `e`,
          `o`,
          `a`,
          `o`,
          `y`,
          `e`,
          `a`,
          `y`,
          `e`,
          `a`,
          `i`,
          `o`,
          `a`, //9 cau dau

          `a`,
          `y`,
          `i`,
          `e`,
          `o`,
          `y`,
          `u`,
          `e`,
          `o`,
          `o`,
          `y`,
          `e`,
          `e`,
          `e`,
          `o`,
          `e`,
          `o`,
          `e`,
          `e`, //8 cau sau
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
          fontSize: 24,
        },
        limitSelect: 1,
        listWords: [
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
        ],
        answers: [
          "0-0",
          "1-4",
          "2-0",
          "3-0",
          "5-4",
          "4-0",
          "6-0",
          "7-0",
          "8-0",
          "9-4",
          "10-4",
          "11-0",
          "12-4",
          "13-4",
          "14-0",
          "15-4",
        ],

        initialValue: [],
      },
      Layout: `
      <div style='width:18cm'>
      <div style='position:relative;'>
      <div style='position: relative; display:flex; line-height:2.5'>

      <div style='margin-top:45px'>
      
      <div style='display:flex'>
      <b>1.</b>&ensp;b e a t
     </div>
 
     <div style='display:flex'>
      <b>2.</b>&ensp;<span>bl<input id='0'  /> <input id='1'  />s</span> 
     </div>
     
     <div style='display:flex'>
      <b>3.</b>&ensp; <span>ch<input id='2'  />r<input id='3'  />s</span> 
     </div>
      
       
     <div style='display:flex'>
      <b>4.</b>&ensp; <span>cl<input id='4'  />ss<input id='5'  />c<input id='6'  />l</span> 
     </div>
     
     <div style='display:flex'>
      <b>5.</b>&ensp; <span>c<input id='7'  /> <input id='8'  />ntr<input id='9'  />&ensp;<input id='10' />nd&ensp; w<input id='11' />st<input id='12' />rn</span>
     </div>
    
     <div style='display:flex'>
     <b>6.</b>&ensp; <span>f<input id='13' />lk</span>
     </div>
     
     <div style='display:flex'>
     <b>7.</b>&ensp; <span>h<input id='14' />rm<input id='15' />n<input id='16' /></span>
    </div>
 
    <div style='display:flex'>
    <b>8.</b>&ensp; <span>h<input id='17' /><input id='18' />v<input id='19' />m<input id='20' />t<input id='21' />l</span>
    </div>
 
    <div style='display:flex'>
    <b>9.</b>&ensp; <span>h<input id='22' />p&ensp; h<input id='23' />p / r<input id='24' />p</span>
    </div>
 
   <div style='margin-left:-10px'>
    <div style='display:flex'>
    <b>10.</b>&ensp; <span>j<input id='25' />zz</span>
    </div>
 
    <div style='display:flex'>
    <b>11.</b>&ensp; <span>l<input id='26' />r<input id='27' />cs</span>
    </div>
 
    <div style='display:flex'>
    <b>12.</b>&ensp; <span>m<input id='28' />l<input id='29' />d<input id='30' />/ t<input id='31' />n<input id='32' /></span>
    </div>
 
    <div style='display:flex'>
    <b>13.</b>&ensp; <span>p<input id='33' />p / r<input id='34' />ck</span>
    </div>
 
    <div style='display:flex'>
    <b>14.</b>&ensp; <span>rh<input id='35' />thm</span>
    </div>
 
    <div style='display:flex'>
    <b>15.</b>&ensp; <span>sp<input id='36' /> <input id='37' />d / t<input id='38' />mp<input id='39' /></span>
    </div>
 
    <div style='display:flex'>
    <b>16.</b>&ensp; <span>t<input id='40' />chn<input id='41' /></span>
    </div>
 
    <div style='display:flex'>
    <b>17.</b>&ensp; <span>v<input id='42' />rs<input id='43' /></span>
    </div>
    </div>
      </div>
 
     <div style='position: relative'>
        <div >
           <span><b>genre</b></span>
           <span style='margin-left:80px'><b>aspect</b></span>
         </div>
      
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
         <div style='display:flex;margin-left:15px'>
         <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
         <div style='margin-left:120px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
     </div>
   </div>
     

      
        
        <div style='position:absolute;color: green;top:55px;left:632px;line-height:2'>
        ✓
				</div>
        <div style='position:absolute;color: white;top:115px;left:472px;line-height:2'>
          <input id='0' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:175px;left:472px;line-height:2'>
          <input id='1' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:235px;left:472px;line-height:2'>
          <input id='2' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:295px;left:472px;line-height:2'>
          <input id='3' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:355px;left:472px;line-height:2'>
          <input id='4' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:415px;left:472px;line-height:2'>
          <input id='5' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:475px;left:472px;line-height:2'>
          <input id='6' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:535px;left:472px;line-height:2'>
          <input id='7' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:595px;left:472px;line-height:2'>
          <input id='8' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:655px;left:472px;line-height:2'>
          <input id='9' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:715px;left:472px;line-height:2'>
          <input id='10' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:775px;left:472px;line-height:2'>
          <input id='11' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:835px;left:472px;line-height:2'>
          <input id='12' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:895px;left:472px;line-height:2'>
          <input id='13' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:955px;left:472px;line-height:2'>
          <input id='14' type='Circle'> 
        </div>
        <div style='position:absolute;color: white;top:1015px;left:472px;line-height:2'>
          <input id='15' type='Circle'> 
        </div>
       
        
			</div>
      </div>
         `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E2",
    // audio: "Audios/2.12.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E2answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    maxLength: 14,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',

      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete the indefinite pronouns.

								`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="line-height:2.3">
          <div><b>1</b>&ensp; No # was listening when I spoke.</div>

          <div><b>2</b>&ensp; My phone must be some # in the house.</div>

          <div><b>3</b>&ensp; Did you notice any # different about Sam?</div>

          <div><b>4</b>&ensp; Does any # know the lyrics to this next song?</div>

          <div><b>5</b>&ensp; I know no # about modern classical music.</div>

          <div><b>6</b>&ensp; Did you go any # exciting during the summer?</div>
        </div>
				
									`,
        answer: [
          `one | body | one / body  `,
          `where`,
          `thing`,
          `one | body | one / body  `,
          `thing`,
          `where`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E3answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Underline one mistake in each sentence. Then correct it.
								`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    textareaStyle: { width: 600 },
    question: {
      Write: {
        isHiddenCheck: true,
        // maxLength: 11,
        inputStyle: { width: 600, fontSize: 23, borderBottom: "none" },
        answers: [
          `We didn't see anything we liked in the art gallery.`,
          `I shouted, but nobody could hear me.`,
          `It doesn't look as if anybody is home yet.`,
          `Nobody saw you, did they?`,
          `Has everybody got their tickets?`,
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: 0,
          // borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          // padding: 5,
          paddingBottom: 4,
          borderBottom: "solid 2px",
          borderColor: "#00aeef",
          // marginTop:-10,
        },
        limitSelect: 1,
        listWords: [
          `We &ensp; didn’t &ensp; see &ensp; nothing &ensp; we &ensp; liked &ensp; in &ensp; the &ensp; art &ensp; gallery.`,
          `I &ensp; shouted, &ensp; but &ensp; anybody &ensp; could &ensp; hear &ensp; me.`,
          `It &ensp; doesn’t &ensp; look &ensp; as &ensp; if &ensp; somebody &ensp; is &ensp; home &ensp; yet.`,
          `Nobody &ensp; saw &ensp; you, &ensp; did &ensp; he?`,
          `Has &ensp; everybody &ensp; got &ensp; your &ensp; tickets?`,
        ],
        answers: ["0-12", "1-12", "2-20", "3-16", "4-12"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:2'>
        <div style='display:flex'>
        <b>1</b>&ensp;&ensp; <input id='0' type='Circle' /> 
        </div>
        <input id='0'/>

        <div style='display:flex'>
        <b>2</b>&ensp;&ensp; <input id='1' type='Circle' />
        </div>
        <input id='1'/>

        <div style='display:flex'>
        <b>3</b>&ensp;&ensp; <input id='2' type='Circle' />
        </div>
        <input id='2'/>

        <div style='display:flex'>
        <b>4</b>&ensp;&ensp; <input id='3' type='Circle' />
        </div>
        <input id='3'/>

        <div style='display:flex; flex:1'>
        <b>5</b>&ensp;&ensp; <input id='4' type='Circle' />
        </div>
        <input id='4'/>

      </div>
          
           
        
      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E4answerKey.png",
    component: T6v2,
    maxLength: 16,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      textAlign: "center",
      // fontWeight: "bold",
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the sentences with indefinite pronouns.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 22px'>
          <div><b>1</b>&ensp; Would you like # to drink before we leave?</div>

          <div><b>2</b>&ensp; He pressed the button, but # happened.</div>

          <div><b>3</b>&ensp; # knows why she resigned; it’s a mystery.</div>

          <div><b>4</b>&ensp; When the ground floor flooded, we moved # upstairs to keep it dry.</div>

          <div><b>5</b>&ensp; They searched the sea for survivors, but they didn’t find # at all.</div>

          <div><b>6</b>&ensp; The room was a mess: there were plastic cups and empty crisp packets # .</div>
        
        
        </div>

        `,
        answer: [
          `something`,
          `nothing`,
          `Nobody / No one | Nobody | No one`,
          `everything`,
          `anybody / anyone | anybody | anyone`,
          `everywhere`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E5answerKey.png",
    component: T6v2,
    maxLength: 50,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      textAlign: "center",
      // fontWeight: "bold",
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Complete the dialogue with indefinite pronouns.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height: 50x; font-size: 22px'>
          <div style='display:flex'><b>George</b>&ensp; <span>Hi, Ellie. Did you do <sup><b>1</b></sup> # interesting at the weekend?</span></div>

          <div style='display:flex'><b>Ellie</b>&ensp;&ensp;&ensp;&ensp; <span>I went to a concert with my cousin on Saturday. It was at a tiny venue <sup><b>2</b></sup> # in London.</span></div>

          <div style='display:flex'><b>George</b>&ensp; <span>What was it like?</span></div>

          <div style='display:flex'><b>Ellie</b>&ensp;&ensp;&ensp;&ensp; <span>It wasn’t like <sup><b>3</b></sup> # I’d ever heard before. It was incredibly modern.</span></div>

          <div style='display:flex'><b>George</b>&ensp; <span>Did you like it?</span></div>

          <div style='display:flex'><b>Ellie</b>&ensp;&ensp;&ensp;&ensp; <span> No, I hated it! But <sup><b>4</b></sup> # else seemed to enjoy it. They all clapped at the end. Anyway, what did you get up to?</span></div>
        
          <div style='display:flex'><b>George</b>&ensp; <span>I just stayed in and watched <sup><b>5</b></sup> # on TV.</span></div>

          <div style='display:flex'><b>Ellie</b>&ensp;&ensp;&ensp;&ensp; <span>That sounds nice.</span></div>

          <div style='display:flex'><b>George</b>&ensp; <span>It was OK. But I was a bit lonely. I didn’t see <sup><b>6</b></sup> # all weekend.</span></div>
        
        </div>

        `,
        answer: [
          `anything`,
          `somewhere`,
          `anything`,
          `everybody / everyone | everybody | everyone`,
          `something`,
          `anybody / anyone | anybody | anyone`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P60-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page60/key/E6answerKey.png",
    component: T6,
    maxLength: 16,
    inputSize: 240,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      // fontWeight:'bold',
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Read the <span style='color:rgb(150,47,143)'>Vocab boost!</span> box. Then write translations for the words. Use a dictionary to help you if necessary
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='display:flex;position: relative;'>
       <div><img src='img/FriendsPlus/Page60/E6/1.jpg' width='100%'></div>
       <img src='img/FriendsPlus/Page60/E6/2.jpg' width='50%'>
       
       <div style="  position: absolute; top:41px; left: 860px; "><input font-size='23px' ></input></div>
       <div style=" position: absolute; top: 78px; left: 860px; "><input font-size='23px' ></input></div>

       <div style="  position: absolute; top:117px; left: 860px; "><input font-size='23px' ></input></div>
       <div style="  position: absolute; top: 155px; left: 860px; "><input font-size='23px' ></input></div>

       <div style="  position: absolute; top:188px; left: 860px; "><input font-size='23px' ></input></div>
       
       </div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
