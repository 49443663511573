import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/key/E1answerkey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct words. If both are correct, circle both. Then find the people in the two photos on this page. 
					`,
        color: "rgb(0,0,0)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 2,
        listWords: [
          `in / wearing`, // 0
          `in / with`, // 1
          `who_is / with`, // 2
          `in / with`, // 3
          `in / with`,
          `wearing / with`, // 4,
        ],
        answers: [
          "0-0",
          "0-4",
          "1-4",
          "2-0",
          "3-0",
          "3-4",
          "4-4",
          "5-0",
          "5-4",
        ],
        initialValue: {},
      },
      Layout: `
	  <div style='display:flex'>
    <div style='flex:1;line-height:2.25'>
    <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">1 </span>
    The man <input id='0' type='Circle' style="letter-spacing: 10px;"/> a baseball cap … 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">2 </span>
    The two women <input id='1' type='Circle' style="letter-spacing: 10px;"/> ponytails … 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">3 </span>
    The girl <input id='2' type='Circle' style="letter-spacing: 10px;"/> sitting on the ground … 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">4 </span>
    The woman <input id='3' type='Circle' style="letter-spacing: 10px;"/> long trousers … 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">5 </span>
    The man <input id='4' type='Circle' style="letter-spacing: 10px;"/> his arms folded …
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">6 </span>
    The woman <input id='5' type='Circle' style="letter-spacing: 10px;"/>  a dress …

  </div>
    </div>

    <div style='flex:1'>
    <img src='img/FriendsPlus/Page30/E1/1.jpg' width='90%'>

    </div>
    </div>
      


			
				`,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P30-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/key/E2answerkey.png",
    component: T6v2,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the sentences about photo A with the phrases below. 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    textAlign: "center",
    inputSize: 330,
    questions: [
      {
        title: `
        <div style="width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>a sort of&ensp;&ensp;</span>
        <span>I'd say&ensp;&ensp;</span>
        <span>it looks like some kind of&ensp;&ensp;</span>
        <span>I would say&ensp;&ensp;</span>
        <span>most likely&ensp;&ensp;</span>
        <span>or maybe&ensp;&ensp;</span>
        <span>something like that&ensp;&ensp;</span>
        </div>
       <div style='line-height:2;margin-top:10px'>
       <b>1</b>&ensp;They’re on a beach,<input/>.<br>
       <b>2</b>&ensp;They’re <input/> in their twenties. <br>
       <b>3</b>&ensp;<input/> the people in the background are a mix of ages.<br>
       <b>4</b>&ensp;<input/> fitness competition.<br>
       <b>5</b>&ensp;There’s <input/> mat on the ground. <br>
       <b>6</b>&ensp;The bald man is doing push-ups or <input/>.<br>
       <b>7</b>&ensp;The man with the cap is the judge <input/> he’s just a friend.
       <br>
       </div>

				`,
        answer: [
          `I would say`,
          `most likely`,
          `I'd say`,
          `It looks like some kind of`,
          `a sort of`,
          `something like that`,
          `or maybe`,
        ],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P30-E3",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page30/E3/key/E3answerkey.png",
    component: T6v2,
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(49,160,10)'>Speaking Strategy</span>. Then look at photo B and prepare your description. Use the questions to help you. 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    textareaStyle: { width: 500, padding: 5 },
    hideBtnFooter: true,
    inputSize: 380,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <div>

        <div style='display:flex'>
        <br>
        <b>1</b>
        <div style='margin-left:10px'>
        Where are the women? What are they doing? <br> What is unusual about the situation? <br>
        <textarea id='0' rows='4'></textarea>
 
        
        </div>
        
        </div>
        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        Is one of them the leader? Why do you think so?  <br>
        <textarea id='1' rows='4'></textarea>

        
        </div>
        
        </div>
        </div>

        <div style='margin-left:20px'>
        <img src='img/FriendsPlus/Page30/E3/1.jpg' width='80%'>

        <img src='img/FriendsPlus/Page30/E3/2.jpg' width='80%'>

        </div>
        </div>
        
				`,
        answer: [],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P30-E4",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page30/E4/key/E4answerkey.png",
    component: T6v2,
    // isHiddenCheck: true,
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: "4",
        title: `
        Read the examiner’s questions. Write notes for your answers. 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    textareaStyle: {
      width: 900,
      padding: 5,
    },
    inputSize: 650,
    questions: [
      {
        title: `
        <div style='height:150px;border:none;background-color:rgb(229,229,230);padding:20px 20px;box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;'>
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        Do you think the women are enjoying themselves?  Why? / Why not? 
        
        </div>
        
        </div>
        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        What are the advantages and disadvantages of exercising outdoors rather than indoors?
        
        </div>
        
        </div>
        </div>
        <div style='display:flex;margin-top:20px'>
        <b>1</b>&ensp; <br>
        <div>
        <textarea id='0' rows='3'></textarea>

        </div>
        
        </div>
        <div style='display:flex;margin-top:20px'>
        <b>2</b>&ensp; <br>
        <div>
        <textarea id='1' rows='3'></textarea>

        </div>
        
        </div>
				
				`,
        answer: [],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P30-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E5/key/E5answerkey.png",
    component: T6,
    // isHiddenCheck: true,
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: `
        Now do the speaking task. Use your notes from exercises 3 and 4.

				`,
        color: "rgb(0,0,0)",
      },
    ],
    textareaStyle: {
      width: 800,
    },
    inputSize: 200,
    questions: [
      {
        title: `
					

          
         
				`,
        answer: [],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
};

export default json;
