import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {	
		unit: 'Unit 2',
		id: 'FGF11-U2-P19-E1',
		audio: '',
		video: '',
		component: T6,
		inputSize: 200,
		isHiddenCheck: true,
		checkUppercase: true,
		maxLength: 22,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page19/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				<span style='font-size: 24px'>Complete the text with the present perfect continuous form 
				of the verbs below.</span>
				`,
				color: 'black',
			},
		],
		hintBox: [
			{
				src: [
					'ask',
					'attract',
					'focus',
					'get',
					'not go',
					'post',
					'not update',
					'visit',
					'write',
				],
				borderColor: 'none',
				width: 800,
				fontColor: '#93338e',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 19,
			height: 25,
			// textTransform: 'uppercase',
		},

		questions: [
			{
				title: `
				<hintbox id=0></hintbox>
        <div style="position: relative">
				<img style="width:19cm; height:16.5cm" src='img/FriendsPlus/Page19/E1/1.jpg' />
				
				<div style='position: absolute; top:124px; left: 33px; width: 650px; font-size:19px'>
				Sam Delaney, a student at Imperial College London, 
				<sup>1 </sup># film reviews since he was 
				eleven years old. At first, he posted them on Facebook, but for 
				the past four years, he <sup>2 </sup># them 
				on his own website – Purple Popcorn. The website 
				<sup>3 </sup># the attention of the media 
				recently, since it emerged that several Hollywood producers 
				<sup>4 </sup># it to read his reviews. 
				‘They’ve finally noticed me,’ says Sam, proudly. ‘They 
				<sup>5 </sup># if they can put lines from my 
				reviews on their own websites and adverts.’
				Since September, Sam <sup>6 </sup>#
				his site very often. That’s because he <sup>7 </sup>#
				to the cinema very much while he’s preparing for exams. 
				‘I <sup>8 </sup># on my university work,’ he 
				says. Even so, his website <sup>9 </sup>#
				a lot of hits thanks to a growing number of followers on Twitter. 

				</div>
        </div>
				`,
				answer: [
					`has been writing`,
					`'s been posting`,
					`has attracted`,
					`have been visiting`,
					`'ve asked`,
					`hasn't been updating`,
					`hasn't been going`,
					`'ve been focusing`,
					`has been getting`,
				],
			},
		],
	},
	2: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P19-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		checkUppercase: true,
		maxLength: 25,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page19/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `<span style='font-size: 25px'>Complete the sentences with the present perfect continuous 
				form of the verbs in brackets and <i>for</i> or <i>since</i>.</span>
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 27,
			width: 270,
			// textTransform: 'uppercase',
		},

		questions: [
			{
				title: `
        <div style="display: flex; margin-top: 30px; font-size: 23px; line-height: 45px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br><br>
          </div>
          <div>
            <div>She #(collect) badges # ten years. <br>She’s got thousands!</div>
            <div>#(you / learn) Japanese # a long time?</div>
            <div>I’m going home. I #(not feel) well # this morning.</div>
            <div>My sister #(drink) coffee # most of the evening and now she can’t sleep! </div>
            
            </div>
        </div>

				`,
				answer: [
					`'s been collecting`,
					`for`,
					`Have you been learning`,
					`for`,
					`haven't been feeling`,
					`since`,
					`has been drinking`,
					`for`,
				],
			},
		],
	},
	3: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P19-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page19/Key/E3answerKey.png',
		component: Circle_Write,
		totalInput: 7,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: `
				Circle the correct answers.
			  `,
				color: 'black',
			},
		],
		question: {
			Write: {
			  isHiddenCheck: true,
			  maxLength: 11,
			  inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
			  answers: [''],
			  initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
					border: '2px solid white',
					padding:'0px 4px',
			
				  },
				  selectWordStyle: {
					padding:'0px 4px',
					border: '2px solid black',
				  }, 
			  limitSelect: 1,
			  listWords: [
				`a <br> b`, //1
				`a <br> b`, //1
				`a <br> b`, //1
				`a <br> b`, //1
				`a <br> b`, //1
				`a <br> b`, //1
				`a <br> b`, //1
	  
			  ],
			  answers: ['0-4', '1-0', '2-0', '3-0', '4-4', '5-4', '6-0'],
			  initialValue: [],
			},
			Layout: `
			<div style='display:flex'>
				<div style='line-height:2'>
				<b>1</b>&ensp;
				You’re two hours late! What __ all afternoon? <br>
				<div style="display: flex;margin-left:40px">

					<div><input id='0' type='Circle'/></div>
					<div style='margin-left:20px'>
					have you done<br>
					have you been doing<br>
						</div>
						</div>

				<b>2</b>&ensp;
				This farm __ to our family for two centuries
				<div style="display: flex;margin-left:40px">
					<div><input id='1' type='Circle'/></div>
					<div style='margin-left:20px'>
					has belonged<br>
					has been belonging<br>
					</div>
				</div>

				<b>3</b>&ensp;
				‘I sent you an email.’ ‘Really? I __ it.’
			<div style="display: flex;margin-left:40px">
					<div><input id='2' type='Circle'/></div>
					<div style='margin-left:20px'>
					haven’t received <br>
					haven’t been receiving<br>
					</div>
			</div>
	  </div>
		
	  <div style='margin-left:100px;line-height'>
			
			<b>4</b>&ensp;
			Is Tom OK? I__him three times this week,<br>but he hasn’t replied.
			<div style="display: flex;margin-left:40px">
					<div><input id='3' type='Circle'/></div>
					<div style='margin-left:20px'>
					haven’t received <br>
					haven’t been receiving<br>
					</div>
			</div>

			<b>5</b>&ensp;
			__ my popcorn? The box is half empty!
			<div style="display: flex;margin-left:40px">
					<div><input id='4' type='Circle'/></div>
					<div style='margin-left:20px'>
					Have you eaten<br>
					Have you been eating<br>
					</div>
			</div>

			<b>6</b>&ensp;
			Is dinner ready yet? You for hours!
			<div style="display: flex;margin-left:40px">
					<div><input id='5' type='Circle'/></div>
					<div style='margin-left:20px'>
					’ve cooked<br>
					’ve been cooking
					</div>
			</div>

			<b>7</b>&ensp;
			I’m really enjoying this novel, but I all of it.
			<div style="display: flex;margin-left:40px">
					<div><input id='6' type='Circle'/></div>
					<div style='margin-left:20px'>
					haven’t understood<br>
					haven’t been understanding<br>
					</div>
			</div>

	  </div>
	</div>	  

			`,
		  },
	},
	4: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P19-E4',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		checkUppercase: true,
		inputSize: 250,
		maxLength: 25,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page19/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<span style='font-size: 24px'>Complete the replies with the present perfect simple or 
				present perfect continuous form of the verbs in brackets.</span>
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 27,
			// textTransform: 'uppercase',
		},
		questions: [
			{
				title: `
        <div style="display: flex; margin-top: 10px; font-size: 22px">
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br><br>
            <span><b>2 </b></span><br><br>
            <span><b>3 </b></span><br><br>
            <span><b>4 </b></span><br><br>
            <span><b>5 </b></span><br><br>
						<span><b>6 </b></span><br><br>
					
          </div>
        
        <div>
					<span><b>A&ensp;</b>Have you finished that history project?</span><br>
					<span><b>B&ensp;</b>No, I haven’t. And I #(do) it all day!</span><br>

					<span><b>A&ensp;</b>Why are your hands so dirty?</span><br>
					<span><b>B&ensp;</b>I #(try) to repair my bike.</span><br>
					
					<span><b>A&ensp;</b>Did your brother forget about football practice?</span><br>
					<span><b>B&ensp;</b>Yes. And I #(remind) him three times this week!</span><br>
					
					<span><b>A&ensp;</b>Did you write a letter to the town council?</span><br>
					<span><b>B&ensp;</b>Yes, but I #(not send) it.</span><br>
					
					<span><b>A&ensp;</b>Are those potatoes ready yet?</span><br>
					<span><b>B&ensp;</b>No, they aren’t. How long #(we / cook) them?</span><br>
					
					<span><b>A&ensp;</b>Has Billy replied to your messages yet?</span><br>
					<span><b>B&ensp;</b>No, he hasn’t. And I #(text) him five times!</span><br>

        </div>

        </div>

				`,
				answer: [
					`'ve been doing`,
					`'ve been trying`,
					`'ve reminded`,
					`haven't sent`,
					`have we been cooking`,
					`'ve texted`,
				],
			},
		],
	},
}

export default json
