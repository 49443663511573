import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Introduction",
    id: "FGF11-I-P6-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 200,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 12,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the definitions with the adjectives below.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "anxious",
          "ashamed",
          "bored",
          "confused",
          "cross",
          "delighted",
          "disappointed",
          "envious",
          "proud",
          "relieved",
          "shocked",
          "terrified",
          "upset",
        ],
        borderColor: "none",
        width: 1000,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
       <b><hintbox id=0></hintbox></b>
        <div style="display: flex; margin-top: 10px">
          <div style='margin-right: 30px'>
            <div><b>1 </b>#&ensp; :</div>
            <div><b>2 </b>#&ensp; :</div>
            <div><b>3 </b>#&ensp; :</div>
            <div><b>4 </b>#&ensp; :</div>
            <div><b>5 </b>#&ensp; :</div>
            <div><b>6 </b>#&ensp; :</div>
            <div><b>7 </b>#&ensp; :</div>
            <div><b>8 </b>#&ensp; :</div>
            <div><b>9 </b>#&ensp; :</div>
            <div><b>10 </b># :</div>
            <div><b>11 </b># :</div>
            <div><b>12 </b># :</div>
            <div><b>13 </b># :</div>
          </div>
        
        <div>
          <span>angry</span><br>
          <span>sad because something is worse than you 
          hoped or expected</span><br>
          <span>happy because something is better than 
          you hoped or expected</span><br>
          <span>not able to understand what is happening</span><br>
          <span>worried</span><br>
          <span>not interested in what is happening</span><br>
          <span>very pleased</span><br>
          <span>very frightened</span><br>
          <span>feeling bad because you did something 
          wrong</span><br>
          <span>unhappy because you want something 
          that belongs to somebody else</span><br>
          <span>happy about something you have achieved</span><br>
          <span>very surprised and upset</span><br>
          <span>unhappy about something that happened</span><br>
        </div>

        </div>
				`,
        answer: [
          `cross`,
          `disappointed`,
          `relieved`,
          `confused`,
          `anxious`,
          `bored`,
          `delighted`,
          `terrified`,
          `ashamed`,
          `envious`,
          `proud`,
          `shocked`,
          `upset`,
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "FGF11-I-P6-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 300,
    isHiddenCheck: true,
    maxLength: 12,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				<span style='font-size: 23px'>Read the speech bubbles. How is each speaker feeling? 
        <br>Choose the best adjective from exercise 1.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "anxious",
          "ashamed",
          "bored",
          "confused",
          "cross",
          "delighted",
          "disappointed",
          "envious",
          "proud",
          "relieved",
          "shocked",
          "terrified",
          "upset",
        ],
        borderColor: "none",
        width: 1000,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <b><hintbox id=0></hintbox></b>
        <div style=" position: relative; padding-top:10px;">
          <div><img style="width: 27cm" src='img/FriendsPlus/Page6/E2/1.jpg';/></div>

          <div style=" position: absolute; top: 98px; left: 86px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 213px; left: 86px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 344px; left: 86px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 98px; left: 599px"><input id='3' ></input></div>
          <div style=" position: absolute; top: 213px; left: 599px"><input id='4' ></input></div>
          <div style=" position: absolute; top: 344px; left: 599px"><input id='5' ></input></div>
          

        </div>

				`,
        answer: [
          `envious`,
          `relieved`,
          `cross`,
          `confused`,
          `disappointed`,
          `ashamed`,
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "FGF11-I-P6-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 230,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 12,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page6/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with personality adjectives from 
        below. There are six extra adjectives.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "brave",
          "confident",
          "flexible",
          "hard-working",
          "honest",
          "kind",
          "loyal",
          "organised",
          "outgoing",
          "patient",
          "punctual",
          "reliable",
          "sensitive",
          "shy",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <b><hintbox id=0></hintbox></b>
        <div style="display: flex; margin-top: 10px">
          <div style='margin-right: 30px'>
            <div><b>1 </b>&ensp;</div>
            <div><b>2 </b>&ensp;</div>
            <div><b>3 </b>&ensp;</div>
            <div><b>4 </b>&ensp;</div>
            <div><b>5 </b>&ensp;</div>
            <div><b>6 </b>&ensp;</div>
            <div><b>7 </b>&ensp;</div>
            <div><b>8 </b>&ensp;</div>
          </div>
        
        <div>
          <span>She’s # : nothing frightens her.</span><br>
          <span>He’s # : he has one job during the week 
          and another at weekends.</span><br>
          <span>She’s really # : all the books in her 
          bookcase are in alphabetical order.</span><br>
          <span>He’s very # : he’s hardly ever late.</span><br>
          <span>She’s # : she always tells the truth.</span><br>
          <span>He’s # : he loves being with people and 
          making new friends.</span><br>
          <span>She’s # : she’s always careful not to 
          upset anyone.</span><br>
          <span>He’s very #: he always supports his 
          friends, whatever the situation.</span><br>
          
        </div>

        </div>
				`,
        answer: [
          `brave`,
          `hard-working`,
          `organised`,
          `punctual`,
          `honest`,
          `outgoing`,
          `kind`,
          `loyal`,
        ],
      },
    ],
  },
  4: {
    unit: "Introduction",
    id: "FGF11-I-P6-E4",
    audio: "",
    video: "",
    component: T6,
    inputSize: 270,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 12,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page6/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				In your opinion, what are the two most important personal 
        qualities for these people? Choose two adjectives from exercise 3.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "brave",
          "confident",
          "flexible",
          "hard-working",
          "honest",
          "kind",
          "loyal",
          "organised",
          "outgoing",
          "patient",
          "punctual",
          "reliable",
          "sensitive",
          "shy",
        ],
        borderColor: "none",
        width: 1000,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px">
          <div style='margin-right: 20px'>
            <div><b>1 </b>&ensp;</div>
            <div><b>2 </b>&ensp;</div>
            <div><b>3 </b>&ensp;</div>
            <div><b>4 </b>&ensp;</div>
          </div>
        
        <div>
          <span>A teacher should be # and #.</span><br>
          <span>A doctor should be # and #.</span><br>
          <span>A TV presenter should be # and #.</span><br>
          <span>A police officer should be # and #.</span><br>
          
        </div>

        </div>
				`,
        answer: [
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          //`hard-working`,
          //`kind`,
          //`confident`,
          //`patient`,
          //`confident`,
          //`outgoing`,
          //`brave`,
          //`honest`,
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "FGF11-I-P6-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/Key/E5answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "5",
        title: `
        Circle the correct adjective.
				`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          color: "rgb(88,147,198)",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `terrifying / terrified`, //0
          `exciting / excited`, // 1
          `tiring / tired`, // 2
          `worrying / worried`, // 3
          `shocking / shocked`, // 4
          `disappointing / disappointed`, // 5
        ],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-0", "5-4"],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>When I took part in my first school play, I was <input id='0' type='Circle' />, but it was<br> an <input id='1' type='Circle' /> experience.</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b>I enjoy gymnastics, but it’s <input id='2' type='Circle' />.</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b>When my dad was ill last month, it was a <input id='3' type='Circle' /> time for all the family.</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b>Have you heard the <input id='4' type='Circle' /> news?</div>
            <div style='margin-bottom:20px'><b>5&ensp;</b>My brother was <input id='5' type='Circle' /> about failing 
            his driving test.</div>

					</div>
          
        `,
    },
  },
  6: {
    unit: "Introduction",
    id: "FGF11-I-P6-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 60,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page6/Key/E6answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `<span style='font-size: 24px'>Complete the questions with a negative prefix. Then write 
        true answers.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="margin-top: 30px">
          <div><b>1&ensp;</b>Are you patient or <input id='0' width='100px'> patient?</div>
          <div><input id='1' width='850px'></div>
          <div><b>2&ensp;</b>Are you enthusiastic about your schoolwork or <input id='2' width='100px'> 
          enthusiastic?</div>
          <div><input id='3' width='850px'></div>
          <div><b>3&ensp;</b>In general, are you organised or <input id='4' width='100px'> organised?</div>
          <div><input id='5' width='850px'></div>
          <div><b>4&ensp;</b>Are you generally reliable or <input id='6' width='100px'> reliable about social 
          arrangements?</div>
          <div><input id='7' width='850px'></div>
        </div>

				`,
        answer: [`im`, ``, `un`, ``, `dis`, ``, `un`, ``],
      },
    ],
  },
};

export default json;
