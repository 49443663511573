import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 175,
    isHiddenCheck: true,
    maxLength: 17,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Label the sports and activities.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
    
        <div style=" position: relative; padding-top:10px; ">
          <div><img style="width: 25cm" src='img/FriendsPlus/Page16/E1/1.jpg';/></div>

          <div style=" position: absolute; top: 168px; left: 22px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 168px; left: 200px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 168px; left: 382px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 168px; left: 577px"><input id='3' ></input></div>
          <div style=" position: absolute; top: 168px; left: 758px"><input id='4' ></input></div>
          
          <div style=" position: absolute; top: 413px; left: 115px"><input id='5' ></input></div>
          <div style=" position: absolute; top: 413px; left: 298px"><input id='6' ></input></div>
          <div style=" position: absolute; top: 413px; left: 484px"><input id='7' ></input></div>
          <div style=" position: absolute; top: 413px; left: 666px"><input id='8' ></input></div>

        </div>

				`,
        answer: [
          `gymnastics`,
          `chess`,
          `weightlifting`,
          `horse riding`,
          `ice hockey`,
          `cards`,
          `ballroom dancing`,
          `ballet`,
          `bowling`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 200,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 8,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the leisure activities with the verbs below.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "bake",
          "collect",
          "hang out",
          "make",
          "read",
          "read",
          "text",
          "use",
          "watch",
        ],
        borderColor: "none",
        width: 800,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 10px; line-height: 45px">
          <div style='margin-right: 30px'>
            <div><b>1 </b>#&ensp; </div>
            <div><b>2 </b>#&ensp; </div>
            <div><b>3 </b>#&ensp; </div>
            <div><b>4 </b>#&ensp; </div>
            <div><b>5 </b>#&ensp; </div>
            <div><b>6 </b>#&ensp; </div>
            <div><b>7 </b>#&ensp; </div>
            <div><b>8 </b>#&ensp; </div>
            <div><b>9 </b>#&ensp; </div>
          </div>
        
        <div>
          <span>cakes</span><br>
          <span>with friends</span><br>
          <span>books</span><br>
          <span>clothes</span><br>
          <span>magazines</span><br>
          <span>your friends</span><br>
          <span>videos online</span><br>
          <span>social media</span><br>
          <span>figures, cards, stamps, etc.</span><br>
          
        </div>

        </div>
				`,
        answer: [
          `bake`,
          `hang out`,
          `read`,
          `make`,
          `read`,
          `text`,
          `watch`,
          `use`,
          `collect`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E3",
    // audio: 'Audios/1.02.mp3',
    video: "",
    component: T6,
    inputSize: 224,
    isHiddenCheck: true,
    maxLength: 35,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page16/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style='font-size: 24px'>Complete the table with the sports and activities below. 
        Then add the sports and activities from exercise 1.</span>
				`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: [
          "basketball",
          "board games",
          "cycling",
          "drama",
          "martial arts",
          "a musical instrument",
          "photography",
          "rollerblading",
          "running",
          "shopping",
          "skateboarding",
          "volleyball",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 26,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				<hintbox id=0></hintbox>
        <div style=" position: relative; padding-top:10px; font-size: 22px; width: max-content">
          <div><img style="width: 27cm" src='img/FriendsPlus/Page16/E3/1.jpg';/></div>

					<div style=" position: absolute; top: 61px; left: 42px">#</div>
          <div style=" position: absolute; top: 91px; left: 42px">#</div>
          <div style=" position: absolute; top: 125px; left: 42px">#</div>
          <div style=" position: absolute; top: 61px; left: 292px">#</div>
          <div style=" position: absolute; top: 91px; left: 292px">#</div>
          <div style=" position: absolute; top: 125px; left: 292px">#</div>
          
          <div style=" position: absolute; top: 197px; left: 42px">#</div>
          <div style=" position: absolute; top: 229px; left: 42px">#</div>
          <div style=" position: absolute; top: 262px; left: 42px">#</div>
          <div style=" position: absolute; top: 294px; left: 42px">#</div>
          <div style=" position: absolute; top: 197px; left: 292px">#</div>
          <div style=" position: absolute; top: 229px; left: 292px">#</div>
          <div style=" position: absolute; top: 262px; left: 292px">#</div>
          
          <div style=" position: absolute; top: 61px;  left: 543px">#</div>
          <div style=" position: absolute; top: 91px;  left: 543px">#</div>
          <div style=" position: absolute; top: 125px; left: 543px">#</div>
          <div style=" position: absolute; top: 158px; left: 543px">#</div>
          <div style=" position: absolute; top: 190px; left: 543px">#</div>
          <div style=" position: absolute; top: 61px;  left: 779px">#</div>
          <div style=" position: absolute; top: 91px;  left: 779px">#</div>
          <div style=" position: absolute; top: 125px; left: 779px">#</div>
					

        </div>

				`,
        answer: [
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          `drama / martial arts / photography / ballet / gymnastics / weightlifting`,
          //`martial arts`,
          //`photography`,
          //`ballet`,
          //`gymnastics`,
          //`weightlifting`,

          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          `basketball / board games / a musical instrument / volleyball / cards / chess / ice hockey`,
          //`board games`,
          //`a musical instrument`,
          //`volleyball`,
          //`cards`,
          //`chess`,
          //`ice hockey`,

          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          `cycling / rollerblading / running / shopping / skateboarding / ballroom dancing / bowling / horse riding`,
          //`rollerblading`,
          //`running`,
          //`shopping`,
          //`skateboarding`,
          //`ballroom dancing`,
          //`bowling`,
          //`horse riding`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E4",
    audio: "Audios/1.07 audio.mp3",
    video: "",
    component: T6,
    inputSize: 230,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 28,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page16/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone name='1.07' src='Audios/1.07 tieu de.mp3'></headphone> Listen to two people talking about their hobbies. 
        Which two activities from exercises 1 and 3 do the speakers mention? 
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style='margin-left: 150px'>
        <img style="width:18.5cm" src='img/FriendsPlus/Page16/E4/1.png' />
        <img style="width:14cm" src='img/FriendsPlus/Page16/E4/2.jpg' />
        <div style=" ; font-size: 21px">
            <div><b>1 </b>&ensp;# and # </div>
            <div><b>2 </b>&ensp;# and # </div>
        </div>
        </div>
        
				`,
        answer: [`photography`, `gymnastics`, `ice hockey`, `running`],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E5",
    audio: "Audios/1.07 audio.mp3",
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page16/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `
				<headphone name='1.07' src='Audios/1.07 tieu de.mp3'></headphone> Listen again. Match the speakers (1 and 2) with the 
        sentences (a–d).
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
    
        <div style=" position: relative; padding-top:10px; ">
          <div><img style="width: 18cm; padding-top:50px;" src='img/FriendsPlus/Page16/E5/1.jpg';/></div>

          <div style=" position: absolute; top: 102px; left: 379px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 135px; left: 429px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 167px; left: 377px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 200px; left: 596px"><input id='3' ></input></div>

        </div>

				`,
        answer: [`2`, `1`, `2`, `1`],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FGF11-U2-P16-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    // maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title: `<span style='font-size: 24px'>Write about two sports and activities you enjoy doing. Say 
        where and when you do them and why you enjoy them.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
      padding:5,
    },
    questions: [
      {
        title: `
        <div style=" font-size: 23px; margin-left: 100px">
				
        <div><textarea id='0' rows='6'></div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
};

export default json;
