import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 45",
    id: "FGF11-WB-demo-2023-U5-P45-E1",
    exerciseKey: "img/FriendsPlus/Page45/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 130,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the verb–noun collocations in the sentences with the words below.               `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 8,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>reports&ensp;&ensp;</span>
        <span>track&ensp;&ensp;</span>
        <span>a hand&ensp;&ensp;</span>
        <span>bridge&ensp;&ensp;</span>
        <span>make&ensp;&ensp;</span>
        </div>
        <div style='line-height:2'>
        <div style='display:flex'><b>1</b> <div style='margin-left:10px'> 
        The young mother takes pictures of her baby on the same day each month in order to keep # of her baby’s growth. 
        </div></div><div style='display:flex'><b>2</b> <div style='margin-left:10px'> 
         Ann is there to # the gap between Nick and his classmates.
        </div></div><div style='display:flex'><b>3</b> <div style='margin-left:10px'> 
         Can you offer me # with this bag? 
        </div></div><div style='display:flex'><b>4</b> <div style='margin-left:10px'> 
          Sam doesn’t take his laptop with him today, so he can’t send # to his manager. 
        </div></div><div style='display:flex'><b>5</b> <div style='margin-left:10px'> 
         During the lesson, Nick sometimes got stuck on the questions, so Ann had to # a few suggestions.
</div></div>
        
        </div>


        
        `,
        answer: [`track`, `bridge`, `a hand`, `reports`, `make`],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P45-E2",
    exerciseKey: "img/FriendsPlus/Page45/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 130,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the text. Are the sentences true (T) or false (F)? `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='position: relative; font-size: 22px'>
      <div style='position: absolute; top: 100px; left: 533px'>
      <b>1</b>&ensp; 
        RoboCup takes place every year. <input/> <br/>
        <b>2</b>&ensp; 
        Twelve countries produce very strong teams.  <input/>
      </div>
        <img style="width:29cm; height:19cm" src='img/FriendsPlus/Page45/E2/1.jpg' />
      </div>

        
        `,
        answer: [`T`, "F"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P45-E3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    // audio: "Audios/1.22.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(67,65,157)'>Reading Strategy</span>. Then circle the correct answers. 			  `,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
          border: '2px solid white',
          padding:'0px 4px',
      
          },
          selectWordStyle: {
          padding:'0px 4px',
          border: '2px solid black',
          }, 
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d `, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
        ],
        answers: ["0-4", "1-8", "2-4", "3-12"],
        initialValue: [],
      },
      Layout: `
      <div style='font-size: 22px'>
      <img style="width:15cm; height:7cm" src='img/FriendsPlus/Page45/E3/1.jpg' /><br>

            <b>1</b>&ensp;
            The movement of each robot is controlled by 
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              the fans of each team. <br/> itself. <br/> the captain of the team. <br/> the owner of the robot. 
                    </div>
                       </div>

            <b>2</b>&ensp;
            During the match, the robots can communicate with 
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
              any other robot on the pitch. <br/> the people who created them. <br/> other robots in the same team. <br/> no other robots or people. 
		          </div>
            </div>

              <b>3</b>&ensp;
              Before the robots can play against humans, 
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              more countries need to get involved. <br/> they need increased intelligence and better movement. <br/> they simply need to become more clever. <br/> the technologies need to be applied in areas other than football. 
		          </div>
            </div>
             <b>4</b>&ensp;
             The main goal of the organisers is to 
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              have the robots play in the real World Cup. <br/> develop technologies that can help in search and rescue. <br/> make it more fun to watch than real footballers. <br/> eventually build robots that can play better than humans.
              RoboCup is a football competition that has taken place every 

		          </div>
            </div>
           </div>

      </div>
      
      `,
    },
  },
};

export default json;
