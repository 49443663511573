import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P48-E1",
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the table.`,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 35,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       
				<div style='display:flex'>
				<div>
				<table style="border-collapse: collapse; width: 500px;">
				<tr>
					<td style="background-color: rgb(181,121,181); color: white; border: 1px solid purple; padding: 10px;"><b>Noun</b> </td>
					<td style="background-color: rgb(181,121,181); color: white; border: 1px solid purple; padding: 10px;"><b>Adjective</b> </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">1&ensp;ambition</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">2&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">cheerful </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">3&ensp;creativity </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>

					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">4&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">enthusiastic </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">5&ensp;flexibility </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">6&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">generous </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">7&ensp;honesty </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">8&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">idealistic </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">9&ensp;intelligence </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">10&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">loyal </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">11&ensp;maturity </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">12&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">modest </td>
				</tr>
			</table>
				</div>
				<div style='margin-left:10px'>
				<table style="border-collapse: collapse; width: 500px;">
				<tr>
					<td style="background-color: rgb(181,121,181); color: white; border: 1px solid purple; padding: 10px;">Noun </td>
					<td style="background-color: rgb(181,121,181); color: white; border: 1px solid purple; padding: 10px;">Adjective </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">13&ensp;optimism </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">14&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">patient  </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">15&ensp;pessimism  </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>

					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">16&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">punctual  </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">17&ensp;realism  </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">18&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">self-confident    </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">19&ensp;seriousness  </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">20&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">shy  </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">21&ensp;sociability  </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">22&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">stubborn  </td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">23&ensp;sympathy  </td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">#</td>
				</tr>
				<tr>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">24&ensp;#</td>
					<td style="background-color: white; color: black; border: 1px solid purple; padding: 10px;">thoughtful
					</td>
				</tr>
			</table>
				</div>
				</div>

        
        `,
        answer: [
          `ambitious`,
          `cheerfulness`,
          `creative`,
          `enthusiasm`,
          `flexible`,
          `generosity`,
          `honest`,
          `idealism`,
          `intelligent`,
          `loyalty`,
          `mature`,
          `modesty`,
          `optimistic`,
          `patience`,
          `pessimistic`,
          `punctuality`,
          `realistic`,
          `self-confidence`,
          `serious`,
          `shyness`,
          `sociable`,
          `stubbornness`,
          `sympathetic`,
          `thoughtfulness`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P48-E2",
    exerciseKey: "img/FriendsPlus/Page48/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
			Complete the phrases for describing personal qualities with the words below.  `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:7,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `

	<div>
	
	<div style="width:980px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
	<span>common &ensp;&ensp;</span>
	<span>communicating &ensp;&ensp;</span>
	<span>courage &ensp;&ensp;</span>
	<span>energy &ensp;&ensp;</span>
	<span>initiative &ensp;&ensp;</span>
	<span>lack &ensp;&ensp;</span>
	<span>sense  &ensp;&ensp;</span>
	<span>skills  &ensp;&ensp;</span>

   
	</div>
	</div>

	
   


	<div style='line-height:1.5;'>
	<div style='display:flex'>
	<b>1</b>&ensp;have a good # of humour
	</div>
   <div style='display:flex;margin-top:5px'>
   <b>2</b>&ensp; have lots of / no # sense       

   </div>

   <div style='display:flex;margin-top:5px'>
   <b>3</b>&ensp; have good organisational # 

   </div>

   <div style='display:flex;margin-top:5px'>
   <b>4</b>&ensp; #  self-confidence


   </div>

   <div style='display:flex;margin-top:5px'>
   <b>5</b>&ensp;have physical #  

   </div>

   <div style='display:flex;margin-top:5px'>
   <b>6</b>&ensp;be good at #  

   </div>

   <div style='display:flex;margin-top:5px'>
   <b>7</b>&ensp;show lots of  #  

   </div>

   <div style='display:flex;margin-top:5px'>
   <b>8</b>&ensp;have lots of #  

   </div>

	
	</div>


	
	`,
        answer: [
          `sense`,
          `common`,
          `skills`,
          `lack`,
          `courage`,
          `communicating`,
          `initiative`,
          `energy`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P48-E3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 320,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 1,
    titleQuestion: [
      {
        num: "3",
        title: `
			Complete the sentences with adjectives or phrases from exercises 1 and 2. 			`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
	

	<div style='line-height:2.3'>
	<div style='display:flex'>
	<b>1</b>
	<div style='margin-left:10px'>
	Sue is very # . She always thinks things will get worse.  
	</div>
	</div>

	<div style='display:flex'>
	<b>2</b>
	<div style='margin-left:10px'>
	Harry loves telling jokes. He has # .  
	</div>
	</div>

	<div style='display:flex'>
	<b>3</b>
	<div style='margin-left:10px'>
	Try to be # . It isn’t good to be late. 
	</div>
	</div>

	<div style='display:flex'>
	<b>4</b>
	<div style='margin-left:10px'>
	She always tells the truth. She’s very # . 	</div>
	</div>

	<div style='display:flex'>
	<b>5</b>
	<div style='margin-left:10px'>
	She’s always telling everyone how clever she is. She isn’t very # . 
	</div>
	</div>
	</div>

	

	<div style='line-height:2.3'>
	<div style='display:flex'>
	<b>6</b>
	<div style='margin-left:10px'>
	He loves being with other people. He’s the most # person I know! 	</div>
	</div>

	<div style='display:flex'>
	<b>7</b>
	<div style='margin-left:10px'>
	She loves dangerous sports. She has great # . 	</div>
	</div>

	<div style='display:flex'>
	<b>8</b>
	<div style='margin-left:10px'>
	I can talk to her about my problems. She always listens. She’s very # . 	</div>
	</div>

	<div style='display:flex'>
	<b>9</b>
	<div style='margin-left:10px'>
	He always knows what to do. You don’t need to tell him. <br/> He # . 	</div>
	</div>

	
	</div>
	`,
        answer: [
          `pessimistic`,
          `a good sense of humour`,
          `punctual`,
          `honest`,
          `modest`,
          `sociable`,
          `physical courage`,
          `sympathetic`,
          `shows lots of initiative`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P48-E4",
    audio: "Audios/1.24 audio.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
			<headphone name='1.24' src='Audios/1.24 tieu de.mp3'></headphone>	Listen to three people describing a friend or family member. Choose two adjectives below that best describe the people. There are two extra adjectives.`,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:7,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `

	<div>
	
	<div style="width:980px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
	<span>ambitious  &ensp;&ensp;</span>
	<span>cheerful  &ensp;&ensp;</span>
	<span>generous  &ensp;&ensp;</span>
	<span>intelligent  &ensp;&ensp;</span>
	<span>optimistic  &ensp;&ensp;</span>
	<span>patient  &ensp;&ensp;</span>
	<span>punctual   &ensp;&ensp;</span>
	<span>serious   &ensp;&ensp;</span>

   
	</div>
	</div>
	<div style='line-height:2;'>
	<b>1</b>&ensp;#&ensp;and # <br/>
	<b>2</b>&ensp;#&ensp;and # <br/>
	<b>3</b>&ensp;#&ensp;and # <br/>

	</div>
	`,
        answer: [
          `cheerful`,
          `optimistic`,
          `intelligent`,
          `ambitious`,
          `serious`,
          `patient`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E5",
    audio: "Audios/1.24 audio.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E5answerKey.png",
    component: T6v2,
    inputSize: 60,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone name='1.24' src='Audios/1.24 tieu de.mp3'></headphone> Listen again. Match the descriptions 1–3 with 
				sentences A–D. There is one extra sentence.`,
        color: "#000000",
      },
    ],
    maxLength: 1,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='font-size: 22px; line-height: 50px'>
				<img style="width: 16cm; height: 6cm" src='img/FriendsPlus/Page48/E5/1.jpg' /><br>
        <div style='margin-left: 50px;display:flex'>
              <b>Answer:&ensp;&ensp;</b>
              &ensp;<b>1 - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>2 - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>3 - #&ensp;&ensp;&ensp;</b>
              
        </div>
        <b style='margin-left: 50px'>The extra sentence is: #</b>
				</div>
          `,
        answer: [`C`, `A`, `D`, "B"],
      },
    ],
  },
};

export default json;
