import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P37-E1",
    exerciseKey: "img/FriendsPlus/Page37/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the compound nouns with the words below. Two are written as one word. 
				`,
        color: "#000000",
      },
    ],
    maxLength: 25,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<span style="font-weight:bold;color:rgb(142,33,137);word-spacing:15px">solar front housing rain rubbish sky</span> <br/>
				<div style='display:flex;margin-top:20px;line-height:2'>
				<div>
				<b>1</b>&ensp;# door <br/>
				

				<b>3</b>&ensp;# estate <br/>
				

				<b>5</b>&ensp;# scraper 
				</div>
				

				
				<div style='margin-left:20px'>
				<b>2</b>&ensp;# dump  <br/>
				
				<b>4</b>&ensp;# panels <br/>
				
				<b>6</b>&ensp;# water
				</div>
 
				
				</div>
        `,
        answer: [
          `front`,
          `housing`,
          `sky | skysrcaper`,
          `rubbish`,
          `solar`,
          `rain | rainwater`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P37-E2",
    exerciseKey: "img/FriendsPlus/Page37/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the sentences with compound nouns from exercise 1. 			`,
        color: "#000000",
      },
    ],
    maxLength: 16,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height:2'>
				<div style='display:flex'>
				<b>1</b>
				<div style='margin-left:10px'>
				We’ve had # fitted on our roof. 		</div>
				</div>

				<div style='display:flex'>
				<b>2</b>
				<div style='margin-left:10px'>
				Joe lives on the 20th floor of a # 	.		</div>
				</div>
				
				<div style='display:flex'>
				<b>3</b>
				<div style='margin-left:10px'>
				There are about 200 homes on the # .
				</div>
				</div>
				<div style='display:flex'>
				<b>4</b>
				<div style='margin-left:10px'>
				Can you open the # for me?
				</div>
				</div>
				
				</div>
        `,
        answer: [`solar panels`, `skyscraper`, `housing estate`, `front door`],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P37-E3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 40,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the texts. Look at the photo and match it with the correct text. `,
        color: "#000000",
      },
    ],
    maxLength: 1,
    checkUppercase: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='display:flex'>
				<div style='flex:4'>
				<img src='img/FriendsPlus/Page37/E3/1.jpg' width='100%'/>

				</div>
				<div style='flex:1;margin-left:20px'>
				<b><i><u>Answer:</u></i></b>&ensp;<input/>
				</div>
				</div>
        `,
        answer: [`A`],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P37-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page37/Key/E4answerKey.png",
    component: T6v2,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    inputSize: 100,
    maxLength: 1,
    titleQuestion: [
      {
        num: "4",
        title: `
				Match the texts (A–C) with questions 1–4 below. One text matches two questions. Underline the evidence in the text that supports your answers.  
				`,
        color: "#000000",
      },
    ],
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
				<img src='img/FriendsPlus/Page37/E3/1.jpg' style='width: 26cm'/><br>
				<b>In which text does the author …</b> <br/>
				<div style='line-height:35px;margin-top:20px'>
				<div style='display:flex'>
				  <b>1</b>
				  <div style='margin-left:20px; flex: 1;'>
					mention that the house isn’t built yet?
				  </div>
				  <div style='margin-left:20px'>
					<input/>
				  </div>
				</div>
				
				<div style='display:flex'>
				  <b>2</b>
				  <div style='margin-left:20px; flex: 1;'>
					refer to an indoor garden? 
				  </div>
				  <div style='margin-left:20px'>
					<input/>
				  </div>
				</div>

				<div style='display:flex'>
				  <b>3</b>
				  <div style='margin-left:20px; flex: 1;'>
				  make it clear that the house has more than one floor? 
				  </div>
				  <div style='margin-left:20px'>
					<input/>
				  </div>
				</div>
				
				<div style='display:flex'>
				  <b>4</b>
				  <div style='margin-left:20px; flex: 1;'>
				  suggest that the house will suit someone with  a playful nature? 
				  </div>
				  <div style='margin-left:20px'>
					<input/>
				  </div>
				</div>
				</div>
				
        `,
        answer: [`B`, `C`, `A`, `A`],
      },
    ],
  },
};

export default json;
