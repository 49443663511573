import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P53-E1",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences with a verb and a preposition below. Use the <br>correct form of the verb.",
        color: "Black",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page53/key/E1answerKey.png",
    component: T6v2,
    // recorder: true,
    // fontSize: 28,
    // maxLength: 10,
    inputSize: 170,
    textAlign: "center",
    isHiddenCheck: true,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },

    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 22px'>
          <div>
            <span style='color: rgb(141,48,145);'><b>Verbs</b>&ensp; work&ensp; concern&ensp; disapprove&ensp; call&ensp; warn&ensp; result&ensp; give&ensp;</span> <br>
            <span style='color: rgb(141,48,145);'><b>Prepositions</b>&ensp; about&ensp; about&ensp; in&ensp; up&ensp; for&ensp; for&ensp; of&ensp;</span>
          </div>
        
          <div><b>1.</b>&ensp; I # # working abroad. I'd prefer to stay in this country.</div>

          <div><b>2.</b>&ensp; Sean's mom usually # him # his spending money wastefully.</div>

          <div><b>3.</b>&ensp; My dad has his own business, but he used to # # a big bank.</div>

          <div><b>4.</b>&ensp; In her book, Silent Spring, Rachel Carson # # new policies to protect humans and the environment.</div>

          <div><b>5.</b>&ensp; As an ambitious girl, she always # herself # starting up her own company.</div>

          <div><b>6.</b>&ensp; Scientists have warned that the widespread use of pesticides may # # harmful effects on human health.</div>

          <div><b>7.</b>&ensp; After graduating from university, I # # the job of a waiter and applied for the position of an accountant.</div>


        </div>
									`,
        answer: [
          `disapprove`,
          `of`,
          `warns`,
          `about`,
          `work`,
          `for`,
          `called`,
          `for`,
          `concerns`,
          `about`,
          `result`,
          `in`,
          `gave`,
          `up`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P52-E5",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E2answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 150,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the newspaper article. Are the sentences true (T) or false (F)?
        `,
        color: "Black",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height:2.4'>

            <div><b>1.</b> Omar Bashir is in his twenties. # </div>

            <div><b>2.</b> He doesn’t have a university qualification. #</div>

            <div><b>3.</b> He finally got a job. #</div>

          </div>
          <img src="/img/FriendsPlus/Page53/E2/1.jpg" style='width: 23cm; height: 13cm'  >
        
        `,
        answer: [`T`, `F`, `T`],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P53-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E3answerKey.png",
    component: T6,
    textareaStyle: { width: "730px" },
    maxLength: 1,
    inputSize: 700,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the <span style='color:rgb(63,65,156)'>Reading Strategy.</span> Then match sentences A–F with gaps 1–4 in the text. There are two extra sentences.
									`,
        color: "Black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    questions: [
      {
        title: `
        <img src="/img/FriendsPlus/Page53/E3/3.jpg" style='width:90%;'  >
        <div style='display:flex'>
        <img src="/img/FriendsPlus/Page53/E3/1.jpg" style='width:50%;'  >
        <img src="/img/FriendsPlus/Page53/E3/2.jpg" style='width:50%;'  >
        </div>

        <div style='display:flex; margin-left:250px; margin-top:20px' >
        
        <div ><b>1.</b> #</div>

        <div style='margin-left:40px;'><b>2.</b> #</div>

        <div style='margin-left:40px;'><b>3.</b> #</div>

        <div style='margin-left:40px;'><b>4.</b> #</div>

        </div>	`,
        answer: [`C`, `A`, `E`, `D`],
      },
    ],
  },
};

export default json;
