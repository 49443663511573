import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 8,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='font-size: 25px'>Complete the polite requests with the words below.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 200,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["could", "if", "mind", "possible", "wonder"],
        borderColor: "none",
        width: 600,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
						<b>5&ensp;</b><br>
          </div>
          <div>
            <div>Would it be # for you to … ?</div>
						<div># you please … ?</div>
						<div>Would you # telling me … ?</div>
						<div>Would you mind # … ?</div>
						<div>I # if …</div>
          </div>
        </div>

				`,
        answer: [`possible`, `Could`, `mind`, `if`, `wonder`],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 100,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style='font-size: 25px'>Rewrite the imperatives as polite requests. Include the 
				words in brackets.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 30,
      width: 750,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br><br>
						<b>4&ensp;</b><br>
					
          </div>
          <div>
            <div>Tidy your room! (could)</div>
						<div>#</div>
						<div>Give me your address! (mind)</div>
						<div>#</div>
						<div>Phone me later! (wonder)</div>
						<div>#</div>
						<div>Bring me some coffee! (possible)</div>
						<div>#</div>
          </div>
        </div>

				`,
        answer: [
          `Could you please tidy your room?`,
          `Would you mind giving me your address?`,
          `I wonder if you could phone me later.`,
          `Would it be possible for you to bring me some coffee?`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E3",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the <span style='color: rgb(43,138,199)'>Writing Strategy</span>. Then read the message. Match 
				the extra information 1–5 with A–D in the message. There is 
				one piece of information you do not need.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 26,
      // textTransform: 'uppercase',
      width: 40,
    },

    questions: [
      {
        title: `
        <div style="padding-top:10px; display: flex; position: relative">
          <img style="width: 26cm" src='img/FriendsPlus/Page15/E3/1.jpg';/>
          <div style='font-size: 23px; margin-left: 50px; display:flex; flex-direction: column; position: absolute; top: 168px; left: 468px'>
              <b>Answer:</b>
              &ensp;<b>A - #</b>
              &ensp;<b>B - #</b>
              &ensp;<b>C - #</b>
							&ensp;<b>D - #</b>
					</div>
          

        </div>

				`,
        answer: [`3`, `5`, `1`, `4`],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 100,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 24px'>Read the advertisement and the task above. Then make brief 
				notes under headings 1–4.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 20,
      height: 80,
      padding: 5,
    },
    questions: [
      {
        title: `
        <div style=" font-size: 20px">
				<img style="width:16cm" src='img/FriendsPlus/Page15/E4/1.jpg' />
          <div style='font-size: 20px'>
            <div><b>1 </b>Information about yourself</div>
              <div><textarea id='0' rows='3'></div>
              
						<div><b>2 </b>Information about your family</div>
              <div><textarea id='1' rows='3'></div>
						<div><b>3 </b>Hobbies and interests </div>
              <div><textarea id='2' rows='3'></div>
						<div><b>4 </b>What information are you requesting?</div>
              <div><textarea id='3' rows='3'></div>
            </div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "5",
        title: `<span style='font-size: 24px'>Write your message. Use your notes from exercise 4 and 
				include a phrase from exercise 1 for your polite request.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
      padding: 5,
    },
    questions: [
      {
        title: `
        <div style=" font-size: 23px; margin-left: 100px">
				<img style="width:15cm" src='img/FriendsPlus/Page15/E5/1.png' />
        <div><textarea id='0' rows='6'></div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "FGF11-U1-P15-E6",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 2,
    component: DesignUnderLine,
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page15/E6/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E6/2.jpg" },
        { url: "img/FriendsPlus/Page15/E6/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page15/E6/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E6/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E6/6.jpg" },
        { url: "img/FriendsPlus/Page15/E6/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page15/E6/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E6/9.jpg" }],
    ],
  },
};

export default json;
