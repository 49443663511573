import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E1",
    exerciseKey: "img/FriendsPlus/Page46/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 145,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Label the icons with the school subjects below.`,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 9,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>art&ensp;&ensp;</span>
        <span>drama&ensp;&ensp;</span>
        <span>geography&ensp;&ensp;</span>
        <span>I.C.T.&ensp;&ensp;</span>
        <span>maths&ensp;&ensp;</span>
        <span>music&ensp;&ensp;</span>
        <span>P.E.&ensp;&ensp;</span>
        <span>science&ensp;&ensp;</span>
        </div>
        <div style='line-height:2'>
       <div style='display:flex;padding:10px'>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/1.jpg' width=90% '/> <br/>
       <b>1</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/2.jpg' width=90% '/> <br/>
       <b>2</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/3.jpg' width=90% '/> <br/>
       <b>3</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/4.jpg' width=90% '/> <br/>
       <b>4</b> #
       </div>
       
       </div>
       <div style='display:flex;padding:10px'>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/5.jpg' width=90% '/> <br/>
       <b>5</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/6.jpg' width=90% '/> <br/>
       <b>6</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/7.jpg' width=90% '/> <br/>
       <b>7</b> #
       </div>
       <div style='padding-left:30px'>
       <img src='img/FriendsPlus/Page46/E1/8.jpg' width=90% '/> <br/>
       <b>8</b> #
       </div>
       
       </div>
        
        </div>


        
        `,
        answer: [
          `drama`,
          `music`,
          `science`,
          `I.C.T`,
          `P.E.`,
          `art `,
          `geography`,
          `maths`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E2",
    // exerciseKey: 'img/FriendsPlus/Page45/Key/E2answerKey.png',
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Look at the photo and the questions below. Complete the answers with your own ideas.
        `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength: 1,
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        <img src='img/FriendsPlus/Page46/E2/1.jpg' width=70% '/>
        <div style='display:flex; font-size: 22px; line-height: 45px'>
        <b>1</b>
        <div style='margin-left:10px'>
        Where are the students? <br/>
        They might be <input/>
        </div>
        </div>
        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        What is the boy with the headset doing? <br/>
        He could be <input/>
        </div>
        </div>


        
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 145,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(1,146,67)'>Speaking Strategy</span>. Then complete the phrases  with the words below. `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 17,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='display:flex'>
        <div>
        
        <div style="width:600px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>kind&ensp;&ensp;</span>
        <span>rather&ensp;&ensp;</span>
        <span>theme&ensp;&ensp;</span>
        <span>unlike&ensp;&ensp;</span>
        <span>maths&ensp;&ensp;</span>
        <span>whereas&ensp;&ensp;</span>
       
        </div>
        </div>

        <div>
        <div style='padding: 10px; border: 5px dotted rgb(1,146,67); border-radius: 20px; width: 600px; background-color: rgb(229, 239, 230);'>
        <span style='color: rgb(1,146,67); font-weight: bold;'>Speaking Strategy</span> <br> 
        <div style='margin-top: 10px;'>
        <span>When you have to compare and contrast photos, try to find at least two things the photos have in common and at least two differences. Learn some key phrases for expressing these similarities and differences.
        </span>
        </div>
      </div>
        </div>
        </div>
       


        <div style='line-height:1.5;margin-top:-87px'>
        <div style='display:flex'>
        <b>1</b>&ensp;The common # in the photos is … <div style="margin-left:10px;width: 40px; height: 40px; border: 2px solid black; border-radius:5px"></div> 
        </div>
       <div style='display:flex;margin-top:5px'>
       <b>2</b>&ensp;Both photos show a … of some # . <div style="margin-left:10px;width: 40px; height: 40px; border: 2px solid black; border-radius:5px"></div>       

       </div>

       <div style='display:flex;margin-top:5px'>
       <b>3</b>&ensp;In the first photo, … , # in the second photo … <div style="margin-left:10px;width: 40px; height: 40px; border: 2px solid black; border-radius:5px"></div>   

       </div>

       <div style='display:flex;margin-top:5px'>
       <b>4</b>&ensp; # the second photo, the first photo shows / does not show … <div style="margin-left:10px;width: 40px; height: 40px; border: 2px solid black; border-radius:5px"></div>   

       </div>

       <div style='display:flex;margin-top:5px'>
       <b>5</b>&ensp;In the second photo, they’re … (-ing) # than … (-ing). <div style="margin-left:10px;width: 40px; height: 40px; border: 2px solid black; border-radius:5px"></div>   

       </div>

        
        </div>


        
        `,
        answer: [`theme`, `kind`, `whereas`, `Unlike`, `rather`],
      },
    ],
  },
  4: {
    audio: "Audios/1.23 audio.mp3",
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E4",
    exerciseKey: "img/FriendsPlus/Page46/Key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.23' src='Audios/1.23 tieu de.mp3'></headphone> Listen to a student comparing photos A and B.  Tick the phrases in exercise 3 which he uses.


              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 5,
        listWords: [`✓ ✓ ✓ ✓ ✓ `],
        answers: ["0-0", "0-4", "0-6"],
        initialValue: [],
      },
      Layout: `
      
      <div style='position: relative;top:0px;left:20px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='display:flex'>
                <div style='margin-right: 20px'>
                  <b>1</b>&ensp; The common theme in the photos is …  <br>
                  <b>2</b>&ensp; Both photos show a … of some kind .  <br>
                  <b>3</b>&ensp; In the first photo, … , whereas  in the second photo …  <br>
                  <b>4</b>&ensp; Unlike the second photo, the first photo shows / does not show …  <br>
                  <b>5</b>&ensp; In the second photo, they’re … (-ing) rather than … (-ing).  <br>

                </div>
                <div>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px;border: 2px solid black;margin:10px 0px; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px;border: 2px solid black;margin:10px 0px; border-radius:15px;padding:5px 20xp'></div>

                </div>
              </div>
              
             
          </div>
        <div style='position:relative;top:-255px;left:781px;color: white; display:flex; flex-direction: column;line-height:50px  '>
          <input id='0' type='Circle'>
          
        </div>
       
      </div>
      <img style="width:19cm; height:7cm;margin-top: -215px" src='img/FriendsPlus/Page46/E4/1.jpg' /><br>
    </div>
       
        
       
           
        
      `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P46-E5",
    // exerciseKey: 'img/FriendsPlus/Page46/Key/E5answerKey.png',
    component: T6v2,
    inputSize: 145,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
          Look at photos C and D above and read the task. Prepare your answer then do the speaking task. Use the questions to help you.  `,
        color: "#000000",
      },
    ],
    textareaStyle: { width: 600,padding:5 },
    // maxLength:7,
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='display:flex'>
        <img style="width:14cm; height:16cm" src='img/FriendsPlus/Page46/E5/1.jpg' />
        <div style='display:flex; flex-direction: column; font-size: 22px'>
        <div>
          <b>1</b>
          <div style='margin-left:10px'>
          Which photo shows secondary students? <br/> Are they working alone or with a teacher?
          <textarea id='0' rows ='2'></textarea>
        </div>
        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        How are the students in each class using technology? <br/>  How do you use it at your school?
        <textarea id='1' rows ='2'></textarea>
        </div>
        </div>
        
        </div>
  
  
          
          `,
        answer: [],
      },
    ],
  },
};

export default json;
