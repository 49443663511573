import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the dialogue with the past simple and would + base form.
				`,
        color: "#000000",
      },
    ],
    maxLength: 22,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='font-size: 23px; line-height: 50px'>
				<div style='display:flex'>
				<b>Tim</b>
				<div style='margin-left:10px'>
				I think we’re lost. If this <sup>1</sup> # (be) the right path, we <sup>2</sup> # (be) there   by now. 
				</div>
				</div>

				<div style='display:flex'>
				<b>Paul</b>
				<div style='margin-left:10px'>
				So which path should we be on?
				</div>
				</div>
				
				<div style='display:flex'>
				<b>Tim</b>
				<div style='margin-left:10px'>
				If I <sup>3</sup> # (know) that, we  <sup>4</sup> # (not be) lost! 				</div>
				</div>

				<div style='display:flex'>
				<b>Paul</b>
				<div style='margin-left:10px'>
				If you <sup>5</sup> # (have) your phone with you, we <sup>6</sup> # (be able to) look at a map. 				</div>
				</div>

				<div style='display:flex'>
				<b>Tim</b>
				<div style='margin-left:10px'>
				We decided not to bring our phones, remember?  A weekend without technology … 				</div>
				</div>

				<div style='display:flex'>
				<b>Paul</b>
				<div style='margin-left:10px'>
				I know. I <sup>7</sup> # (not be) so worried if we <sup>8</sup> # (not be) so far from home. Next time, we should bring our phones, but only use them in an emergency. 				</div>
				</div>

				<div style='display:flex'>
				<b>Tim</b>
				<div style='margin-left:10px'>
				If you <sup>9</sup> # (bring) your phone with you, you <sup>10</sup> # (use) it all the time. You’re addicted!
				</div>
				</div>
				</div>
        `,
        answer: [
          `was`,
          `would be`,
          `knew`,
          `wouldn't be`,
          `had`,
          `would be able to`,
          `wouldn't be`,
          `weren't`,
          `brought`,
          `would use`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P35-E2",
    exerciseKey: "img/FriendsPlus/Page35/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the sentences with the past simple or <i>would</i> + base form, depending on the meaning. 				`,
        color: "#000000",
      },
    ],
    maxLength: 26,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height:50px; font-size: 23px'>
				<div style='display:flex'>
				<b>1</b>
				<div style='margin-left:10px'>
				Our neighbours have still got my badminton net. I wish they # (give) it back! 				</div>
				</div>

				<div style='display:flex'>
				<b>2</b>
				<div style='margin-left:10px'>
				I can’t work with the TV so loud. I wish you # (turn) it down! 				</div>
				</div>
				
				<div style='display:flex'>
				<b>3</b>
				<div style='margin-left:10px'>
				I love that new phone, but it’s really expensive. If only it # (be) cheaper!
				</div>
				</div>
				<div style='display:flex'>
				<b>4</b>
				<div style='margin-left:10px'>
				Unfortunately, I don’t see my cousins very often. I wish they # (live) closer. 				</div>
				</div>
				<div style='display:flex'>
				<b>5</b>
				<div style='margin-left:10px'>
				I want to go to bed, but our guests are still here. If only they # (leave)! 				</div>
				</div>
				<div style='display:flex'>
				<b>6</b>
				<div style='margin-left:10px'>
				I wish it # (be) Friday today. 				</div>
				</div>
				<div style='display:flex'>
				<b>7</b>
				<div style='margin-left:10px'>
				I really want to go skiing tomorrow. If only it # (snow) tonight!				</div>
				</div>
				</div>
        `,
        answer: [
          `would give`,
          `would turn`,
          `was | were`,
          `lived`,
          `would leave`,
          `was | were`,
          `would snow`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P35-E3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 1100,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Rewrite the sentences. Use the second conditional.`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height:1.75'>
				<div style='display:flex'>
				<b>1</b>
				<div style='margin-left:10px'>
				Our house doesn’t have a big garden, so we have to play football in the park. 	<br>
				<span style='color:blue'>If our house had a big garden, we wouldn’t have to play football in the park. </span>		</div>
				</div>

				<div style='display:flex'>
				<b>2</b>
				<div style='margin-left:10px'>
				I don’t live in a detached house, so I can’t play my music really loud. <br/> #		</div>
				</div>
				
				<div style='display:flex'>
				<b>3</b>
				<div style='margin-left:10px'>
				We don’t have a nine-bedroom mansion, so we don’t invite lots of friends and family to stay.  
				 <br> #
				</div>
				</div>
				<div style='display:flex'>
				<b>4</b>
				<div style='margin-left:10px'>
				Our flat is small, so my parents don’t allow me to have  a pet. <br/> #			</div>
				</div>
				<div style='display:flex'>
				<b>5</b>
				<div style='margin-left:10px'>
				I don’t close my bedroom shutters at night, so I wake up early in the morning.  <br/> #
				</div>
				</div>
				<div style='display:flex'>
				<b>6</b>
				<div style='margin-left:10px'>
				They haven’t got a fence. Their dog keeps running away. <br/> # 			</div>
				</div>
				<div style='display:flex'>
				<b>7</b>
				<div style='margin-left:10px'>
				They aren’t interested in gardening. Their flower beds are  a mess. <br/> # 
 			</div>
				</div>
				<div style='display:flex'>
				<b>8</b>
				<div style='margin-left:10px'>
				I share a bedroom with my brother. I can't watch TV at night. <br/>#		</div>
				</div>
				</div>
        `,
        answer: [
          `If I lived in a detached house, I could play my music really loud.`,
          `If we had a nine-bedroom mansion, we would invite lots of friends and family to stay.`,
          `If our flat wasn't so small, my parents would allow me to have a pet.`,
          `If I closed my bedroom shutters at night, I wouldn't wake up early in the morning.`,
          `If they had a fence, their dog wouldn't keep running away.`,
          `If they were interested in gardening, their flower beds wouldn't be a mess.`,
          `If I didn't share a bedroom with my brother, I could watch TV at night.`,
        ],
      },
    ],
  },
};

export default json;
