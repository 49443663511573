import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-U6-P52-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    component: T6v2,
    titleQuestion: [
      {
        num: "1",
        title: `
        <b>Complete the text with the correct form of the phrasal verbs below.</b>
			  `,
        color: "black",
      },
    ],
    inputSize: 200,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "23px",
      // borderStyle:'dotted',
      textAlign: "center",
      fontWeight: "bold",
    },
    isHiddenCheck: true,
    hintBox: [
      {
        src: [
          "come across",
          "come up with",
          "look up",
          "look up to",
          "run out of",
          "throw away",
          `turn into`,
          `work out`,
        ],
        borderColor: "#B979B6",
        width: 700,
        fontColor: "rgb(173,16,182)",
      },
    ],
    questions: [
      {
        title: `
						<b><hintBox id='0'></hintBox></b>
          <div style='background: gainsboro; padding:15px 25px; margin-top:20px; border-radius:20px'>
            <div style='width:21cm;line-height:2; margin:auto;padding:20px'>
              In Kelvin Doe’s home in Sierra Leone, there is no 
              reliable supply of electricity. So when Kelvin, at the 
              age of ten,  <sup>1</sup> # the idea of 
              starting up his own radio station, few people took 
              him seriously. But Kelvin began by making his own 
              battery. He used things which people had 
              <sup>2</sup> # , including a piece of 
              metal and an old cup. Amazingly, it worked! But his 
              battery soon <br/>  <sup>3</sup> # power – so 
              he had to build a generator. He searched a rubbish 
              dump until he   <sup>4</sup> # some old 
              DVD players. He took them apart and used the parts. 
              Next, he needed some electronic equipment for his 
              radio station. How could he build that? He couldn’t 
              <sup>5</sup> # it 
              online or in a library, so he  <sup>6</sup> #
              it for himself. Other 
              children in his town  <sup>7</sup> # him 
              and call him DJ Focus. And his radio station has 
              <sup>8</sup> # an important local facility. 
              People take their phones there to charge them!
            </div>
          </div>
       
									`,
        answer: [
          `came up with`,
          `thrown away`,
          `ran out of`,
          `came across`,
          `look up`,
          `worked out`,
          `look up to`,
          `turned into`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-U6-P52-E2",
    // audio: "Audios/2.04.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E2answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        <div style='max-length: 1000px'>Complete the sentences with one verb and one or two 
        particles below. You can use the words more than once.</div>
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2.5'>
          <div>
            <span style='color: rgb(141,48,145);'><b>Verbs</b>&ensp; count&ensp; hold&ensp; look&ensp; take&ensp;</span> <br>
            <span style='color: rgb(141,48,145);'><b>Particles</b>&ensp; after&ensp; down&ensp; on&ensp; up&ensp;</span>
          </div>
        
          <div><b>1.</b> Can you # my cat while I’m away?</div>

          <div><b>2.</b> Both girls # their aunt; they love art, and so did she.</div>

          <div><b>3.</b> I’d like to talk to you before you leave, but I don’t want to # you # .</div>

          <div><b>4.</b> You shouldn’t # people just because they are poor</div>

          <div><b>5.</b> I need a reliable assistant, and I know I can # you.</div>


        </div>
									`,
        answer: [
          `look after`,
          `take after`,
          `hold`,
          `up`,
          `look down on`,
          `count on`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-U6-P52-E3",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 5,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Rewrite the sentences replacing the underlined words  with 
        a suitable pronoun <br><i>(it, him, her, them)</i>. Remember, pronouns
         go before the particle if the phrasal <br>verb is separable.
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='line-height:2'>
        <div><b>1.</b>&ensp; He needed a lot of courage to ask out <u>the girl next door.</u>  </div> 
        #

        <div><b>2.</b>&ensp; I hope he doesn’t bring up <u>those emails</u> when I see him.  </div> 
        #

        <div><b>3.</b>&ensp; They called off <u>the match</u> because of the snow.  </div> 
        #

        <div><b>4.</b>&ensp; The members are calling for <u>Mr Lewis</u> to resign as their leader.  </div> 
        #

        <div><b>5.</b>&ensp; She’s going to give up <u>eating chocolate</u> for a year.  </div> 
        #

        <div><b>6.</b>&ensp; You should clean up <u>your skates</u> before you sell them.  </div> 
        #
            
      </div>
									`,
        answer: [
          `He needed a lot of courage to ask her out.`,
          `I hope he doesn't bring them up when I see him.`,
          `They called it off because of the snow.`,
          `The members are calling for him to resign as their leader.`,
          `She's going to give it up for a year.`,
          `You should clean them up before you sell them.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-d6mo-2023-U8-P52-E4",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 50,
    textareaStyle: { width: 350, padding: 0, textAlign: "left" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      borderStyle: "dotted",
      fontWeight: "bold",
      textAlign: "center ",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Add the other phrasal verbs in exercise 1 to the table.
        `,
        color: "black",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:20px ">
        <div> <img src='img/FriendsPlus/Page52/E4/1.jpg' style="width:110%" /> </div>
        
        <div style=" position: absolute; top:95px; left: 20px; "><textarea id='0' font-size='23px' rows='3'></textarea></div>
        <div style=" position: absolute; top: 95px; left: 415px; "><textarea id='1' font-size='23px' rows='3'></textarea></div>
    </div>

        
        `,
        answer: [
          `look sth up, throw sth away`,
          `come across sth, come up with sth, look up to sb, run out of sth`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-WB-d6mo-2023-U8-P52-E5",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    textareaStyle: { width: "1000px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      // borderStyle:'dotted',
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Order the words to complete the sentences. Use the table in exercise 4 to help you.
        `,
        color: "black",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:60px; font-size: 22px'>
        <div><b>1.</b>&ensp; Can you buy some more onions? We’ve (them / out / of / run)
        #.</div>

        <div><b>2.</b>&ensp; I can’t find my phone. (you / it / if / across / come) 
        # , tell me.</div> 
        

        <div><b>3.</b>&ensp; If you don’t know the meaning of a word, (up / it / should / you / look) 
        #.</div>

        <div><b>4.</b>&ensp; Listen to this idea. (up / my / came / friend / it / with)
        #.</div>

        <div><b>5.</b>&ensp; I can use these paper plates again, (don’t / away / so / them / throw) 
        #.</div>

            
      </div>

        
        `,
        answer: [
          `run out of them`,
          `If you come across it`,
          `you should look it up`,
          `My friend came up with it`,
          `so don't throw them away`,
        ],
      },
    ],
  },
};

export default json;
