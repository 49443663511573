import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    component: T6,
    // maxLength:1,
    textareaStyle: { width: 250, textAlign: "center" },
    titleQuestion: [
      {
        num: "1",
        title: `
        Read the descriptions and complete the crossword.
				 
				`,
        color: "black",
      },
    ],
    inputSize: 49,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      // fontWeight: "bold",
    },
    questions: [
      {
        title: `
        <div style='display:flex;margin-top:10px;margin-left:100px'><b><i>Key Word:</i></b> <input id='0' width='250px' border-bottom='1px solid black' font-weight='bold'></input></div>
      <div style=" position: relative; padding-top:20px ">
          <div> <img style='height:12cm' src='img/FriendsPlus/Page64/E1/1.jpg' /> </div >
    
        <div style="  position: absolute; top:42px; left: 124px; ">#<input id='1' font-weight='bold'></input>####</div>
        <div style=" position: absolute; top: 98px; left: 179px; "><input id='6' font-weight='bold'></input>#######</div>
        <div style=" position: absolute; top: 153px; left: 180px; "><input id='14' font-weight='bold'></input>#####</div>
        <div style=" position: absolute; top: 208px; left: 11px; ">###<input id='23' font-weight='bold'></input>###</div>
        <div style=" position: absolute; top: 263px; left: 68px; ">##<input id='29' font-weight='bold'></input>#####</div>
        <div style=" position: absolute; top: 319px; left: 10px; ">###<input id='38' font-weight='bold'></input>###</div>
        <div style=" position: absolute; top: 374px; left: 180px; "><input id='42' font-weight='bold'></input>######</div>
        <div style=" position: absolute; top: 429px; left: 124px; ">#<input id='50' font-weight='bold'></input>####</div>
    </div>
    <div style='margin-top:15px;line-height:2'>
        <div style='display:flex'><b>1</b>&ensp; <span>a vehicle with three wheels and an engine that is used as a taxi</span></div>
        
        <div style='display:flex'><b>2</b>&ensp; <span>person who lives at a place</span></div>

        <div style='display:flex'><b>3</b>&ensp; <span>a country in South East Asia on the north coast of Borneo</span></div>

        <div style='display:flex'><b>4</b>&ensp; <span>the capital of Indonesia</span></div>

        <div style='display:flex'><b>5</b>&ensp; <span>a structure built to remind people of a famous person or event</span></div>

        <div style='display:flex'><b>6</b>&ensp; <span>a small vehicle with three wheels, with pedals like a 
        bicycle and used as a taxi</span></div>

        <div style='display:flex'><b>7</b>&ensp; <span>a study of animals</span></div>

        <div style='display:flex'><b>8</b>&ensp; <span>a person who sells things, usually on the street</span></div>
    </div>
   
										`,
        answer: [
          `URBANIZE`,
          `T`,
          `U`,
          `K`,
          `T`,
          `U`,
          `K`,
          `R`,
          `E`,
          `S`,
          `I`,
          `D`,
          `E`,
          `N`,
          `T`,
          `B`,
          `R`,
          `U`,
          `N`,
          `E`,
          `I`,
          `J`,
          `A`,
          `K`,
          `A`,
          `R`,
          `T`,
          `A`,
          `M`,
          `O`,
          `N`,
          `U`,
          `M`,
          `E`,
          `N`,
          `T`,
          `P`,
          `E`,
          `D`,
          `I`,
          `C`,
          `A`,
          `B`,
          `Z`,
          `O`,
          `O`,
          `L`,
          `O`,
          `G`,
          `Y`,
          `V`,
          `E`,
          `N`,
          `D`,
          `O`,
          `R`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E2",
    // audio: "Audios/2.17.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E2answerKey.png",
    component: T6v2,
    maxLength: 10,
    inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
				Complete the compound nouns with words from the list below.
										`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
         <span style='color:rgb(148,37,148);font-weight:bold;line-height:2;'>pollution&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;office&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;vendor&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;park&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;centre<br>
         ticket&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;drinking&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;traffic&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;shop&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;guide <br>
         antenna&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;high&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;city&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;stadium&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;hall</span>
       </div>
      <div style='display:flex'>
      <div style='line-height:2.3;'>
        <div><b>1</b>&ensp; street #</div>

        <div><b>2</b>&ensp; # jam</div>

        <div><b>3</b>&ensp; # tower</div>

        <div><b>4</b>&ensp; car #</div>

        <div><b>5</b>&ensp; city #</div>

        <div><b>6</b>&ensp; noise #</div>
        
        <div><b>7</b>&ensp; # dwellers</div>

        <div><b>8</b>&ensp; football #</div>   
      </div>

      <div style='line-height:2.3; margin-left:100px'>
      <div style='margin-left:10px'><b>9</b>&ensp; tourist #</div>

        <div><b>10</b>&ensp; # booth</div>

        <div><b>11</b>&ensp; information #</div>

        <div><b>12</b>&ensp;  lost property #</div>

        <div><b>13</b>&ensp; souvenir #</div>

        <div><b>14</b>&ensp; # fountain</div>

        <div><b>15</b>&ensp; # street</div>
      </div>

        

      </div>
									`,
        answer: [
          `vendor`,
          `traffic`,
          `antenna`,
          `park`,
          `hall`,
          `pollution`,
          `city`,
          `stadium`,
          `guide`,
          `ticket`,
          `centre`,
          `office`,
          `shop`,
          `drinking`,
          `high`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E3answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 280,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
				Complete the sentences with the compound nouns from exercise 2.
										`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style='display:flex'>
      <div style='line-height:2.5'>
        <div><b>1.</b>&ensp; You can find the new album in any shop on the # ? </div>

        <div style='display:flex'><b>2.</b>&ensp; <span>I lost the way to the hotel, but luckily a # who could speak English <br> showed me the way.</span> </div>

        <div><b>3.</b>&ensp; I do not want to live near the airport; I can’t stand the # .</div>

        <div style='display:flex'><b>4.</b>&ensp; <span>From the view of the hotel room, tourists can see the # with <br> its ancient architecture.</span></div>

        <div><b>5.</b>&ensp; When I first arrived in this city, I went straight to an # to ask for a map. </div>

        <div><b>6.</b>&ensp; The # is not large enough for such a great number of cars. </div>

        <div><b>7.</b>&ensp; If you left your handbag on the tube, you can come to the # and get it back. </div>

        <div><b>8.</b>&ensp; I will be waiting for you at the # round 7pm. The film will start at 7:30. </div>
      </div>
    
    </div>
      

										`,
        answer: [
          `high street`,
          `street vendor`,
          `noise pollution`,
          `city hall`,
          `information centre`,
          `car park`,
          `lost property office`,
          `ticket booth`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E4",
    audio: "Audios/2.05 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E4answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "25px",
      // fontWeight:'bold',
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='2.05' src='Audios/2.05 tieu de.mp3'></headphone>&ensp;Listen to three conversations. Where are the 
        people? <br>Choose from the places below. There are two extra places.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
         <span style='color:rgb(148,37,148);font-weight:bold;line-height:2;'>bus stop&ensp;&ensp;&ensp; 
         monument&ensp;&ensp;&ensp; information centre&ensp;&ensp;&ensp;   
         car park&ensp;&ensp;&ensp; high street</span>
       </div>

       <div style='display:flex;margin-top:20px'>
        <u><b><i>ANSWER:</i></b></u>
        <div style='margin-left:50px;line-height:2.5'>
        <div><b>A:</b>#</div>
        <div><b>B:</b>#</div>
        <div><b>C:</b>#</div>
        </div>
       
       </div>
        `,
        answer: [`information centre`, `car park`, `bus stop`],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Ask for and give advice for each situation. Which piece of
        advice was the best?

        `,
        color: "black",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px;">
          <span style="font-weight: bold;2;" >1</span>
          <span>I cheated in a test.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >2</span>
          <span>Another student is bullying my best friend.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >3</span>
          <span>I’m always late for school.</span>
        </p>
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/14.jpg" /></div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P64-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Read the situation, prepare and practise a new dialogue. Use the key phrases and the
        dialogue in exercise 1 to help you.
        `,
        color: "black",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/15.jpg" /></div>
        <div style="margin-left: 200px;margin-top: 30px"><img src="img/FriendsPlus/Page64/13.jpg" /></div>
        <div style="margin-top: 30px"><img src="img/FriendsPlus/Page64/12.jpg" width="1200px"/></div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
