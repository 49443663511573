import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  // 4: {
  //   unit: 'Cumulative Review 3',
  //   id: 'FGF11-WB-demo-2023-CR-P75-E4',
  //   audio: '',
  //   video: '',
  //   // recorder: true,
  //   exerciseKey: 'img/FriendsPlus/Page75/E4/key/E4answerKey.png',
  //   component: T6v2,
  //   // maxLength: 1,
  //   fontSize: "22px",
  //   inputSize: 130,
  //   stylesTextInput: {
  //     background: 'transparent',
  //     // border: 'none',
  //     fontSize: '22px',
  //     fontWeight: '700',
  //     textAlign: 'center',
  //     width: '80px'
  //   },
  //   titleQuestion: [
  //     {
  //       num: '4',
  //       title: `
  //       Choose the correct answers.
  // 			`,
  //       color: '#262324',
  //     },
  //   ],
  //   questionImage: [],
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: `
  // 	<div style="margin-top: 30px; width: 800px; background-color: rgb(202,201,202)">
  //    <div style="padding: 40px">
  //    <h1 style="font-size: 40px; margin-left: 105px; font-style: italic">ROBOTS IN THE KITCHEN</h1>
  //    <span>
  //    When it comes to cooking, current trends show that people are
  //    spending
  //    </span>
  //    <sup>1</sup>
  //    <input id="0">
  //    <span>
  //    time in the kitchen than ever before. Even those
  //    who know how to prepare a meal have moments when they wish
  //    they
  //    </span>
  //    <sup>2</sup>
  //    <input id="1">
  //    <span>
  //    to make the dinner. Of course, if these people
  //    </span>
  //    <sup>3</sup>
  //    <input id="2">
  //    <span>
  //    that
  //    a robot at home that could cook, they wouldn't have to do
  //    it themselves. However, that is easier said than done. It
  //    </span>
  //    <sup>4</sup>
  //    <input id="3">
  //    <span>
  //    a long time to programme a robot to cook, basically because
  //    there are so
  //    performed
  //    </span>
  //    <sup>5</sup>
  //    <input id="4">
  //    <span>
  //    different decisions involved. The biggest
  //    of these is
  //    </span>
  //    <sup>6</sup>
  //    <input id="5">
  //    <span>
  //    up your mind about what to cook in the first
  //    place! However, a team of American and Australian scientists
  //    has come up with a new idea for teaching a robot to cook: by
  //    showing it videos on YouTube. Now, not everyone is aware
  //    </span></br>
  //    <sup>7</sup>
  //    <input id="6">
  //    <span>
  //    the educational values of YouTube, but the site has thousands of
  //    videos that teach all sorts of things. One of
  //    </span>
  //    <sup>8</sup>
  //    <input id="7">
  //    <span>
  //    useful skills
  //    you can learn is cooking, and this is what caught the researchers'
  //    attention. After using data from 88 different YouTube videos,
  //    their robot was successful
  //    </span>
  //    <sup>9</sup>
  //    <input id="8">
  //    <span>
  //    identifying all of the objects
  //    and actions on the screen. Although the team is pleased
  //    </span>
  //    <sup>10</sup>
  //    <input id="9">
  //    <span>
  //    these results, they admit there is still a long way to go before the
  //    technique is perfected.
  //    </span>
  //    </div>
  // 	</div>
  //   <div style="width: 800px; display: flex">
  //   <div style="width: 30px; text-align: right">
  //        <div> <span style="font-weight: bold">1</span></div>
  //        <div> <span style="font-weight: bold">2</span> </div>
  //        <div> <span style="font-weight: bold">3</span></div>
  //        <div> <span style="font-weight: bold">4</span></div>
  //        <div> <span style="font-weight: bold">5</span></div>
  //        <div> <span style="font-weight: bold">6</span></div>
  //        <div> <span style="font-weight: bold">7</span></div>
  //        <div> <span style="font-weight: bold">8</span></div>
  //        <div> <span style="font-weight: bold">9</span></div>
  //        <div> <span style="font-weight: bold">10</span></div>
  //      </div>
  //      <div style="width: 250px; margin-left: 15px">
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">fewer</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">don't have</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">had</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">does</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">many</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">doing</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">about</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">more</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">about</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">for</span> </div>
  //      </div>
  //      <div style="width: 250px">
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">less</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">didn't have</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">has</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">makes</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">most</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">making</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">for</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">most</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">in</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">to</span> </div>
  //      </div>
  //      <div style="width: 250px">
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">little</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px"></span>wouldn't have</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">have</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">takes</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">much</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">taking</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">of</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">the most</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">on</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">with</span> </div>
  //      </div>
  //    </div>
  // 			`,
  //       answer: [
  //        'b',
  //        'b',
  //        'a',
  //        'c',
  //        'a',
  //        'b',
  //        'c',
  //        'c',
  //        'b',
  //        'c',
  //       ],
  //     },
  //   ],
  // },
  4: {
    unit: "Cumulative Review 3",
    id: "FGF11-WB-demo-2023-CR-P75-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page77/E4/key/E4answerKey.png",
    component: Circle_Write,
    // inputSize: 110,
    textAlign: "center",
    titleQuestion: [
      {
        num: "4",
        title: `
        Choose the correct answers.
				`,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //2
          `a <br> b <br> c`, //3
          `a <br> b <br> c`, //4
          `a <br> b <br> c`, //5

          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //2
          `a <br> b <br> c`, //3
          `a <br> b <br> c`, //4
          `a <br> b <br> c`, //5
        ],
        answers: [
          "0-4",
          "1-4",
          "2-0",
          "3-8",
          "4-0",
          "5-4",
          "6-8",
          "7-8",
          "8-4",
          "9-8",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
  		
 		  <img src='img/FriendsPlus/Page77/E4/1.jpg' style="padding:2px; width: 19cm; height:20cm"/>
    
  	<div style=''>
  	  <div style ='display: flex'>
<div style='margin-right: 150px'>
      
  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>1</b>&ensp;</div>
  		  <div><input id='0' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  fewer <br> less <br> little
  		</div>
  		</div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>2</b>&ensp;</div>
  		  <div><input id='1' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  don’t have <br> didn’t have <br> wouldn’t have
  		</div>
  		</div>
 
  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>3</b>&ensp;</div>
  		  <div><input id='2' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  had <br> has <br> have
  		  </div>
  		</div>

  	  <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>4</b>&ensp;</div>
  		<div><input id='3' type='Circle'/></div>
  		<div style='margin-left:20px'>
  		does <br> makes <br> takes

  		</div>
  	  </div>

      <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>5</b>&ensp;</div>
  		<div><input id='4' type='Circle'/></div>
  		<div style='margin-left:20px'>
  		many <br> most <br> much

  		</div>
  	  </div>
</div>
<div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>6</b>&ensp;</div>
  		  <div><input id='5' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  doing <br> making <br> taking
  		</div>
  		</div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>7</b>&ensp;</div> 
  		  <div><input id='6' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  about <br> for <br> of
  		  </div>
  		</div>

  	  <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>8</b>&ensp;</div>
  		<div><input id='7' type='Circle'/></div>
  		<div style='margin-left:20px'>
  		more <br> most <br> the most

  		</div>
  	  </div>

      <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>9</b>&ensp;</div>
  		  <div><input id='8' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  about <br> in <br> on
  		</div>
  		</div>

      <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>10</b>&ensp;</div>
  		  <div><input id='9' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  for <br> to <br> with
  		</div>
  		</div>
</div>

  	  </div>
  	</div>

    </div>

    </div>

  		`,
    },
  },
  5: {
    unit: "Cumulative Review 3",
    id: "FGF11-WB-demo-2023-CR-P77-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page77/E5/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    fontSize: "22px",
    inputSize: 130,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "700",
      textAlign: "center",
      width: "80px",
    },
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 1200,
      fontSize: 22,
      height: 270,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        You recently saw somebody using their mobile phone
        irresponsibly (for example, being rude or dangerous) in a
        social situation. Write a forum entry in which you:
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 0px; width: 1000px">
         <ul style:"list-style-type: disc">
           <li>relate what you saw.</li>
           <li>compare social situations in the past to those in the present.</li>
           <li>recommend how you think people should behave in this kind of situation.</li>
           <li>ask other contributors to react to your post.</li>
         </ul>
         <textarea id='0' row='3'>
		    </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
