import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P68-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 230,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "21px",
      // fontWeight: 'bold',
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
					
        Complete the text with the infinitive (with or without <i>to</i>) 
        or <i>-ing</i> form of the verbs in brackets.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='background: rgb(232,232,234); width:100%; margin-top:20px;margin-left:50px; border-radius:20px;box-shadow: 10px 10px 10px rgb(207,207,207)'>
        <img src="img/FriendsPlus/Page68/E1/1.jpg" style='width:100%; margin:auto;border-radius:20px' />
        <div style='width:900px;line-height:2; margin:auto;padding:20px; font-size: 21px'>
        Three years ago, an Australian couple from Perth decided 
        <sup><b>1</b></sup> # (go) on holiday – permanently. 
        In other words, they hoped <sup><b>2</b></sup> #  (stay) on holiday for years rather than days. So far, their trip has lasted 
        for three years and they’re enjoying <sup><b>3</b></sup> #  (travel) so much 
        that they will keep <sup><b>4</b></sup> # (do) it for as long as possible.
        Before the trip, Nicole Connolly ran a successful business. But 
        the death of a family member made her <sup><b>5</b></sup> # 
        (think) about her life in a different way. She spent some time 
        <sup><b>6</b></sup> #  (discuss) her ideas 
        with Michael, her husband, and they ended up 
        <sup><b>7</b></sup> #  (make) a decision: they 
        agreed <br> <sup><b>8</b></sup> #  (give up) their home 
        and their jobs in return for freedom and adventure. But they 
        couldn’t put their plan into action immediately. They went on 
        <sup><b>9</b></sup> #  (work) for a year and 
        managed <br> <sup><b>10</b></sup> #  (save) some 
        money. They then sold their house and their possessions. Finally, 
        they could afford <sup><b>11</b></sup> #  (begin) their 
        journey. 
        They started <sup><b>12</b></sup> #  (travel) and 
        they’ve been on the road ever since. They’ve continued 
        <sup><b>13</b></sup> #  (earn) some money by 
        managing an online business, including a successful blog about 
        their lifestyle. And although they miss their friends and family, 
        they don’t miss their old life; they definitely prefer 
        <sup><b>14</b></sup> #  (be) on holiday all the time.
        </div>
      </div>
												`,
        answer: [
          `to go`,
          `to stay`,
          `travelling`,
          `doing`,
          `think`,
          `discussing`,
          `making`,
          `to give up`,
          `working`,
          `to save`,
          `to begin`,
          `to travel / travelling | to travel | travelling`,
          `to earn`,
          `to be / being | to be | being`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P68-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 30,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
      // borderBottom:'dotted',
    },

    titleQuestion: [
      {
        num: "2",

        title: `
					 Complete the sentences with the infinitive or <i>-ing</i> form 
           of the verbs in brackets. Your answers will depend on the meaning.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height:2.5; font-size: 23px">
        <div><b>1.</b>&ensp; <span>She tried # (open) her suitcase, but it was impossible without the key.</span></div>

        <div style='display:flex'><b>2.</b>&ensp; <span>I’m not surprised you found the film confusing – you 
        didn’t stop # (talk) all <br>the way through!</span></div>

        <div><b>3.</b>&ensp; <span>I keep waking up at 3 a.m. I’ve tried # (go) to bed earlier, but it doesn’t help.</span></div>

        <div><b>4.</b>&ensp; <span>I’ll never forget # (visit) India when I was a child.</span></div>

        <div><b>5.</b>&ensp; I don’t remember # (buy) this T-shirt. Maybe somebody gave it to me.</div>

        <div><b>6.</b>&ensp; We often forget # (turn) the computer off at night.</div>
    
    </div>				
											`,
        answer: [
          `to open`,
          `talking`,
          `going`,
          `visiting`,
          `buying`,
          `to turn`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P68-E3",
    audio: "",

    video: "",
    // recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page68/Key/E3 answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 400,
    textareaStyle: { width: 700, padding: 0 },
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      borderBottom: "dotted",
      // textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Complete the sentences with your own words. Use an infinitive in sentences <br> 1 and 3 and an -ing form in 2 and 4.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height:2">
        <div style='display:flex'><b>1.</b>&ensp; <textarea id='0' rows='3'>I often forget</textarea></div>

        <div style='display:flex'><b>2.</b>&ensp; <textarea id='1' rows='3'>I don’t remember</textarea></div>

        <div style='display:flex'><b>3.</b>&ensp; <textarea id='2' rows='3'>I stopped</textarea></div>

        <div style='display:flex'><b>4.</b>&ensp; <textarea id='3' rows='3'>I stopped</textarea></div>


    
    </div>				
											`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P68-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E4answerKey.png",
    component: T6v2,
    textareaStyle: { width: 700, padding: 0 },
    maxLength: 16,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: 'center',
      fontSize: "23px",
      fontWeight: "bold",
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
					
        Read the <span style='color:rgb(147,37,143)'>Vocab boost!</span> box. Then write sentences which include these verbs. <br>Underline the verb patterns.

											`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height:2;margin-left:50px">
    <img src="img/FriendsPlus/Page68/E4/1.jpg" style='width:100%; margin:auto;border-radius:20px' />
        <div style='display:flex'><b>1.</b>&ensp; <textarea id='0' rows='3'>(avoid doing sth)</textarea></div>

        <div style='display:flex'><b>2.</b>&ensp; <textarea id='1' rows='3'>(choose to do sth)</textarea></div>

        <div style='display:flex'><b>3.</b>&ensp; <textarea id='2' rows='3'>(not mind doing sth)</textarea></div>

        <div style='display:flex'><b>4.</b>&ensp; <textarea id='3' rows='3'>(expect to do sth)</textarea></div>


    
    </div>				
											`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P68-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E5answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
					
        Complete the dialogue using the correct active or passive form of the verbs in brackets.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2'>
        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'> Your room looks completely different from the last time I <b><u>visited</u></b> (visit)!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>  Yes, that’s because it <sup>1</sup> # (paint) last week. And we <sup>2</sup> # (get) new pictures for the walls, too</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'> Wow. <sup>3</sup> # (you / do) the painting? It <sup>4</sup> # (look) amazing!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>No. It <sup>5</sup> # (do) by a friend of the family. He <sup>6</sup> # (do) a good job, didn’t he?</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>Yes. And the cushions match the colour of the walls, too!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>Ah, they <sup>7</sup> # (not be) home-made. They <sup>8</sup> # (buy) for my mum last year, but she gave them to me.</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>Well, I hope you <sup>9</sup> # (please) with your room. I just love it!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>Thanks – so do I. How about your room? 
        <sup>10</sup> # (it / decorate) often?</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>No, not enough! I’m going to ask my 
        parents about that!</span></div>


    </div>
		
	
			
								`,
        answer: [
          `was painted`,
          `got`,
          `Did you do`,
          `looks`,
          `was done`,
          `did`,
          `aren't`,
          `were bought`,
          `'re pleased`,
          `Is it decorated`,
        ],
      },
    ],
  },
};

export default json;
