import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P42-E1",
    exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 42,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the gadgets in the sentences. 			`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <div style='line-height:2;width:1200px'>
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        He used a c#######r to record the school play. 
        </div>
        </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        I need a new M## #####r so I can listen to music when I go running. 
        </div>
        </div>
        
        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>
        Without the s####v, we would never have found the hotel.
        </div>
        </div>

        <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        She wears a B######## h#####t so she can speak to the office while she’s in her car.
        </div>
        </div>

        <div style='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        His s########h doesn’t just tell him what the time is but also how far he’s walked every day.        </div>
        </div>

        <div style='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        If I had a g#### c###### in my bedroom, I probably wouldn’t do much homework. 
        </div>
        </div>

        <div style='display:flex'>
        <b>7</b>
        <div style='margin-left:10px'>
        Now that I’ve bought this t####t, I hardly ever use my laptop. 
        </div>
        </div>

        <div style='display:flex'>
        <b>8</b>
        <div style='margin-left:10px'>
        My new d###### r#### is also a B######## <br/> s#####r, so I can use it to stream music from my s#########. 
        </div>
        </div>

        <div style='display:flex'>
        <b>9</b>
        <div style='margin-left:10px'>
        I’ve saved all of my schoolwork onto a  m##### s###k.

        </div>
        </div>
        </div>

        `,
        answer: [
          `a`,
          `m`,
          `c`,
          `o`,
          `r`,
          `d`,
          `e`,
          `P`,
          `3`,
          `p`,
          `l`,
          `a`,
          `y`,
          `e`,
          `a`,
          `t`,
          `n`,
          `a`,
          `l`,
          `u`,
          `e`,
          `t`,
          `o`,
          `o`,
          `t`,
          `h`,
          `e`,
          `a`,
          `d`,
          `s`,
          `e`,
          `m`,
          `a`,
          `r`,
          `t`,
          `w`,
          `a`,
          `t`,
          `c`,
          `a`,
          `m`,
          `e`,
          `s`,
          `o`,
          `n`,
          `s`,
          `o`,
          `l`,
          `e`,
          `a`,
          `b`,
          `l`,
          `e`,
          `i`,
          `g`,
          `i`,
          `t`,
          `a`,
          `l`,
          `a`,
          `d`,
          `i`,
          `o`,
          `l`,
          `u`,
          `e`,
          `t`,
          `o`,
          `o`,
          `t`,
          `h`,
          `p`,
          `e`,
          `a`,
          `k`,
          `e`,
          `m`,
          `a`,
          `r`,
          `t`,
          `p`,
          `h`,
          `o`,
          `n`,
          `e`,
          `e`,
          `m`,
          `o`,
          `r`,
          `y`,
          `t`,
          `i`,
          `c`,
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P38-E1",
    audio: "Audios/1.21 audio.mp3",
    video: "",
    component: T6v2,
    stylesTextInput: {
			// borderStyle: 'dashed',
			borderWidth: 1,
        borderColor: 'black',
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
			fontSize: '24px',
			textAlign: 'center',
		}, 
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page42/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='1.21' src='Audios/1.21 tieu de.mp3'></headphone> Read the <span style='color:#D2365C'>Listening Strategy</span>. Then listen to four short dialogues. Are the sentences below facts (F) or opinions (O)? 
              `,
        color: "#000000",
      },
    ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page42/E2/1.jpg' style='margin-left:100px;width=100%'/>
        <div style='line-height:2;margin-top:20px;margin-left:100px'>
          <div><b>1.</b>&ensp; The best place to keep a copy of photos is on a memory stick. #</div>
          <div><b>2.</b>&ensp; Smartwatches will get cheaper over the next few years. #</div>
          <div><b>3.</b>&ensp; Watching films on a tablet is more popular than going to the cinema #</div>
          <div><b>4.</b>&ensp; People buy fewer gadgets now than they bought in the past. #</div>
        </div>

        
        `,
        answer: [`O`, `O`, `F`, `O`, ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U5-P40-E3",
    audio: "Audios/1.21 audio.mp3",

    exerciseKey: "img/FriendsPlus/Page42/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='1.21' src='Audios/1.21 tieu de.mp3'></headphone> Complete the phrases for expressing opinions with the words below. Then listen again and check. 
              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    maxLength: 17,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="line-heigth: 50px; width:800px;font-size:23px;font-weight:bold;color:rgb(142,15,177);">
        <span>as&ensp;&ensp;</span>
        <span>in&ensp;&ensp;</span>
        <span>opinion&ensp;&ensp;</span>
        <span>problem&ensp;&ensp;</span>
        <span>to&ensp;&ensp;</span>
        </div>
        <div style='line-height:2'>
        <b>1</b>&ensp;# , … <br/>
        <b>2</b>&ensp;# I see it, …  <br/>
        <b>3</b>&ensp;# my view, …  <br/>
        <b>4</b>&ensp;The # is …  <br/>
        <b>5</b>&ensp;It seems # me that …  <br/>

        </div>


        
        `,
        answer: [`opinion`, `as`, `in`, `problem`, `to`],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P42-E4",
    exerciseKey: "img/FriendsPlus/Page42/Key/E4answerKey.png",
    component: T6v2,
    // inputSize: 150,
    // stylesTextInput: {

    // 		fontSize: '26px',
    // 		textAlign: 'center',
    //   	},
    isHiddenCheck: true,
    // maxLength: 8,
    hideBtnFooter: true,
    textareaStyle: { width: 820,padding:5 },
    titleQuestion: [
      {
        num: "4",
        title: `
				Express your own opinions about these topics using the phrases from exercise 3. 
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
       <b>1</b>
       <div style='margin-left:10px'>
       students using their phones in school <br/>
       <textarea id='0' rows='2'></textarea>
       </div>
       </div>

       <div style='display:flex'>
       <b>2</b>
       <div style='margin-left:10px'>
       having hundreds of ‘friends’ on social media  <br/>
       <textarea id='1' rows='2'></textarea>
       </div>
       </div>
        `,

        answer: [`Few`, `little`, `a little`, `a few`, `few`, `little`],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P42-E5",
    exerciseKey: "img/FriendsPlus/Page42/Key/E5answerKey.png",
    audio: "Audios/1.22 audio.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone name='1.22' src='Audios/1.22 tieu de.mp3'></headphone> Listen to four dialogues. Circle the correct answers. 
			  `,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
          border: '2px solid white',
          padding:'0px 4px',
      
          },
          selectWordStyle: {
          padding:'0px 4px',
          border: '2px solid black',
          }, 
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c `, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-0", "1-8", "2-8", "3-4"],
        initialValue: [],
      },
      Layout: `
     



            <b>1</b>&ensp;
            In dialogue 1, which sentence is a fact, not an opinion? 
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              They are driving along a road called West Way. <br/> There can’t be two hotels with the same name in the same part of town. <br/> The Adelphi Hotel has two buildings.

                    </div>
                       </div>

            <b>2</b>&ensp;
            In dialogue 2, why do they decide to use the paper map, not the phone? 
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      There is no map app on their phone. <br/> Mobile phone reception is not reliable. <br/> The map on the phone is not big enough.

		          </div>
            </div>

              <b>3</b>&ensp;
              In dialogue 3, which sentence is an opinion, not a fact? 
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              They can’t drive along the High Street. <br/> There’s a market next to the park on Saturdays. <br/> The fastest route to the station is along Park Avenue.

		          </div>
            </div>
             <b>4</b>&ensp;
             In dialogue 4, why will the man’s mistake not make him late? 
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              The train he is on is faster than the train he wanted. <br/> He can still catch the train he wanted. <br/> Both trains go to the same destination.


		          </div>
            </div>
           </div>



        
      `,
    },
  },
};

export default json;
