import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P72-E1",
    audio: "Audios/2.09 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/E1/key/E1answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 37,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 35,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='2.09' src='Audios/2.09 tieu de.mp3'></headphone>&ensp;
         Listen to four speakers talking about their
         memories of family holidays. Match the speakers
         1-4 with the adjectives describing attitude a-d. 
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 1</span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 2</span>
           <input id="1">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 3</span>
           <input id="2">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 4</span>
           <input id="3">
           </div>
           
           <div>
           <span style="font-weight: bold">a</span>
           <span style="margin-left: 20px">bitter</span>
           </div>

           <div>
           <span style="font-weight: bold">b</span>
           <span style="margin-left: 20px">grateful</span>
           </div>

           <div>
           <span style="font-weight: bold">c</span>
           <span style="margin-left: 20px">nostalgic</span>
           </div>

           <div>
           <span style="font-weight: bold">d</span>
           <span style="margin-left: 20px">sarcastic</span>
           </div>
					`,
        answer: ["c", "d", "a", "b"],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P72-E2",
    audio: "Audios/2.09 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/E2/key/E2answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 38,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      textTransform: "uppercase",
      height: 35,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='2.09' src='Audios/2.09 tieu de.mp3'></headphone>
         Listen again. Match speakers 1-4 with
         sentences A-E. There is one extra sentence. 
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 1</span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 2</span>
           <input id="1">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 3</span>
           <input id="2">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">Speaker 4</span>
           <input id="3">
           </div>
           
           <div>
           <span style="font-weight: bold">A</span>
           <span style="margin-left: 20px">He / She didn't get on with the rest of the family on holiday.</span>
           </div>

           <div>
           <span style="font-weight: bold">B</span>
           <span style="margin-left: 20px">He / She had to put up with a lot of discomfort while they were away.</span>
           </div>

           <div>
           <span style="font-weight: bold">C</span>
           <span style="margin-left: 20px">He / She used to create problems when the family went on holiday.</span>
           </div>

           <div>
           <span style="font-weight: bold">D</span>
           <span style="margin-left: 20px">He / She looked forward to going on holiday each year.</span>
           </div>

           <div>
           <span style="font-weight: bold">E</span>
           <span style="margin-left: 20px">He / She didn't mind travelling with his/her family.</span>
           </div>
					`,
        answer: ["D", "B", "A", "E"],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P71-E3",
    audio: "",
    video: "",
    // recorder: true,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page72/E3/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      // height: 170,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<div style='max-width: 1000px'>
        Work in pairs. A family that you know is planning
        their summer holiday. The family is composed of
        the parents, who are in their forties, their children,
        a teenage daughter and an eight-year-old son, and
        the children's seventy-year-old grandfather. Give
        the family some advice about the holiday. Discuss
        the following points:</div>
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 0px; width: 1000px">
         <ul style:"list-style-type: disc">
           <li>where they could go</li>
           <li>where they could stay</li>
           <li>how they could travel</li>
           <li>what they could do together</li>
         </ul>
         <textarea id='0' rows='8'>
		    </div>
					`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P71-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/E4/key/E4answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 60,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "18px",
      // fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the article. Match sentences A-G with gaps 1-5 in the
        article. There are two extra sentences.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
        <span style= "font-weight: bold; margin-right:15px">A</span>
        <span>She asked Ann and Elizabeth to take part in her research, and they agreed.</span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">B</span>
        <span>The thing that most twins always want to know is which of them was born first.</span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">C</span>
        <span>When she was an adult, she made several attempts to locate her, but without success. </span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">D</span>
        <span>There she met her husband and went back with him to settle in the USA.</span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">E</span>
        <span>The reason for so much emotion is that twin sisters Ann Hunt and Elizabeth Hamel have not seen each other since they were babies, 78 years ago. </span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">F</span>
        <span>She doesn't think her adoptive mother had any idea she was a twin.</span>
        </div>

        <div>
        <span style= "font-weight: bold; margin-right:15px">G</span>
        <span>In 2001, Ann collected a copy of her own birth certificate from the register office and the search began. </span>
        </div>

        <div style= "background-color: rgb(227,229,231); margin-top: 50px; width: 1400px; margin-bottom: 30px">
        <h1 style= "font-size: 55px; padding-top: 25px; padding-left: 315px; font-family: futura-pt">Together again - after 78 years!</h1>
        <div style= "padding: 0px 50px 40px 60px">
        <span>
         Imagine the scene: two sisters in their seventies running to greet each
         other with tears in their eyes.'Lizzie, Lizzie, how lovely!' shouts one.
         'How lovely to see you in the flesh!' cries the other.
         </span>
        <sup>1</sup>
        <span style= "width: 60px; border-bottom: 2px solid gray; color: rgb(227,229,231)">aaaai</span>
        <span>
        According to the Guinness World Records, the two women are the longest
        separated twins ever.
        </span> <br>
        <span>
        The twins' mother, Alice Lamb, was unable to bring up both girls
        because she was a domestic cook in the house of an employer. She
        gave Ann up for adoption because she was the healthier of the two.
        Ann was fourteen when she found out she was adopted, and once her
        adoptive mother died, she decided to start looking for her birth mother. 
        </span>
        <sup>2</sup>
        <span style= "width: 60px; border-bottom: 2px solid gray; color: rgb(227,229,231)">aaaai</span>
        <span>With the help of her daughter, she also found a copy of Alice's
        birth certificate, and later the two discovered that Alice had married a
        man who already had a son called Albert.
        </span> <br>
        <span>
        Alice's stepson had died, but his son was able to tell them that Alice
        had a daughter in the USA. That was how they found out about
        Elizabeth. Alice Lamb had originally intended for both of her daughters to be
        adopted, but in the end, she had to keep Elizabeth because of her poor
        health. At first, Elizabeth stayed with an aunt and then with a woman
        who did not care for her properly, prompting Alice to take Elizabeth to
        live with her in the house where she worked. Elizabeth remembers her
        mother telling her when she was fifteen that she was a twin, but at
        the time she thought it would be impossible to find her sister.
        </span>
        <sup>3</sup>
        <span style= "width: 60px; border-bottom: 2px solid gray; color: rgb(227,229,231)">aaaai</span>
        <span>So she was more than delighted to receive Ann's letter in 2014, and
        minutes after reading it, the two were talking on the phone.
        </span> <br>
        <span>
        The reunion was arranged in Los Angeles at the invitation of psychologist
        Nancy Segal, who has been studying twins separated at birth for nearly
        forty years. During her studies, Ms Segal has discovered that twins
        growing up in completely different families can share many similar
        attitudes.
        </span>
        <sup>4</sup>
        <span style= "width: 60px; border-bottom: 2px solid gray; color: rgb(227,229,231)">aaaai</span>
        <span>
        At first glance, the sisters appeared to have quite a lot
        in common: both had married men called Jim, who had recently died,
        so both women became widows. Both of them were grandmothers and
        evidence from photos shows that they both like to pose in front of the
        camera.
        </span> <br>
        <span>
        However, there were also big differences in their lives. After leaving
        school, Ann worked for a printer until she got married, and remained all
        her life in the town where she was born. Elizabeth left school to work in
        a sweet shop, but after going to night school, she joined the navy and
        moved to Malta.
        </span>
        <sup>5</sup> 
        <span style= "width: 60px; border-bottom: 2px solid gray; color: rgb(227,229,231)">aaaai</span>
        <span>
        Ms Segal is going to take a DNA sample from
        each twin and study all their similarities and differences to establish
        whether Ann and Elizabeth are identical or non-identical twins. As for the
        sisters themselves, the most remarkable thing for them is that after such
        a short time, they feel like they have known each other all their lives.
        </span>
        </div>
        </div>

        <span style= "font-weight:bold">1.</span> <input id="0">
        <span style= "font-weight:bold; margin-left: 35px">2.</span> <input id="1">
        <span style= "font-weight:bold; margin-left: 35px">3.</span> <input id="2">
        <span style= "font-weight:bold; margin-left: 35px">4.</span> <input id="3">
        <span style= "font-weight:bold; margin-left: 35px">5.</span> <input id="4">
					`,
        answer: ["E", "G", "C", "A", "D"],
      },
    ],
  },
};

export default json;
