import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P94-E1",
    audio: "",
    video: "",
    component: T6v2,
    maxLength: 13,
    inputSize: 120,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page94/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Check the meaning of the words in the box.
Use the words to complete the text below.

				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    stylesTextInput: {
      fontSize: 22,
      height: 24,
      background: "none",
      borderBottom: "none",
      color: "white",
    },
    hintBox: [
      {
        src: [
          "alter",
          "crops",
          "diseases",
          "gene",
          "genetic engineering",
          "herbicides",
          "nutrients",
          "weeds",
        ],
        width: 500,
        borderColor: "#e22f3b",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='position: relative;margin-left:20px'>
				<img src="img/FriendsPlus/Page94/E1/1.jpg" />
				<span style='position: absolute; top: 397px; left: 92px'><input id='0' ></input></span>
				<span style='position: absolute; top: 677px; left: 72px'><input id='1' width='120px' ></input></span>
				<span style='position: absolute; top: 78px; left: 567px'><input id='2'  width='215px'></input></span>
				<span style='position: absolute; top: 130px; left: 1013px'><input id='3' ></input></span>
				<span style='position: absolute; top: 160px; left: 970px'><input id='4' ></input></span>
				<span style='position: absolute; top: 187px; left: 1042px'><input id='5' ></input></span>
				<span style='position: absolute; top: 399px; left: 640px'><input id='6' ></input></span>
				<span style='position: absolute; top: 452px; left: 1008px'><input id='7' ></input></span>
			</div>

			
									`,
        answer: [
          "gene",
          "alter",
          "Genetic engineering",
          "crops",
          "nutrients",
          "diseases",
          "herbicides",
          "weeds",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P94-E2",
    audio: "Audios/3.06.mp3",
    video: "",
    component: T6v2,
    // maxLength: 13,
    inputSize: 40,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page94/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				<headphone name="3.06"></headphone >
				Read and listen to the text. Match
headings A–E with paragraphs 1–5.
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
						
          <div style='position: relative;'>
							<img src='img/FriendsPlus/Page94/E2/1.jpg'>
    					<span style='position: absolute; top: 28px; left: 30	px;line-height:50px'>
								<b>A</b>&ensp;What genetic engineering can do #<br>
								<b>B</b>&ensp;Doubts about genetic engineering #<br>
								<b>C</b>&ensp;An example of genetic engineering #<br>
								<b>D</b>&ensp;Defining genes #<br>
								<b>E</b>&ensp;Genetic engineering and weeds #
							</span>
					</div>
            
			
									`,
        answer: ["3", "5", "1", "2", "4"],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P94-E3",
    audio: "",
    video: "",
    // recorder: true,
    component: T6v2,
    maxLength: 5,
    inputSize: 100,
    textAlign: "center",
    stylesTextInput: {
      // fontSize: 19,
      background: "transparent",
      borderBottom: "dash",
    },
    exerciseKey: "img/FriendsPlus/Page94/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",

        title: `
				Answer the questions.
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      
        <div style='display:flex'>
        			<div >
								<div>
        			  	<b>1</b>&ensp;Why did scientists genetically modify a
									tomato? 
										<br><textarea id='0' rows='2'></textarea>
								</div>
								<div>
        			  	<b>2</b>&ensp;What do genes do? <br><textarea id='1' rows='2'></textarea>
								</div>
							</div>
							<div style='margin-left:50px'>
								<div>
        			  	<b>3</b>&ensp;What effect can herbicides have on crops? <br><textarea id='2' rows='2'></textarea>
								</div>
								<div>
        			  	<b>4</b>&ensp;What sort of plants can resist herbicides? <br><textarea id='3' rows='2'></textarea>
								</div>
							</div>
				</div>

					<div>
          	<b>5</b>&ensp;Why do some people oppose the use of GMOs? <br><textarea id='4' rows='4'></textarea> 
					</div>
		
			
									`,
        answer: [
          "They did it to develop tomatoes which can grow in cold temperatures.",
          "They determine your eye colour, how tall you are, what skills you have, and so on.",
          "Herbicides are chemicals which can damage crops.",
          "GM crops and superweeds can resist herbicides.",
          "Some people oppose the use of GMOs because they are worried about the overuse of chemicals and the possible effects of eating GMOs.",
        ],
      },
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P94-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Ask and answer
				the questions. `,
        color: "#e22f3b",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page94/E4/1.jpg",
        },
      ],
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P94-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Create a poster
        to show what happens in the water cycle.<br>
        Include information from this page.`,
        color: "#e22f3b",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
          
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
