import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P51-E1",
    // audio: "Audios/2.03.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E1answerKey.png",
    component: Circle_Write,
    maxLength: 6,
    inputSize: 90,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "20px",
      // borderStyle:'dotted',
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Choose the correct option to complete the sentences.
        `,
        color: "black",
      },
    ],

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-4", "1-0", "2-8", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <div style ='flex:1;width:100%'>
           <div style='display:flex;'>
           <b>1</b>&ensp;&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp; well off, Sebastian's parents could afford to 
           send him to a private school. <br>
           </div>
            <div style="display:flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px;'>
              Been <br>
              Being <br>
              To be
            </div>
            </div>

             <div style='display:flex;'>
                <b>2</b>&ensp;&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp;  badly last night, Jaime is in a bad mood. <br>
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      Having slept<br>
              Sleeping <br>
              To sleep
		          </div>
            </div>

            <div style='display:flex;'>
            <b>3</b>&ensp;&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp;  
            at the checkout, I remembered that I needed to buy bread. <br>
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      To have stood <br>
              Stood <br>
              Standing
		          </div>
            </div>

            <div style='display:flex;'>
            <b>4</b>&ensp;&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp;  
            into debt once, Anne has limited the use of her credit card. <br>
            </div>
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      Had got <br>
              Having got <br>
              To have got
		          </div>
            </div>
           </div>

           <div style='display:flex;'>
           <b>5</b>&ensp;&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp;  
           a fan of Sherlock Holmes, I have all the Conan Doyle novels. <br>
           </div>
            
           <div style="display: flex;margin-left:40px">
             <div><input id='4' type='Circle'/></div>
             <div style='margin-left:20px'>
             Being <br>
             To have been <br>
             To have been
             </div>
           </div>


           <div style='display:flex;'>
           <b>6</b>&ensp;&ensp; Suddenly&ensp; <span style='border-bottom:1px solid black;width:150px;margin-bottom:10px'></span>&ensp;  
           how late it was, we decided to go home. <br>
           </div>
           
           <div style="display: flex;margin-left:40px">
             <div><input id='5' type='Circle'/></div>
             <div style='margin-left:20px'>
             realised <br>
             realising <br>
             to be realised
             </div>
           </div>
    </div>


        
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P51-E2",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E2answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 400,
    textareaStyle: { width: "700px" },
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "20px",
      // borderStyle:'dotted',
    },
    titleQuestion: [
      {
        num: "2",

        title: `Choose the correct option (a–c) to complete the text.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 200, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-4", "1-8", "2-0", "3-4", "4-0", "5-8", "6-4"],
        initialValue: [],
      },
      Layout: `
      <img src="/img/FriendsPlus/Page51/E1/1.jpg" style='margin-left:120px'  >
    <div style='display:flex;margin-top:20px'>
    <div>
    <div style='display:flex;'>
    <b>1</b>
    </div>
     <div style="display:flex;margin-left:40px">
       <div><input id='0' type='Circle'/></div>
       <div style='margin-left:20px;'>
       Marked <br>
       Marking <br>
       To mark
     </div>
     </div>

      <div style='display:flex;'>
         <b>2</b>&ensp;&ensp; 
     </div>
     <div style="display: flex;margin-left:40px">
       <div><input id='1' type='Circle'/></div>
       <div style='margin-left:20px'>
       Celebrating<br>
       Celebrated <br>
       Having celebrated
       </div>
     </div>

     <div style='display:flex;'>
     <b>3</b>&ensp;&ensp;
     </div>
     <div style="display: flex;margin-left:40px">
       <div><input id='2' type='Circle'/></div>
       <div style='margin-left:20px'>
       hoping <br>
       having hoped <br>
       hoped
       </div>
     </div>

     <div style='display:flex;'>
     <b>4</b>&ensp;&ensp;
     </div>
      <div style="display: flex;">
     <div style="display: flex;margin-left:40px">
       <div><input id='3' type='Circle'/></div>
       <div style='margin-left:20px'>
       taken place <br>
       taking place <br>
       having taken placed
       </div>
     </div>
    </div>
    
    </div>

  <div style='margin-left:150px'>
    <div style='display:flex;'>
    <b>5</b>&ensp;&ensp; 
    </div>
    <div style="display: flex;margin-left:40px">
      <div><input id='4' type='Circle'/></div>
      <div style='margin-left:20px'>
      not wanting <br>
      not having wanted <br>
      not wanted
      </div>
    </div>


    <div style='display:flex;'>
    <b>6</b>&ensp;&ensp;
    </div>
    <div style="display: flex;margin-left:40px">
      <div><input id='5' type='Circle'/></div>
      <div style='margin-left:20px'>
      offered <br>
      having offered <br>
      offering
      </div>
    </div>

    <div style='display:flex;'>
    <b>7</b>&ensp;&ensp; 
    </div>
    <div style="display: flex;margin-left:40px">
      <div><input id='6' type='Circle'/></div>
      <div style='margin-left:20px'>
      have encouraged <br>
      encouraging <br>
      encouraged
      </div>
    </div>
   </div>
         
    </div>


        
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P51-E3",
    // audio: "Audios/2.03.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E3answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      fontSize: "22px",
      // borderStyle:'dotted',
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Write the words in the correct order to make sentences 
        using gerunds, perfect gerunds and perfect participles. 
        Insert a comma in the correct place where necessary.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="line-height:50px; font-size: 22px">
          <div>
          <b>1.</b>&ensp; <span> the/ exam/ celebrating/ passed/ are/ Having/ their/ students</span>
            <div><input id="0" rows="2"></input></div>
          </div>
          <div>
          <b>2.</b>&ensp; <span> to/ Feeling/ lie-in/ I’ve/ have/ a/ lazy/ decided</span>
            <div><input id="1" rows="2"></input></div>
          </div>
          <div>
          <b>3.</b>&ensp; <span> he/ the/ on/ climbed/ ladder/ Holding/ up/ tight</span>
            <div><input id="2" rows="2"></input></div>
          </div>
          <div>
          <b>4.</b>&ensp; <span> success/ the/ for/ interview/ key/ a/ to/ job/ Preparing/ is/ well</span>
            <div><input id="3" rows="2"></input></div>
          </div>
          <div>
          <b>5.</b>&ensp; <span> a/ is/ French/ Our/ of/ teacher/ thinking/ opening/ France/ hotel/ in</span>
            <div><input id="4" rows="2"></input></div>
          </div>
          <b>6.</b>&ensp; <span> for/ they/ Having/ walk/ went/ finished/ breakfast/ out/ a&ensp;</span>
            <div><input id="5" rows="2"></input></div>
          </div>
        </div>
        
        `,
        answer: [
          `Having passed their exam, the students are celebrating.`,
          `Feeling lazy, I've decided to have a lie-in.`,
          `Holding on tight, he climbed up the ladder.`,
          `Preparing well for a job interview is the key to success.`,
          `Our French teacher is thinking of opening a hotel in France.`,
          `Having finished breakfast, they went out for a walk.`,
        ],
      },
    ],
  },

  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P51-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E4answerKey.png",
    component: T6v2,
    textareaStyle: { width: 900, fontSize: "22px", padding: 5 },
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // borderStyle:'dotted',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Rewrite each pair of sentences as a single sentence, using perfect <br>participle or perfect gerund.
        `,
        color: "black",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="line-height:30px; font-size: 22px">
          <div>
          <b>1.</b>&ensp; <span> I had looked at all designs available. I chose a pair of brown biker boots.</span>
            <div><textarea id="0" rows="2"></textarea></div>
          </div>
          <div>
          <b>2.</b>&ensp; <span> I received the boots. I was shocked to find they do not look like the photo on your website.</span>
            <div><textarea id="1" rows="2"></textarea></div>
          </div>
          <div>
          <b>3.</b>&ensp; <span> He was very nervous during his first interview. He was not offered the job.</span>
            <div><textarea id="2" rows="2"></textarea></div>
          </div>
          <div>
          <b>4.</b>&ensp; <span> My cousin finished her first degree in maths. She did a second one in computer science.</span>
            <div><textarea id="3" rows="2"></textarea></div>
          </div>
          <div >
          <span style="display:flex">  <b>5.</b>&ensp; Laszlo Block has worked as a senior-vice president for many years. He’s experienced in<br> finding new employees for Google.</span>
            <div><textarea id="4" rows="2"></textarea></div>
          </div>
        </div>
        
        `,
        answer: [
          `Having looked at all designs available, I chose a pair of brown biker boots.`,
          `Having received the boots, I was shocked to find they do not look like the photo on your website.`,
          `Having been very nervous during his first interview, he was not offered the job.`,
          `Having finished her first degree in maths, my cousin did a second one in computer science.`,
          `Having worked as a senior-vice president for many years, Laszlo Block's experienced in finding new employees for Google.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P51-E5",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "700px" },
    // maxLength: 6,
    inputSize: 400,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Write about a TV programme that you like. Use the present and past passive forms of the verbs and your own ideas.
        `,
        color: "black",
        star: 3,
      },
    ],
    hintBox: [
      {
        src: [`award`, "base / set", "play", "show", "watch"],
        borderColor: "#1dbfd7",
        width: 500,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div> <textarea id="0" rows="4"> </div>
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
