import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P12-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 17,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='font-size: 25px'>Complete the sentences with the correct form of the phrasal 
        verbs below.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["go in for", "go through with", "put up with", "run out of"],
        borderColor: "none",
        width: 600,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
          </div>
          <div>
            <div>We # money two days after we arrived!</div>
						<div>He’s so annoying. How do you # him?</div>
						<div>Personally, I don’t # dangerous sports.</div>
						<div>She wasn’t brave enough to # the plan.</div>
          </div>
        </div>

				`,
        answer: [`ran out of`, `put up with`, `go in for`, `go through with`],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P12-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 190,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 15,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the plot summary of the film <i>Big</i> with the correct form of the phrasal verbs below.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "catch up with",
          "fit in with",
          "get away with",
          "get on with",
          "get up",
          "get up",
          "make up",
          "walk out on",
        ],
        borderColor: "none",
        width: 800,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="position: relative; margin-left: 50px">
				<img style="width:25cm; height:25cm" src='img/FriendsPlus/Page12/E2/1.jpg' />
				<div style='position: absolute; top:239px; left: 139px; width: 748px; font-size:22px; line-height: 36px'>
				At a travelling carnival,twelve-year-old Josh Baskin is 
        embarrassed when he is too small for one of the rides. 
        He puts a coin in a fortune-telling machine, which tells 
        him to make a wish – so Josh wishes he was big. When 
        he <sup>1 </sup># the next morning, he notices that his 
        wish has come true: he is an adult, although still a child 
        inside. His mother thinks he is a burglar, so he has to 
        leave home. He tries to <sup>2 </sup># to the carnival, 
        but it has left town.<br>
        On his own, Josh needs money, so he finds a job at a toy 
        company. He doesn't really have the skills he needs for 
        work, but he&ensp;<sup>3</sup># it because he loves toys. 
        In fact, he does well at work, but he finds it difficult to 
        <sup>4 </sup># the other adults there. The only person 
        he really <sup>5 </sup># is a female colleague called 
        Susan Lawrence.
        One day, Josh’s friend Billy tells him that the carnival is 
        back in town. Josh <sup>6 </sup># Susan in the middle 
        of an important meeting at work. When she 
        <sup>7 </sup># him, he is at the fortune-telling 
        machine. Finally, she realises that he did not 
        <sup>8 </sup># the story about being a child. The 
        two say goodbye before Josh puts another coin in the 
        machine and becomes a child again.
				</div>
        </div>
				`,
        answer: [
          `gets up`,
          `go back`,
          `gets away with`,
          `fit in with`,
          `gets on with`,
          `walks out on`,
          `catches up with`,
          `make up`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P12-E3",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 8,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page12/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `<span style='font-size: 25px'>Complete the sentences with one or two prepositions below.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 120,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "away",
          "back",
          "for",
          "for",
          "in",
          "in",
          "on",
          "to",
          "up",
          "up",
          "up",
        ],
        borderColor: "none",
        width: 800,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
						<b>5&ensp;</b><br>
						<b>6&ensp;</b><br>
						<b>7&ensp;</b><br>
          </div>
          <div>
            <div>Look # his address on the internet.</div>
						<div>It was so dark inside the cave that he was afraid to go #.</div>
						<div>I’ve always looked # my grandmother; she’s a brave and intelligent person.</div>
						<div>He’s very reliable; he never goes # a promise.</div>
						<div>I like sport, but I don’t really go # martial arts.</div>
						<div>The beach was fantastic. It made # the hotel, which wasn’t great.</div>
						<div>The police saw the burglar, but he still managed to get #.</div>
					</div>
        </div>

				`,
        answer: [`up`, `in`, `up to`, `back on`, `in for`, `up for`, `away`],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P12-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 32,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 25px'>Rewrite the sentences replacing the underlined object with a 
				pronoun (<i>him, her, it, them, </i>etc.).</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 350,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 30px; line-height: 40px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br><br>
						<b>4&ensp;</b><br><br>
						<b>5&ensp;</b><br><br>
          </div>
          <div>
            <div>She likes my friends and tries to get on with <u>&ensp;my friends&ensp;</u>.</div>
						<div>She likes my friends and tries to #.</div>
						<div>I don’t know where the restaurant is. Let’s look up
						<u>the location</u> on the internet.</div>
						<div>I don’t know where the restaurant is. Let’s #.</div>
						<div>They’d planned the robbery carefully, but didn’t go through 
						with <u>the plan</u>.</div>
						<div>They’d planned the robbery carefully, but #.</div>
						<div>We haven’t eaten here before; we just came across <u>the
						restaurant</u> while we were walking around town.</div>
						<div>We haven’t eaten here before; we just #.</div>
						<div>He’s always so rude. I don’t think I can put up with <u>his
						rudeness</u> much longer.</div>
						<div>He’s always so rude. I don’t think I can #.</div>
					</div>
        </div>

				`,
        answer: [
          `get on with them`,
          `look it up`,
          `didn't go through with it`,
          `came across it`,
          `put up with it`,
        ],
      },
    ],
  },
};

export default json;
