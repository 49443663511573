import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P36-E1",
    exerciseKey: "img/FriendsPlus/Page36/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the phrases with <i>do, make</i> and <i>take</i>. 				`,
        color: "#000000",
      },
    ],
    maxLength: 4,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height:2.25'>
				<div style='display:flex'>
				<b>1</b>
				<div style='margin-left:10px'>
				# a look at something / a picture / a photo  
				</div>
				</div>

				<div style='display:flex'>
				<b>2</b>
				<div style='margin-left:10px'>
				# an appointment / up your mind 
				</div>
				</div>
				
				<div style='display:flex'>
				<b>3</b>
				<div style='margin-left:10px'>
				# yoga / karate / gymnastics / your best 			</div>
				</div>

				
				</div>
        `,
        answer: [`take`, `make`, `do`],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P36-E2",
    exerciseKey: "img/FriendsPlus/Page36/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the dialogue with the correct form of <i>do, make,</i> or <i>take</i>.
				`,
        color: "#000000",
      },
    ],
    maxLength: 4,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height:2'>
	<div style='display:flex'>
		 <div style='flex:1'>
		 <b>Man</b>
		 </div>
		<div style='margin-left:-150px;flex:4'>
			I love your house. It’s really unusual.
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Woman</b></div>
		<div style='margin-left:10px'>
			Yes, I designed it myself. You must <sup>1</sup> # take a look at the basement. I’m really proud of it.
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'>
		 <b>Man</b>
		 </div>
		 <div style='margin-left:-150px;flex:4'>
		 Thanks. Wow! This is amazing. It’s huge! Can I <sup>2</sup> # take a photo?
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Woman</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 Yes, of course.
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Man</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 What are you going to use the basement for?
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Woman</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 It might be a guest room – or maybe a fitness room. I can’t <sup>3</sup> # make up my mind. At the moment, my daughters <sup>4</sup> # do gymnastics down here. They love it!
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Man</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 So, if you’re happy to be in our magazine, I need some photos and an interview. Maybe one day next week?
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Woman</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 OK, I’ll <sup>5</sup> # do my best, but I’m quite busy at the moment. Can you call my personal assistant and <sup>6</sup> # schedule an appointment?
		</div>
	</div>

	<div style='display:flex'>
		 <div style='flex:1'><b>Man</b></div>
		 <div style='margin-left:-150px;flex:4'>
		 Yes, of course.
		</div>
	</div>
</div>

        `,
        answer: [`take`, `take`, `make`, `do`, `do`, `make`],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P36-E3",
    exerciseKey: "img/FriendsPlus/Page36/Key/E3answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
			Circle the correct verbs. Use a dictionary to help you. 
						`,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `make  / take`, // 0
          `made  / took`, // 1
          `does / takes`, // 2
          `do / take`, // 3
          `make / take`, // 4,
          `makes / takes`,
          `did / made`,
          `did / took`,
        ],
        answers: ["0-0", "1-6", "2-0", "3-4", "4-4", "5-0", "6-4", "7-0"],
        initialValue: {},
      },
      Layout: `
		
			  <div style='line-height:2'>
			  <div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">1 </span>
				How many goals have we scored? I <input id='0' type='Circle' style="letter-spacing: 10px;"/> it six. 
			</div>
	
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">2 </span>
				She could be a really good tennis player if she <input id='1' type='Circle' style="letter-spacing: 10px;"/> it more seriously. 
			</div>
	
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">3 </span>
				My neighbour works in London. I’m not sure what he <input id='2' type='Circle' style="letter-spacing: 10px;"/> , but it must be well paid.
			</div>
	
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">4 </span>
				My cousin refuses to <input id='3' type='Circle' style="letter-spacing: 10px;"/> painkillers even when  she’s ill. 
			</div>
	
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">5 </span>
				Don’t <input id='4' type='Circle' style="letter-spacing: 10px;"/> this the wrong way, but that jumper doesn’t really suit you. 
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">6 </span>
			I like travelling, but flying <input id='5' type='Circle' style="letter-spacing: 10px;"/> me anxious. 
			</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">7 </span>
			We only had three days in Paris, but we <input id='6' type='Circle' style="letter-spacing: 10px;"/> the most of it. 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">8 </span>
			The doctors were amazing and <input id='7' type='Circle' style="letter-spacing: 10px;"/> everything they could to help.

		</div>
			  </div>
		  
				
					`,
    },
  },
  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P36-E4",
    exerciseKey: "img/FriendsPlus/Page36/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Read the <span style='color:rgb(142,33,137)'>Vocab boost!</span> box. Then use a dictionary to complete the phrases with do, make, or take. 
				`,
        color: "#000000",
      },
    ],
    isHiddenCheck: true,
    maxLength: 4,
    questions: [
      {
        title: `
				<div style=''>
				<div style='margin-left:250px'>
				<img src='img/FriendsPlus/Page36/E4/1.jpg' width='70%'/>

				</div>
				<div style='line-height:2;margin-top:10px'>
				<b>1</b>&ensp;Can you # me a favour and hold my bag? <br/>
				<b>2</b>&ensp;Try not to # a mess before the visitors arrive.  <br/>
				<b>3</b>&ensp;I can’t understand his text – it doesn’t # sense.  <br/>
				<b>4</b>&ensp;There’s no hurry – # your time.  <br/>
				<b>5</b>&ensp;I don’t mind you playing computer games, but you also need to # time for your homework. <br/>
				<b>6</b>&ensp;Lisa looks really upset. I wish I could # something to help.
				<br/>
				</div>
				</div>
				
        `,
        answer: [`do`, `make`, `make`, `take`, `make`, `do`],
      },
    ],
  },
};

export default json;
