import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P50-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/E1/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 252,
    maxLength: 8,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "20px",
      fontWeight: "bold",
      height: 23,
      // color: "#9D2595"
    },
    titleQuestion: [
      {
        num: "1",
        // star:1,
        title: `
		    Read the <span style="color: #BD0D44">Listening Strategy</span>. Then complete the table with
        the headings below. 
							`,
        color: "#262324",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			  <img src="img/FriendsPlus/Page50/E1/1.jpg" width="600px"/>
        <div>
        <span style= "color: rgb(157,37,149); font-weight: bold; margin-left: 8px">contrast</span>
        <span style= "color: rgb(157,37,149); font-weight: bold; margin-left: 30px">emphasis</span>
        <span style= "color: rgb(157,37,149); font-weight: bold; margin-left: 30px">example</span>
        <span style= "color: rgb(157,37,149); font-weight: bold; margin-left: 30px">result</span>
        </div>
        <img src="img/FriendsPlus/Page50/E1/2.jpg" width="600px"/>
        <div style="position: relative; top: -291px; left: 34px"><input id="0"></div>
        <div style="position: relative; top: -329px; left: 330px"><input id="1"></div>
        <div style="position: relative; top: -224px; left: 34px"><input id="2"></div>
        <div style="position: relative; top: -262px; left: 330px"><input id="3"></div>
							`,
        answer: ["emphasis", "contrast", "result", "example"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P50-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page50/E2/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
        Choose the correct linking word or phrase in each pair of
        the sentences.
        `,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `Consequently, / Mind_you,`, //1
          `For_instance, / However,`, //1
          `For_example, / For_that_reason,`, //1
          `Indeed, / However`, //1
          `As_a_result, / Mind_you,`,
          `in_fact, / though,`,
        ],
        answers: ["0-0", "1-0", "2-4", "3-0", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
      
        <div style='display:flex'>
          <div style ='line-height:2; font-size: 22px'>
             <div><b>1</b>&ensp; She worked hard at school. <input id='0' type='Circle'/> she did well in her exams. </div>
           
             <div><b>2</b>&ensp; The town has excellent leisure facilities. <input id='1' type='Circle'/> there is a new sports centre. </div>

             <div><b>3</b>&ensp; The journey had been long and tiring. <input id='2' type='Circle'/> they decided to get an early night. </div>

             <div><b>4</b>&ensp; The weather was extremely cold. <input id='3' type='Circle'/> it reached -12°C one night. </div>

             <div><b>5</b>&ensp; Ellie spent all afternoon at the beach. <input id='4' type='Circle'/> she got slightly sunburned. </div>

             <div><b>6</b>&ensp; The house is in a terrible condition - <input id='5' type='Circle'/> it would be impossible to live there. </div>
          </div>
        </div>
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P50-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/E3/key/E3answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 38,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 35,
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Match sentences 1-5 with endings a-f. Use the linking
        words to help you. There is one extra ending. 
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           <div style='line-height: 50px; font-size: 22px'>
           <div>
           <span style="font-weight: bold; margin-right: 10px">1</span>
           <span>She stayed up all night doing her homework. </span>
           <span style="border-bottom: 2px solid gray">As a result</span>
           <span>, she </span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">2</span>
           <span>They climbed the mountain in one day, </span>
           <span style="border-bottom: 2px solid gray">though</span>
           <span> it </span>
           <input id="1">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">3</span>
           <span>She really disliked the film. </span>
           <span style="border-bottom: 2px solid gray"> In fact</span>
           <span>, she</span>
           <input id="2">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">4</span>
           <span>He applied for about twenty jobs. </span>
           <span style="border-bottom: 2px solid gray">However</span>
           <span>, he</span>
           <input id="3">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">5</span>
           <span>He decided he wanted to be a journalist. </span>
           <span style="border-bottom: 2px solid gray">Consequently</span>
           <span>, he</span>
           <input id="4">
           </div>
           
           <div style="margin-top: 25px">
           <span style="font-weight: bold">a</span>
           <span style="margin-left: 20px">eventually managed to get in touch.</span>
           </div>

           <div>
           <span style="font-weight: bold">b</span>
           <span style="margin-left: 20px">sent his CV to all the national newspapers.</span>
           </div>

           <div>
           <span style="font-weight: bold">c</span>
           <span style="margin-left: 20px">left before the end.</span>
           </div>

           <div>
           <span style="font-weight: bold">d</span>
           <span style="margin-left: 20px">overslept and was late for school.</span>
           </div>

           <div>
           <span style="font-weight: bold">e</span>
           <span style="margin-left: 20px">was difficult and exhausting.</span>
           </div>

           <div>
           <span style="font-weight: bold">f</span>
           <span style="margin-left: 20px">only got two or three interviews.</span>
           </div>
           </div>
					`,
        answer: ["d", "e", "c", "f", "b"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P50-E4",
    audio: "Audios/1.25 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/E4/key/E4answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 950,
    fontSize: "22px",
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.25' src='Audios/1.25 tieu de.mp3'></headphone>
        You are going to listen to a radio interview about
        Margaret Fuller. First, read the sentences and circle the correct
        endings. Then listen and check your answers.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`,
          `a <br> b`, //1
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      
      
          <b>1</b>&ensp;
           Margaret was an excellent student. <span style="border-bottom: 2px solid gray">In fact</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 by the age of six, she was translating Latin poetry into English. <br>
                 she always found Latin particularly difficult.
                </div>
              </div>
            </div>

          <b>2</b>&ensp;
           Boys often learned Latin, but girls learned different things: <span style="border-bottom: 2px solid gray">for instance</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 girls usually started school at a later age.<br>
                 how to behave in public.
                </div>
              </div>
            </div>

          <b>3</b>&ensp;
           She taught herself several languages and read great literature from around the world. <span style="border-bottom: 2px solid gray">Indeed</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 novels from Germany, France and Italy. <br>
                 many people considered her the best-educated person on the East Coast.
                </div>
              </div>
            </div>
          <b>4</b>&ensp;
           At that time, there were certain jobs which people thought of as suitable for women: <span style="border-bottom: 2px solid gray">for example</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 being a teacher. <br>
                 Margaret was not interested in these jobs.
                </div>
              </div>
            </div>
          <b>5</b>&ensp;
           Some of the reviews she wrote were very negative. <span style="border-bottom: 2px solid gray">As a result</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='4' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 she was not always popular with novelists and poets of her time. <br>
                 she praised good writing when she came across it.
                </div>
              </div>
            </div>
          <b>6</b>&ensp;
           She definitely tried to make the world a better place. <span style="border-bottom: 2px solid gray">Mind you</span>,
            <div style="display: flex;margin-left:40px">
		          <div><input id='5' type='Circle'/></div>
		            <div style='margin-left:20px'>
                 she particularly wanted to help people who couldn't help themselves. <br>
                 during her lifetime, she was actually best known for being bad-tempered!
                </div>
              </div>
            </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P50-E5",
    audio: "Audios/1.25 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/E5/key/E5answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 40,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 35,
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone name='1.25' src='Audios/1.25 tieu de.mp3'></headphone>
        Listen again. Are the sentences true (T) or false (F)?
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height: 60px'>
           <div>
           <span style="font-weight: bold; margin-right: 10px">1</span>
           <span>Margaret Fuller was educated both at home and at school. </span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px">2</span>
           <span>In those days, most girls were expected to read great works of literature. </span>
           <input id="1">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">3</span>
           <span>She worked as a teacher before she became a journalist. </span>
           <input id="2">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">4</span>
           <span>She didn't become editor of the </span>
           <i> New York Tribune </i>
           <span>because she was a woman. </span>
           <input id="3">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">5</span>
           <span>She reviewed novels and poetry for the </span>
           <i>New York Tribune. </i>
           <input id="4">
           </div>

           <div>
           <span style="font-weight: bold; margin-right: 10px">6</span>
           <span>As a journalist, she took an interest in groups of people whose lives were difficult. </span>
           <input id="4">
           </div>
           </div>
					`,
        answer: ["T", "F", "T", "F", "T", "T"],
      },
    ],
  },
};

export default json;
