import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E1",
    // audio: "Audios/2.20.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E1answerKey.png",
    component: T6v2,
    maxLength: 16,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `	
        Label the icons with six of the holiday activities below.
													`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div>
        <span style='color:rgb(148,37,148);font-weight:bold;line-height:2;'>
        fishing&ensp;&ensp;&ensp; hiking&ensp;&ensp;&ensp; horse riding&ensp;&ensp;&ensp;   
        kayaking&ensp;&ensp;&ensp; <br> mountain biking&ensp;&ensp;&ensp; scuba-diving&ensp;&ensp;&ensp; shopping&ensp;&ensp;&ensp; 
        <br> sightseeing&ensp;&ensp;&ensp; skiing&ensp;&ensp;&ensp; swimming&ensp;&ensp;&ensp;</span>
      </div>
      <div>

        <div style='display:flex;margin-top:20px'>
          <div><img src="img/FriendsPlus/Page70/E1/1.jpg" style='' /></div>
          <div style='margin-left:150px;'><img src="img/FriendsPlus/Page70/E1/2.jpg" style='' /></div>
          <div style='margin-left:150px'><img src="img/FriendsPlus/Page70/E1/3.jpg" style='' /></div>
        </div>

        <div style='display:flex;margin-top:20px;'>
          <div style='margin-left:-30px;'><b>1</b>#</div>
          <div style='margin-left:50px;'><b>2</b>#</div>
          <div style='margin-left:50px'><b>3</b>#</div>
        </div>

        <div style='display:flex;margin-top:40px'>
          <div><img src="img/FriendsPlus/Page70/E1/4.jpg" style='' /></div>
          <div style='margin-left:150px;'><img src="img/FriendsPlus/Page70/E1/5.jpg" style='' /></div>
          <div style='margin-left:150px'><img src="img/FriendsPlus/Page70/E1/6.jpg" style='' /></div>
        </div>
        <div style='display:flex;margin-top:20px;'>
          <div style='margin-left:-30px;'><b>4</b>#</div>
          <div style='margin-left:50px;'><b>5</b>#</div>
          <div style='margin-left:50px'><b>6</b>#</div>
        </div>
      </div>
													`,
        answer: [
          `horse riding`,
          `skiing`,
          `kayaking`,
          `scuba-diving`,
          `mountain biking`,
          `fishing`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Read the <span style='color:green'>Speaking Strategy.</span> Then choose the correct reply 
        question below for each statement. There are two extra 
        questions

													`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page70/E2/1.jpg" style='width:130%'/>
      <div style='margin-left:100px'>
        <span style='color:rgb(148,37,148);font-weight:bold;line-height:2;'>
        Are you&ensp;&ensp;&ensp; Do I&ensp;&ensp;&ensp; Don’t I&ensp;&ensp;&ensp;   
        Had I&ensp;&ensp;&ensp; <br> Have you&ensp;&ensp;&ensp;  Haven’t you&ensp;&ensp;&ensp; Would I&ensp;&ensp;&ensp; 
        </span>
      </div>
      <div style='line-height:2'> 
          <div><b>1</b>&ensp; I haven’t got enough money for this holiday.</div>
          #?
          <div><b>2</b>&ensp; You need to book the train tickets soon.</div>
          #?
          <div><b>3</b>&ensp; You’d better check in that large bag.</div>
          #?
          <div><b>4</b>&ensp; You don’t seem happy about the arrangements.</div>
          #?
          <div><b>5</b>&ensp; I’m booking a hotel for the first two nights.</div>
          #?
        
      </div>
													`,
        answer: [`Haven't you`, `Do I`, `Had I`, `Don't I`, `Are you`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E3",
    audio: "Audios/2.08 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E3answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      borderBottom: "2px solid black",
      textAlign: "center",
      fontSize: "24px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
						
        <headphone name='2.08' src='Audios/2.08 tieu de.mp3'></headphone> Listen to a student and an examiner doing the 
        task after exercise 2. <br> Which point do they forget to cover? Complete the table.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:20px ">
        <div> <img style='max-width:100%' src='img/FriendsPlus/Page70/E3/1.jpg' /> </div>
        
        <div style=" position: absolute; top:33px; left: 320px; "><input id='0' width='360px' ></input></div>
        <div style=" position: absolute; top: 84px; left: 320px; "><input id='1' width='360px' ></input></div>
        <div style=" position: absolute; top: 133px; left: 320px; "><input id='2' width='360px' ></input></div>
        <div style=" position: absolute; top: 192px; left: 320px; "><input id='3' width='360px' ></input></div>
    </div>
												`,
        answer: [
          `the lakes`,
          `train`,
          `swimming`,
          `They forget to cover point 4.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E4answerKey.png",
    component: T6v2,
    maxLength: 30,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the sentences with question tags.

													`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'> 
          <div><b>1</b>&ensp; It shouldn’t be too far, #?</div>
          
          <div><b>2</b>&ensp; The train is quite fast, #?</div>
      
          <div><b>3</b>&ensp; It isn’t too expensive, #?</div>
          
          <div><b>4</b>&ensp; There are lots of other things we could do, #?</div>
          
          <div><b>5</b>&ensp; Everybody likes swimming, #?</div>
          
          <div><b>6</b>&ensp; Let’s tell the others, #?</div>
      </div>
													`,
        answer: [
          `should it`,
          `isn't it`,
          `is it`,
          `aren't there`,
          `don't they`,
          `shall we`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E5",
    audio: "Audios/2.08 audio.mp3",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page70/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 15,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        <headphone name='2.08' src='Audios/2.08 tieu de.mp3'></headphone>&ensp;Listen again. Check your answers to exercise 4.

													`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'> 
          <div><b>1</b>&ensp; It shouldn’t be too far, #?</div>
          
          <div><b>2</b>&ensp; The train is quite fast, #?</div>
      
          <div><b>3</b>&ensp; It isn’t too expensive, #?</div>
          
          <div><b>4</b>&ensp; YThere are lots of other things we could do, #?</div>
          
          <div><b>5</b>&ensp; Everybody likes swimming, #?</div>
          
          <div><b>6</b>&ensp; Let’s tell the others, #?</div>
      </div>
													`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E6answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "6",

        title: `
        Complete the sentences with question tags.

													`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2.3'> 
          <div><b>1</b>&ensp; You haven’t booked a hotel, #?</div>
          
          <div><b>2</b>&ensp; We shouldn’t go away right at the end of the holiday, #?</div>
      
          <div><b>3</b>&ensp; You’d rather stay at a campsite, #?</div>
          
          <div><b>4</b>&ensp; I’m the only one of us who can drive, #?</div>
          
          <div><b>5</b>&ensp; You never go on holiday with friends, #?</div>
          
          <div><b>6</b>&ensp; Don’t forget to pack the suncream, #?</div>
      </div>
													`,
        answer: [
          `have you`,
          `should we`,
          `wouldn't you`,
          `aren't I`,
          `do you`,
          `will you`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E7",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page70/key/E7answerKey.png",
    component: T6v2,
    // maxLength: 15,
    inputSize: 200,
    textareaStyle: { width: 800, padding: 0 },
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "7",

        title: `
        Read the task and make notes for your answers.

													`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page70/E7/1.jpg" style='width:80%'/>
      <div style='line-height:2.3'> 
          <div><b>1</b>&ensp; Where do you want to go? Why?</div>
          <textarea id='0' rows='2'></textarea>

          <div><b>2</b>&ensp; Where are you going to stay? Why? (villa / hotel / campsite / 
          youth hostel, etc.)</div>
          <textarea id='1' rows='2'></textarea>

          <div><b>3</b>&ensp; When is the best time to go? (beginning / middle / end of 
          summer)</div>
          <textarea id='2' rows='2'></textarea>

          <div><b>4</b>&ensp; What activities do you want to do on holiday?</div>
          <textarea id='3' rows='2'></textarea>
        
      </div>
													`,
        answer: [],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P70-E8",
    audio: "",
    video: "",
    recorder: true,
    // exerciseKey: "img/FriendsPlus/Page70/key/E7answerKey.png",
    component: T6v2,
    inputSize: 200,
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      // height: 70,
    },

    titleQuestion: [
      {
        num: "8",

        title: `
        Now do the speaking task. Use your notes from exercise 7.	`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
      <textarea id='0' rows='8'>`,
        answer: [],
      },
    ],
  },
};

export default json;
