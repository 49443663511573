import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P73-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page73/E5/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 130,
    fontSize: "22px",
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "700",
      textAlign: "center",
      width: "730px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Read the text again with the missing sentences. Underline
        the word or words in the sentences before or after the gap
        that link with vocabulary in the missing sentences. 
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top: 30px; width: 1000px; background-color: rgb(232,232,232)">
     <div style="padding: 40px">
     <span>
     Alice's stepson had died, but his son was able to tell them that Alice
     had a daughter in the USA. That was how they found out about
     Elizabeth.
     </span></br>
     <span>
     Alice Lamb had originally intended for both of her daughters to be
     adopted, but in the end, she had to keep Elizabeth because of her poor
     health. At first, Elizabeth stayed with an aunt and then with a woman
     who did not care for her properly, prompting Alice to take Elizabeth to
     live with her in the house where she worked. Elizabeth remembers her
     mother telling her when she was fifteen that she was a twin, but at
     the time she thought it would be impossible to find her sister. 
     </span>
     <sup>3</sup>
     <input id="0">
     <span>
     So she was more than delighted to receive Ann's letter in 2014, and
     minutes after reading it, the two were talking on the phone.
     </span></br>
     <span>
     The reunion was arranged in Los Angeles at the invitation of psychologist
     Nancy Segal, who has been studying twins separated at birth for nearly
     forty years. During her studies, Ms Segal has discovered that twins
     growing up in completely different families can share many similar
     attitudes. 
     </span>
     <sup>4</sup>
     <input id="1">
     <span>
     At first glance, the sisters appeared to have quite a lot
     in common: both had married men called Jim, who had recently died,
     so both women became widows. Both of them were grandmothers and
     evidence from photos shows that they both like to pose in front of the
     camera.
     </span></br>
     <span>
     However, there were also big differences in their lives. After leaving
     her life in the town where she was born. Elizabeth left school to work in
     school, Ann worked for a printer until she got married, and remained all
     a sweet shop, but after going to night school, she joined the navy and
     moved to Malta. 
     </span>
     <sup>5</sup>
     <input id="2">
     <span>
     Ms Segal is going to take a DNA sample from
     each twin and study all their similarities and differences to establish
     whether Ann and Elizabeth are identical or non-identical twins. As for the
     sisters themselves, the most remarkable thing for them is that after such
     a short time, they feel like they have known each other all their lives.
     </span>
     </div>
		</div>
				`,
        answer: [
          "she thought it would be impossible = without success",
          "at the invitation of = She asked … to take part in her research",
          "Malta = There",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P73-E6",
    exerciseKey: "img/FriendsPlus/Page73/E6/key/E6answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "6",
        title: `
        Choose the correct answers.
				`,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
          fontSize: 22,
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c`, //0
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //4

          `a b c`, //5
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //9
        ],
        answers: [
          "0-0",
          "1-2",
          "2-4",
          "3-4",
          "4-0",
          "5-2",
          "6-4",
          "7-0",
          "8-2",
          "9-2",
        ],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative; font-size: 25px; margin-right: 50px;'>
      <img style="width:17cm; height:18cm" src='img/FriendsPlus/Page73/E6/1.jpg' />
					<div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>1&ensp;</b><input id='0' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>2&ensp;</b><input id='1' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>3&ensp;</b><input id='2' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>4&ensp;</b><input id='3' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>5&ensp;</b><input id='4' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm; margin-right: 110px;'>
          <b>6&ensp;</b><input id='5' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>7&ensp;</b><input id='6' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>8&ensp;</b><input id='7' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>9&ensp;</b><input id='8' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>10</b><input id='9' type='Circle'>
					</div>
						
					
						
<div style='position:absolute;top:681px;left:202px; font-size: 25px'>
            an<br>
						doesn’t<br>
            A<br>
            do<br>
            is born<br>
            be used to<br>
            Getting away<br>
            live up to<br>
            bring up<br>
            are<br>
					</div>
					<div style='position:absolute;top:681px;left:399px; font-size: 25px'>
            the<br>
            don’t<br>
            An<br>
            make<br>
            born<br>
            get used to<br>
            Getting on<br>
            get up to<br>
            grow up<br>
            are going to<br>
				</div>
					<div style='position:absolute;top:681px; left: 618px; font-size: 25px'>
            a<br>
            isn’t<br>
            The<br>
            start<br>
            are born<br>
            used to<br>
            Putting up<br>
            look up to<br>
            make up<br>
            will<br>

					</div>
					
			</div>
         `,
    },
  },
  7: {
    unit: "Cumulative Review 1",
    id: "FGF11-WB-demo-2023-CR-P73-E7",
    audio: "",
    video: "",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page73/E7/key/E7answerKey.png",
    component: T6v2,
    // maxLength: 1,
    fontSize: "22px",
    inputSize: 130,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "13 00",
      textAlign: "center",
      width: "730px",
    },
    textareaStyle: {
      // background: 'none',
      width: 1000,
      fontSize: 24,
      height: 120,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "7",
        title: `
        You have received a letter from a cousin you haven’t seen
        since you were a young child. In the letter, your cousin says
        he / she wants to visit you. Write a reply in which you:
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		    <div style="margin-top: 0px; width: 1000px">
         <ul style:"list-style-type: disc">
           <li>apologise for not writing back sooner.</li>
           <li>say how happy you are about the visit and remind your cousin about the last time you met.</li>
           <li>explain what you are doing now.</li>
           <li>request a recent photo of your cousin.</li>
         </ul>
         <textarea id='0' row='3'>
		    </div>
				`,
        answer: [""],
      },
    ],
  },
};

export default json;
