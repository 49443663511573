import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E1",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "1",
        title: `Read the sentences. What is each speaker’s attitude? 
        Circle the correct answers. 
				`,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c d`, //0
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`, //5
        ],
        answers: ["0-6", "1-4", "2-4", "3-2", "4-6", "5-0"],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative'>
						<b>1</b>&ensp; ‘Quick! Shut the door, before it’s too late!’
					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='0' type='Circle'>
					</div>
						<b>2</b>&ensp; ‘This town was wonderful when I was a boy.’
					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='1' type='Circle'>
					</div>
						<b>3</b>&ensp;‘Poor you. I hope you feel better soon.’

					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='2' type='Circle'>
					</div>
						<b>4</b>&ensp;‘Don’t worry. Everything will be fine, I’m sure.’
					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='3' type='Circle'>
					</div>
						<b>5</b>&ensp; ‘I expect I’ll come last in the race. I usually do.’
					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='4' type='Circle'>
					</div>
						<b>6</b>&ensp;‘I can’t forgive him for how he behaved.’
					<div style='display:flex; justify-content:space-around;height:10mm;width: 20cm'>
						<input id='5' type='Circle'>
					</div>
						
					
						
<div style='position:absolute;top:40px;left:85px'>
						aggressive <br><br>
						accusing <br><br>
						bitter <br><br>
						accusing <br><br>
						arrogant <br><br>
						bitter <br><br>
						
					</div>
					<div style='position:absolute;top:40px;left:300px'>
					calm <br><br>
					miserable <br><br>
					grateful <br><br>
					calm<br><br>
					enthusiastic<br><br>
					complimentary<br><br>
					
				</div>
					<div style='position:absolute;top:40px;left:515px'>
          sarcastic<br><br>
          nostalgic<br><br>
          sympathetic<br><br>
          nostalgic<br><br>
          grateful<br><br>
          optimistic<br><br>
					
					</div>
					<div style='position:absolute;top:40px;left:720px;'>
					urgent<br><br>
					optimistic <br><br>
					urgent<br><br>
					pessimistic<br><br>
          pessimistic<br><br>
          sarcastic<br><br>
					
					</div>
			</div>

			


        
         `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E2",
    audio: "Audios/1.03 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",
    component: Circle_Write,
    totalInput: 4,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='1.03' src='Audios/1.03 tieu de.mp3'></headphone> Read the <span style='color: rgb(217,106,137)'>Listening Strategy</span>. Then listen and circle 
				the tone of voice the speaker uses. 
			  `,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
        ],
        answers: ["0-0", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
    
	        <img src='img/FriendsPlus/Page10/E2/1.jpg' style='margin-left:150px'>

      <div style='display:flex; line-height:2'>
      <div style ='flex:1;width:100%'>
            <b>1</b>&ensp;
            The next train leaves in half an hour.<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              calm <br>
              urgent
                    </div>
                       </div>

            <b>2</b>&ensp;
            That’s made me feel a lot better.
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
              grateful<br>
              sarcastic
		          </div>
            </div>
           </div>


           <div style='margin-left:100px'>
              <b>3</b>&ensp;
           This is going to be rather painful.
         <div style="display: flex;margin-left:40px">
           <div><input id='2' type='Circle'/></div>
           <div style='margin-left:20px'>
           aggressive<br>
           sympathetic
           </div>
         </div>
                     <b>4</b>&ensp;
           We were too poor to even go on holiday.
          <div style="display: flex;">
         <div style="display: flex;margin-left:40px">
           <div><input id='3' type='Circle'/></div>
           <div style='margin-left:20px'>
           bitter <br>
           nostalgic

           </div>
         </div>
         </div>
      </div>

                        </div>


        
      `,
    },
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 270,
    isHiddenCheck: true,
    // checkUppercase: true,
    hideBtnFooter: true,
    maxLength: 12,
    textAlign: "center",
    // exerciseKey: 'img/FriendsPlus/Page6/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: "3",
        title: `
				Try reading aloud each sentence from exercise 2 using the other tone of voice.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="">
				<img style="width:15cm " src='img/FriendsPlus/Page10/E3/1.jpg' />
        </div>
				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E4",
    audio: "Audios/1.04 audio.mp3",
    video: "",
    component: T6,
    inputSize: 400,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 13,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page10/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone name='1.04' src='Audios/1.04 tieu de.mp3'></headphone> Listen. Which adjective below best describes each 
				speaker’s tone of voice? There are three extra adjectives.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "arrogant",
          "enthusiastic",
          "grateful",
          "nostalgic",
          "pessimistic",
          "sympathetic",
          "urgent",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; margin-left: 50px; line-height: 50px">
          <div style='margin-right: 20px'>
            <div><b>Speaker 1 </b>&ensp;</div>
            <div><b>Speaker 2 </b>&ensp;</div>
            <div><b>Speaker 3 </b>&ensp;</div>
            <div><b>Speaker 4 </b>&ensp;</div>
          </div>
        
        <div>
          <span>#</span><br>
          <span>#</span><br>
          <span>#</span><br>
          <span>#</span>
          
        </div>

        </div>
				`,
        answer: [`sympathetic`, `enthusiastic`, `arrogant`, `urgent`],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E5",
    exerciseKey: "img/FriendsPlus/Page10/Key/E5answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "5",
        title: `Match the intentions (1–5) with the tone of voice you are 
        most likely to use. 
				`,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c`, //0
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //4
        ],
        answers: ["0-0", "1-2", "2-4", "3-2", "4-2"],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative'>
						<b>1</b>&ensp; persuading somebody:________
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='0' type='Circle'>
					</div>
						<b>2</b>&ensp; remembering something:________
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='1' type='Circle'>
					</div>
						<b>3</b>&ensp;thanking somebody:________
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='2' type='Circle'>
					</div>
						<b>4</b>&ensp;praising somebody: ________ 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='3' type='Circle'>
					</div>
						<b>5</b>&ensp; complaining about something:________
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='4' type='Circle'>
					</div>
					
						
<div style='position:absolute;top:40px;left:95px'>
						enthusiastic <br><br>
						arrogant <br><br>
						accusing <br><br>
						calm <br><br>
						enthusiastic <br><br>
					
					</div>
					<div style='position:absolute;top:40px;left:355px'>
					grateful <br><br>
					nostalgic <br><br>
					bitter <br><br>
					complimentary<br><br>
					miserable<br><br>
				
				</div>
					<div style='position:absolute;top:40px;left:600px'>
          sarcastic<br><br>
          sympathetic<br><br>
          grateful<br><br>
          optimistic<br><br>
          optimistic<br><br>
						
					</div>
					
			</div>

			


        
         `,
    },
  },
  6: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E6",
    audio: "Audios/1.05 audio.mp3",
    video: "",
    component: T6,
    inputSize: 400,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 13,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page10/Key/E6answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `
				<headphone name='1.05' src='Audios/1.05 tieu de.mp3'></headphone> Listen to four monologues. Decide what tone of 
				voice each speaker is using. Choose from the adjectives in exercise 5.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				<img style="width:15cm" src='img/FriendsPlus/Page10/E6/1.jpg' />
        <div style="display: flex; margin-left: 40px; font-size: 22px;line-height: 40px">
          <div style='margin-right: 20px'>
            <div><b>Speaker 1 </b>&ensp;</div>
            <div><b>Speaker 2 </b>&ensp;</div>
            <div><b>Speaker 3 </b>&ensp;</div>
            <div><b>Speaker 4 </b>&ensp;</div>
          </div>
        
        <div>
          <span>#</span><br>
          <span>#</span><br>
          <span>#</span><br>
          <span>#</span>
          
        </div>

        </div>
				`,
        answer: [`complimentary`, `enthusiastic`, `miserable`, `nostalgic`],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "FGF11-I-P10-E7",
    audio: "Audios/1.05 audio.mp3",
    video: "",
    component: T6,
    inputSize: 60,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page10/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `
				<span style='font-size: 24px'><headphone name='1.05' src='Audios/1.05 tieu de.mp3'></headphone> Listen again. Match speakers 1–4 with sentences 
				A–E. There is one extra sentence. Use your answers to 
				exercises 5 and 6 to help you.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 27,
      textTransform: "uppercase",
    },

    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:10px; margin-left: 100px">
          <div><img style="width: 18cm" src='img/FriendsPlus/Page10/E7/1.jpg';/></div>

          <div style=" position: absolute; top: 82px; left: 254px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 82px; left: 359px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 82px; left: 463px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 82px; left: 568px"><input id='3' ></input></div>
          
        </div>
       
        <img src='img/FriendsPlus/Page10/E7/2.png' style='width: 22cm; height: 11cm'/>
        <img src='img/FriendsPlus/Page10/E7/3.png' style='width: 24cm; height: 14cm'/>
        
    

				`,
        answer: [`D`, `A`, `E`, `B`],
      },
    ],
  },
};

export default json;
