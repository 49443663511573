import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Introduction",
    id: "FGF11-I-P5-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page5/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct tense.
				`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          color: "black",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `do_you_do / are_you_doing`, //0
          `I_stay / I'm_staying`, // 1
          `get / ’re_getting`, // 2
          `rises / is_rising`, // 3
          `sets / is_setting`, // 4
          `do_you_laugh / are_you_laughing`, // 5
          `leaves / is_leaving`, // 6
          `always_borrows / is_always_borrowing`, // 7
          `does_your_train_arrive / is_your_train_arriving`, //8
        ],
        answers: [
          "0-4",
          "1-4",
          "2-0",
          "3-0",
          "4-0",
          "5-4",
          "6-0",
          "7-4",
          "8-0",
        ],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>‘What <input id='0' type='Circle' /> tonight?’ ‘ <input id='1' type='Circle' /> at home.’</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b>Can you text me when you <input id='2' type='Circle' /> home?</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b>The sun <input id='3' type='Circle' /> in the east and <input id='4' type='Circle' /> in the west.</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b>Why <input id='5' type='Circle' /> ? It isn’t funny!</div>
            <div style='margin-bottom:20px'><b>5&ensp;</b>My dad <input id='6' type='Circle' /> home every day at eight o’clock.</div>
            <div style='margin-bottom:20px'><b>6&ensp;</b>My sister <input id='7' type='Circle' /> my clothes. It’s really annoying!</div>
            <div style='margin-bottom:20px'><b>7&ensp;</b>What time <input id='8' type='Circle' />?</div>

					</div>
          
        `,
    },
  },
  2: {
    unit: "Introduction",
    id: "FGF11-I-P5-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page5/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Match sentences 1–7 in exercise 1 with the uses of tenses a–g below.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 26,
      // textTransform: 'uppercase',
      width: 40,
    },

    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:10px; ">
          <div><img style="width: 20cm" src='img/FriendsPlus/Page5/E2/1.png';/></div>
          <div><img style="width: 25cm" src='img/FriendsPlus/Page5/E2/2.jpg';/></div>

          <div style=" position: absolute; top: 374px; left: 224px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 400px; left: 306px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 426px; left: 273px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 479px; left: 133px"><input id='3' ></input></div>
          
          <div style=" position: absolute; top: 377px; left: 870px"><input id='4' ></input></div>
          <div style=" position: absolute; top: 403px; left: 897px"><input id='5' ></input></div>
          <div style=" position: absolute; top: 429px; left: 703px"><input id='6' ></input></div>

        </div>

				`,
        answer: [`5`, `3`, `7`, `2`, `4`, `6`, `1`],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "FGF11-I-P5-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 15,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page5/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style='font-size: 24px'>Complete the dialogue with the present simple or present continuous form of the verbs in brackets.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div><img style="width: 13cm" src='img/FriendsPlus/Page5/E3/1.jpg';/></div>
        <div style="display: flex; margin-top: 30px; font-size: 22px">
          <div style='margin-right: 40px'>
            <b>Martin</b><br>
            <b>Hannah</b><br><br>
            <b>Martin</b><br>
            <b>Hannah</b><br>
            <b>Martin</b><br>
            <b>Hannah</b><br>
            <b>Martin</b><br><br>
            <b>Hannah</b><br>
          </div>
          <div>
           <div>Hurry up! The film <sup>1 </sup># (start) in ten 
           minutes.</div>
           <div>I <sup>2 </sup># (look for) my phone.<sup>3 </sup># you #<br>(know) 
           where it is? </div>
           <div>No. Why <sup>4 </sup># you always # (lose) things? It’s so annoying!</div>
           <div>I usually <sup>5 </sup># (keep) it on my bedside table, but it isn’t there.</div>
           <div><sup>6 </sup># you # (remember) when you last used it?</div>
           <div>No – that’s the problem.</div>
           <div>You <sup>7 </sup># (not need) your phone at the cinema.<br> Look for it when we <sup>8 </sup># (get back).</div>
           <div>I won’t have time when we get back.<br>
           I <sup>9 </sup># (go) straight out again … It’s OK.
           Here it is! It was in my pocket!</div>
          </div>

        </div>

				`,
        answer: [
          `starts`,
          `'m looking for`,
          `Do`,
          `know`,
          `are`,
          `losing`,
          `keep`,
          `Do`,
          `remember`,
          `don't need`,
          `get back`,
          `'m going`,
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Introduction",
    id: "FGF11-I-P5-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page5/Key/E4answerKey.png",
    inputSize: 700,
    isHiddenCheck: true,
    checkUppercase: true,
    component: T6,
    textAlign: "left",
    // totalInput: 2,
    titleQuestion: [
      {
        num: "4",
        title:
          '<span style="font-size: 23px">Some of the sentences are incorrect. Rewrite them correctly. Tick the correct sentences.</span>',
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 21,
      height: 26,
      // textTransform: 'uppercase',
    },

    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    // selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='font-size:21px'>
          <div style='display: flex'><b>1&ensp;</b>Mel is belonging to the drama club. <select id=6></select></div>
          <div><input id='0'></div>
          <div style='display: flex'><b>2&ensp;</b>Do you prefer beach holidays or adventure holidays? <select id=7></select></div>
          <div><input id='1'></div>
          <div style='display: flex'><b>3&ensp;</b>Call me as soon as you’re arriving. <select id=8></select></div>
          <div><input id='2'></div>
          <div style='display: flex'><b>4&ensp;</b>Jack is hating spaghetti. <select id=9></select></div>
          <div><input id='3'></div>
          <div style='display: flex'><b>5&ensp;</b>Why are you always interrupting? <select id=10></select></div>
          <div><input id='4'></div>
          <div style='display: flex'><b>6&ensp;</b>I’m not understanding this maths calculation. <select id=11></select></div>
          <div><input id='5'></div>
        </div> 
        `,
        answer: [
          "Mel belongs to the drama club.",
          "-",
          `Call me as soon as you arrive.`,
          `Jack hates spaghetti.`,
          "-",
          `I don't understand this maths calculation.`,
          "",
          "✓",
          "",
          "",
          "✓",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "FGF11-I-P5-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 27,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page5/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `<span style='font-size: 24px'>Complete the sentences with the verbs below. Use the same 
        verb in each pair of sentences. Use the present simple and present continuous.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["have", "look", "think"],
        borderColor: "none",
        width: 300,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px">
          <div style="margin-right: 30px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br><br>
          </div>
          <div>
            <div><b>a&ensp;</b>That food <input id='0' width='200px'> delicious!</div>
            <div><b>b&ensp;</b>What <input id='0' width='150px'> you <input id='0' width='150px'> at?</div>
            <div><b>a&ensp;</b>What <input id='0' width='150px'> you <input id='0' width='150px'> about?</div>
            <div><b>b&ensp;</b>Who <input id='0' width='150px'> you <input id='0' width='150px'> will win the match?</div>
            <div><b>a&ensp;</b>‘Where’s Tom?’ ‘He <input id='0' width='200px'> a shower.’</div>
            <div><b>b&ensp;</b>Dogs <input id='0' width='200px'> a good sense of smell. </div>
          
            </div>
        </div>

				`,
        answer: [
          `looks`,
          `are`,
          `looking`,
          `are`,
          `thinking`,
          `do`,
          `think`,
          `'s having / is having`,
          `have`,
        ],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "FGF11-I-P5-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 80,
    textareaStyle: { width: 850 },
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page5/Key/E6answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `<span style='font-size: 24px'>Complete the questions with the verbs in brackets. Use 
        the present simple or present continuous. Then write true 
        answers, in complete sentences.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="margin-top: 30px">
          <div><b>1&ensp;</b>Where <input id='0' width='150px'> you usually <input id='1' width='150px'> on Saturday mornings? (go)</div>
          <div><input id='2' width='900px'></div>
          <div><b>2&ensp;</b>What <input id='3' width='150px'> you usually <input id='4' width='150px'> on Saturday mornings? (do)</div>
          <div><input id='5' width='900px'></div>
          <div><b>3&ensp;</b>What time <input id='6' width='150px'> school <input id='7' width='150px'> and <input id='8' width='150px'>? (start / finish)</div>
          <div><input id='9' width='900px'></div>
          <div><b>4&ensp;</b>What <input id='10' width='150px'> your parents <input id='11' width='150px'> now? (do)</div>
          <div><input id='12' width='900px'></div>
        </div>

				`,
        answer: [
          `do`,
          `do`,
          ``,
          `do`,
          `do`,
          ``,
          `does`,
          `start`,
          `finish`,
          ``,
          `are`,
          `doing`,
          "",
        ],
      },
    ],
  },
};

export default json;
