import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/key/E1answerKey.png",
    component: T6v2,
    maxLength: 40,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the sentences with the passive form of the verbs in brackets. Use the correct tense.
												`,
        color: "black",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='line-height:2.5'>
        <div style='display:flex'><b>1.</b>&ensp; <span style='margin-left:10px'><i>Romeo and Juliet</i> # probably # between 1591 and 1595. (write)</span> </div>

        <div><b>2.</b>&ensp; ‘ # these glasses # ?’ ‘No, they haven’t.’ (wash) </div>

        <div><b>3.</b>&ensp; He told me that the show # two days before. (cancel)</div>

        <div><b>4.</b>&ensp; I’m sharing my sister’s bedroom because mine # at the moment. (decorate) </div>

        <div><b>5.</b>&ensp; I walked into the office while a job applicant # . (interview) </div>

        <div><b>6.</b>&ensp;The exam results # by post next week. (send) </div>
      
      
      </div>
			
						
												`,
        answer: [
          `was`,
          `written`,
          `Have`,
          `been washed`,
          `had been cancelled`,
          `is being decorated`,
          `was being interviewed`,
          `will be sent`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E2",
    audio: "",
    // hideBtnFooter:true,
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/key/E2answerKey.png",
    component: T6v2,
    maxLength: 25,
    inputSize: 230,
    textareaStyle: { width: "470px" },
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: 400,
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete the text with the passive form of the verbs in brackets. Use the correct tense.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <div>
        <img src="img/FriendsPlus/Page57/E2/1.jpg" style='height:9cm;' />
        </div>
        <div style='margin-left:10px'>
        <div style='background: gainsboro;border-radius:20px'>
        <div style='width:750px;line-height:2; margin:auto;padding:20px; font-size: 22px'>
        Until 2004, composer Mamoru Samuragochi <br/>
        <sup><b>1</b></sup> # (know) as ‘Japan’s Beethoven’. <br/> He 
        is most famous for his Hiroshima Symphony No. 1, which  <br/>
        <sup><b>2</b></sup> # (compose) in 2003 in memory of 
        the people who <sup><b>3</b></sup> # (kill) by the atomic 
        bomb in 1945. It <br/> <sup><b>4</b></sup> # (perform) for the 
        first time in 2008 in front of many of the world’s most 
        important politicians. But Samuragochi now admits that 
        this piece and many others <br/> <sup><b>5</b></sup> # in fact 
         # (not write) by him, but by another 
        musician. The real composer of the musician’s works 
        <sup><b>6</b></sup> # yet # (not 
        name), but a man called Takahashi Niigaki recently claimed 
        to have written them. Although a talented composer, it is 
        sad that in the future Samuragochi <sup><b>7</b></sup> #
        (remember) as a fraud.
        </div>
      </div>
        </div>
        </div>
        
       
												`,
        answer: [
          `was known`,
          `was composed`,
          `were killed`,
          `was performed`,
          `were not`,
          `written`,
          `hasn't`,
          `been named`,
          `will be remembered`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 890,
    textareaStyle: { width: 890, padding: 0 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "23px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Write passive sentences with by.
 
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
          <div><b>1 </b>&ensp;Leonardo da Vinci painted the <i>Mona Lisa</i>.
          <textarea id='0' rows='1'>
          </div>
         
          <div><b>2 </b>&ensp;A group of young men will perform the dance.
          <textarea id='1' rows='1'>
          </div>
          
          <div><b>3 </b>&ensp;The Queen has opened a new art gallery.
          <textarea id='2' rows='1'>
          </div>
         
          <div><b>4 </b>&ensp;J.J. Abrams directed <i>Star Wars: The Force Awakens</i>.
          <textarea id='3' rows='1'>
          </div>
          
          <div><b>5 </b>&ensp;In <i>The Theory of Everything</i>, Eddie Redmayne plays the role of Stephen Hawking.
          <textarea id='4' rows='2'></div>
          
        
        
        </div>
												`,
        answer: [
          `The Mona Lisa was painted by Leonardo da Vinci.`,
          `The dance will be performed by a group of young men.`,
          `A new art gallery has been opened by the Queen.`,
          `Star Wars: The Force Awakens was directed by J.J. Abrams.`,
          `The role of Stephen Hawking is played by Eddie Redmayne in The Theory of Everything.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E4",
    // audio: 'Audios/1-22 Friends Plus 8_WB.mp3',
    video: "",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page57/key/E4answerKey.png",
    stylesTextInput: {
      fontSize: 23,
    },
    inputSize: 550,
    // maxLength: 3,
    // textAlign:'center',
    checkUppercase: true,
    isHiddenCheck: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Make the active sentences passive. Use modal verbs. <u>Don’t</u> use by + agent.",

        color: "black",
      },
    ],
    questions: [
      {
        title: `
			<div style='line-height:2'>
        <div>
        <b>1</b>&ensp;You shouldn’t eat those mushrooms.<br>
        <span style='color:rgb(43,90,103);margin-left:22px'><i>Those mushrooms shouldn’t be eaten.</i></span>
        </div>
      
        <div><b>2</b>&ensp; They might have cancelled the concert.</div>
        #

        <div><b>3</b>&ensp; You can often see foxes in my garden.</div>
        #

        <div><b>4</b>&ensp; We mustn’t use mobiles during lessons.</div>
        # 

        <div><b>5</b>&ensp; You have to write your name in capital letters.</div>
        #

        <div><b>6</b>&ensp; You can’t trust Toby!</div>
        #
      
      </div>
						
												`,
        answer: [
          `The concert might have been cancelled.`,
          `Foxes can often be seen in my garden.`,
          `Mobiles mustn't be used during lessons.`,
          `Your name has to be written in capital letters.`,
          `Toby can't be trusted!`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page57/Key/E5answerKey.png",
    inputSize: 740,
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 28,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Some of the sentences are incorrect. Rewrite them correctly. Tick the correct sentences. `,
        color: "black",
      },
    ],
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 35,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "X"],
    questionImage: [],
    questions: [
      {
        title: `
     <div style='position: relative; line-height:40px; font-size: 22px'>
     <div><i style='font-size:20px; color: rgb(245,54,92)'>*If the sentence is true, rewrite the full sentence.</i></div>
       <div style='display:flex'>
        <b>1</b>&ensp;I think we are been followed.<select id=6 margin-top='10px'></select>
       </div>
        #
       <div style='display:flex'>
        <b>2</b>&ensp;Peugeot cars make in France. <select id=7 margin-top='10px'></select>
       </div>
        #
       <div style='display:flex'>
        <b>3</b>&ensp;<i>Set Fire to the Rain</i> was written and performed by Adele.<select id=8 margin-top='10px'></select>
       </div>
         #
        <div style='display:flex'>
         <b>4</b>&ensp;The book must be returning to the library. <select id=9 margin-top='10px'></select>
       </div>
        #
       <div style='display:flex'>
        <b>5</b>&ensp;Was German teaching in your school?<select id=10 margin-top='10px'></select>
       </div>
        #
        <div style='display:flex'>
        <b>6</b>&ensp;Oh no! My mobile has been stolen!<select id=11 margin-top='10px'></select>
       </div>
        #
      
     </div>
     
     `,
        answer: [
          `I think we are being followed.`,
          `Peugeot cars are made in France.`,
          `Set Fire to the Rain was written and performed by Adele.`,
          `The book must be returned to the library.`,
          `Was German taught in your school?`,
          `Oh no! My mobile has been stolen!`,

          `X`,
          `X`,
          `✓`,
          `X`,
          `X`,
          `✓`,
        ],
      },
    ],
  },

  6: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P57-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Imagine you have bought a new piece of equipment for a hike in the mountains. Write the review.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; What is it? What does it do? Why is it special?</div>
        <div><b>2</b>&ensp; What do you like about it?</div>
        <div><b>3</b>&ensp; What is the biggest disadvantage of this product?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page57/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
