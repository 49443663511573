import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E1",
    audio: "Audios/1.26 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='1.26' src='Audios/1.26 tieu de.mp3'></headphone>&ensp;Listen to a teenage boy enquiring about a job. Complete the information.
										`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
          <div>Job: A or B? #</div>
          <div>
          Responsibilities:<br>
          <sup><b>1</b></sup> # <br>
          <sup><b>2</b></sup> #
          </div>
          <div>Salary: <b>£</b> # </div>
          <div>Hours: #</div>
          <div>Accommodation included? #</div>
        
        
        </div>
									`,
        answer: [
          `A (fruit picking)`,
          `pick apples`,
          `put them in boxes`,
          `8 p/h`,
          `7 a.m.- 4 p.m.`,
          `No`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the <span style='color:#1E9853'>Speaking Strategy</span> with the words below.
										`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: [`ask`, "question", "reminds", "something", `speaking`, `thing`],
        borderColor: "none",
        width: 750,
        fontColor: "rgb(148,36,144)",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <b><hintBox id='0'></hintBox></b>
        <div style='background: rgb(229,239,230); padding:10px 20px; margin-top:10px; border-radius:20px; border:5px dotted rgb(0,146,58) '>
        <div style='width:18cm;line-height:2;'>
        <div style='color:rgb(0,146,58);font-size:35px'><b>Speaking Strategy</b></div>
        Make sure that you refer to all four points in the task. 
        You may need to move the conversation on in order 
        to cover all four topics. Use phrases like: <br>
        <i>Moving on to the <sup><b>1</b></sup> # of …<br> 
        Another <sup><b>2</b></sup> # I wanted to ask / know is …<br>
       <sup><b>3</b></sup> # else I’d like to talk about is …<br> 
        Could I possibly <sup><b>4</b></sup> # you about … ? <br> 
        <sup><b>5</b></sup> # of X (if X has been mentioned)<br>
        That <sup><b>6</b></sup> # me, … </i>(if there is a link with 
        something you want to say or ask)
        </div>
      </div>
				`,
        answer: [
          `question`,
          `thing`,
          `Something`,
          `ask`,
          `Speaking`,
          `reminds`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E3",
    audio: "Audios/1.26 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E3answerKey.png",
    component: T6,
    maxLength: 1,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='1.26' src='Audios/1.26 tieu de.mp3'></headphone>&ensp;Listen again. Tick the phrases in the <span style='color:#1E9853'>Speaking Strategy</span> that the interviewee uses.
										`,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      border: "2px solid gray",
      marginTop: -4,
      marginLeft: 10,
      height: 36,
      width: 40,
      // textAlign: "left",
      borderRadius: "5px",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: [`✓`, `-`],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px'>
        <div style='background: rgb(229,239,230); padding:10px 20px; margin-top:10px; border-radius:20px; border:5px dotted rgb(0,146,58) '>
        <div style='width: 20cm;line-height:2;'>
        <div style='color:rgb(0,146,58);font-size:35px'><b>Speaking Strategy</b></div>
        Make sure that you refer to all four points in the task.<br> 
        You may need to move the conversation on in order to cover all four topics. Use phrases like: <br>
        <i>
        <div style='display: flex'>Moving on to the question of … <select id=0></select></div>
        <div style='display: flex'>Another thing I wanted to ask / know is … <select id=1></select></div>
        <div style='display: flex'>Something else I’d like to talk about is … <select id=2></select></div>
        <div style='display: flex'>Could I possibly ask you about … ? <select id=3></select> </div>
        <div style='display: flex'>Speaking of X (if X has been mentioned) <select id=4></select></div>
        <div style='display: flex; flex-direction: row'>That reminds me, … </i></div>
        <div style='display: flex; flex-direction: row'>(if there is a link with 
        something you want to say or ask)<select id=5></select></div>
        </div>
      </div>
           </div>
					`,
        answer: [`✓`, `✓`, `-`, `✓`, `✓`, `-`],
      },
    ],
  },
  // 3: {
  //   unit: "Unit 6",
  //   id: "FGF11-WB-demo-2023-U6-P54-E3",
  //   audio: "Audios/1.26 audio.mp3",
  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "img/FriendsPlus/Page54/key/E3answerKey.png",
  //   component: Circle_Write,
  //   // maxLength: 1,
  //   inputSize: 150,
  //   textareaStyle: { width: "800px" },
  //   stylesTextInput: {
  //     // background: 'transparent',
  //     // border: 'none',
  //     textAlign: "center",
  //     fontSize: "20px",
  //     fontWeight: "bold",
  //   },
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `
  //       <headphone name='1.26' src='Audios/1.26 tieu de.mp3'></headphone>&ensp;Listen again. Tick the phrases in the <span style='color:#1E9853'>Speaking Strategy</span> that the interviewee uses.
  // 									`,
  //       color: "black",
  //     },
  //   ],
  //   questionImage: [],
  //   // hideBtnFooter: true,
  //   isHiddenCheck: true,
  //   question: {
  //     Write: {
  //       isHiddenCheck: true,
  //       maxLength: 11,
  //       inputStyle: {
  //         width: 149,
  //         fontSize: 23,
  //         borderBottom: "none",
  //         textAlign: "center",
  //       },
  //       answers: [""],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       initialWordStyle: {},
  //       selectWordStyle: {
  //         // padding: 5,
  //         color: "black",
  //       },
  //       limitSelect: 6,
  //       listWords: [`✓  ✓ ✓ ✓ ✓ ✓`],
  //       answers: ["0-4", "0-0", "0-8", "0-10"],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <div style='position: relative;background: rgb(229,239,230); width:100%;margin-left: 138px;margin-top:20px; border-radius:20px; border:5px dotted rgb(0,146,58) '>
  //     <div style='width:16cm;line-height:50px; margin:auto;padding:20px'>
  //     <div style='color:rgb(0,146,58);font-size:35px'><b>Speaking Strategy</b></div>
  //     Make sure that you refer to all four points in the task.
  //     You may need to move the conversation on in order
  //     to cover all four topics. Use phrases like: <br>
  //     Moving on to the <sup><b>1</b></sup>______ of …
  //     <br>Another <sup><b>2</b></sup> ______ I wanted to ask / know is …
  //    <br> <sup><b>3</b></sup> ______ else I’d like to talk about is …
  //     <br>Could I possibly <sup><b>4</b></sup> ______ you about … ? <br> <sup><b>5</b></sup> ______ of X (if X has been mentioned)
  //     <br>That <sup><b>6</b></sup> ______ me, … (if there is a link with
  //     something you want to say or ask)
  //     </div>

  //     <div style='position:absolute;top:220px;left:591px; display:flex; flex-direction: column;line-height:50px  '>
  //             <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
  //             <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
  //             <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
  //             <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
  //             <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
  //             <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>

  //         </div>
  //       <div style='position:absolute;top:215px;left:603px;color: rgb(229,239,230); display:flex; flex-direction: column;line-height:50px  '>
  //         <input id='0' type='Circle'>

  //       </div>

  //     </div>
  //   </div>
  //     `,
  //   },
  // },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E4",
    // audio: "Audios/2.07.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E4answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 850,
    textareaStyle: { width: "600px" },
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Match 1–5 with a–e to make the start of indirect questions.

										`,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `  
        <div style='display:flex; line-height:2.5 '>
        <div>
          <div style='display:flex'><b>1.</b>&ensp; Could you&ensp;&ensp; # </div>
          <div style='display:flex'><b>2.</b>&ensp; I’d be&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; # </div>
          <div style='display:flex'><b>3.</b>&ensp; I’d like&ensp;&ensp;&ensp;&ensp;&ensp; # </div>
          <div style='display:flex'><b>4.</b>&ensp; May&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; # </div>
          <div style='display:flex'><b>5.</b>&ensp; I was&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; # </div>
        </div>
        <div style='margin-left:200px'>
        <div><b>a.</b> I ask … </div>
        <div><b>b.</b> tell me … </div>
        <div><b>c.</b> interested to know …</div>
        <div><b>d.</b> wondering …</div>
        <div><b>e.</b> to know …</div>
        
        </div>
        
        </div>

										`,
        answer: [`b`, `c`, `e`, `a`, `d`],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E5",
    audio: "Audios/1.27 audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E5answerKey.png",
    component: T6v2,
    maxLength: 60,
    inputSize: 350,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // borderStyle:'dotted',
      // fontWeight: "bold",
    },

    titleQuestion: [
      {
        num: "5",
        title: `
        <span>
        <headphone name='1.27' src='Audios/1.27 tieu de.mp3'></headphone>&ensp;Listen to a teenage girl enquiring about a job. Complete the information.
        </span>
										`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height: 60px; font-size: 23px'>
          <div>Job:<sup><b>1</b></sup> #</div>
    
          <div>Responsibilities:<br>
          <sup><b>2</b></sup> # <br>
          <sup><b>3</b></sup> # <br>
          <sup><b>4</b></sup> # <br>
          </div>
      
          <div>Salary: <sup><b>5</b></sup> £ #</div>
      
          <div>Travel expenses included? <sup><b>6</b></sup> #</div>
      
        </div>
										`,
        answer: [
          `hotel receptionist`,
          `checking guests in`,
          `checking guests out`,
          `answering the phone`,
          `250 for a 35-hour week`,
          `No`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E6",
    audio: "Audios/1.27 audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E6answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 350,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // borderStyle:'dotted',
      fontWeight: "bold",
    },

    titleQuestion: [
      {
        num: "6",
        title: `
        <span>
        <headphone name='1.27' src='Audios/1.27 tieu de.mp3'></headphone>&ensp;Listen again. Complete the indirect questions with phrases from exercise 4.
        </span>
										`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height: 60px; font-size: 22px'>
        <div><b>1.</b> # if I could ask you some questions.</div>
        <div><b>2.</b> # what the job involves?</div>
        <div><b>3.</b> # what experience is required.</div>
        <div><b>4.</b> # what the salary is.</div>
      
        </div>
					`,
        answer: [
          `I was wondering`,
          `May I ask`,
          `I'd be interested to know`,
          `I'd like to know`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E7",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E7answerKey.png",
    component: T6v2,
    // maxLength: 13,
    inputSize: 300,
    textareaStyle: { width: 700, padding: 0 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // borderStyle:'dotted',
      // fontWeight: "bold",
    },

    titleQuestion: [
      {
        num: "7",
        title: `
        <span>
        Read the task. Prepare questions about the information below. Think of follow-up questions to find out more details.
        </span>
										`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2;'>
        <div style='background:rgb(229,239,230);padding:20px;border-radius:20px;width:800px'>
          <b>You have applied for a part-time holiday job as a sales 
          assistant in a supermarket. You are going for an interview 
          with the store manager. Prepare questions for the interview 
          about these four points:</b>
          <ul>
            <li>responsibilities</li>
            <li>personal qualities and experience required</li>
            <li>hours of work and salary</li>
            <li>travel expenses.</li>
          </ul>
        </div>
        <div>
          <div><b>1.</b> Responsibilities? <textarea id='0' row='2'></textarea></div>

          <div><b>2.</b> Personal qualities? <textarea id='1' row='2'></textarea></div>

          <div><b>3.</b> Experience required? <textarea id='2' row='2'></textarea></div>

          <div><b>4.</b> Hours of work? <textarea id='3' row='2'></textarea></div>

          <div><b>5.</b> Salary? <textarea id='4' row='2'></textarea></div>

          <div><b>6.</b> Travel expenses? <textarea id='5' row='2'></textarea></div>
        </div>
        </div>


										`,
        answer: [],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P54-E8",
    audio: "",

    video: "",
    recorder: true,
    // exerciseKey: "img/FriendsPlus/Page54/key/E5answerKey.png",
    component: T6v2,
    inputSize: 300,
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      paddingTop: 5,
      // height: 70,
    },

    titleQuestion: [
      {
        num: "8",
        title: `
        <span>
        Now do the speaking task above. Use your notes from exercise 7.
        </span>
				`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
        <textarea id='0' rows='8'>

										`,
        answer: [],
      },
    ],
  },
};

export default json;
