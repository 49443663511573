import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E1",
    // audio: "Audios/2.09.mp3",
    video: "",
    // recorder: true,
    // hideBtnFooter:true,
    isHiddenCheck: true,
    maxLength: 13,
    exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the sentences with the words and phrases below.
                `,
        color: "black",
      },
    ],
    inputSize: 220,
    component: T6v2,
    textAlign: "center",
    hintBox: [
      {
        src: [
          `apathetic`,
          `era`,
          `foolish`,
          `lethargic`,
          `the norm`,
          `peers`,
          `quick fix`,
          `straight`,
        ],
        borderColor: "none",
        width: 1200,
        fontColor: "rgb(148,36,144)",
      },
    ],
    questions: [
      {
        title: `
       <b> <hintBox id=0></hintBox></b>
      <div style='margin-top:20px; line-height:2.5'>
        <div><b>1</b>&ensp;She was feeling # , so she spent the afternoon watching DVDs.</div>
        
        <div><b>2</b>&ensp;She loves classical music, though most of her # prefer pop.</div>

        <div><b>3</b>&ensp;Unfortunately, there is no # for the problem of homelessness.</div>

        <div><b>4</b>&ensp;Some people complete their degree in four years, though three years is #.</div>

        <div><b>5</b>&ensp;It’s easy to become # about your future when there don’t seem to be many opportunities on offer.</div>

        <div><b>6</b>&ensp;We need to get these things # to avoid similar misunderstandings in future.</div>

        <div><b>7</b>&ensp;It’s quite an interesting play, but it isn’t very relevant to our #.</div>

        <div><b>8</b>&ensp;I felt # when I realised that I had sent the email to the wrong person.</div>

      </div>
						
												`,
        answer: [
          `lethargic`,
          `peers`,
          `quick fix`,
          `the norm`,
          `apathetic`,
          `straight`,
          `era`,
          `foolish`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E2",
    audio: "Audios/2.03 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E2answerKey.png",
    component: Circle_Write,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 180,
    titleQuestion: [
      {
        num: "2",

        title: `
        <headphone name='2.03' src='Audios/2.03 tieu de.mp3'></headphone>&ensp;Read the <span style='color:rgb(209,24,81)'>Listening Strategy</span> Then listen to four monologues and circle the<br> correct implication for each speaker.
								`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b `, //1
          `a <br> b `, //1
          `a <br> b `, //1
          `a <br> b `, //1
        ],
        answers: ["0-4", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page58/E2/1.jpg" style='width: 16cm' />
        <div style ='flex:1;width:100%;line-height:2'>
            <b>Monologue 1</b>&ensp;
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              Josh enjoyed the modern dance performance a lot <br>
              Josh didn’t really enjoy the modern dance performance
                    </div>
                       </div>

            <b>Monologue 2</b>&ensp;
		       What does the writer say about the future of communication?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      Hannah expected to get a part in the show. <br>
             Hannah didn’t expect to get a part in the show
		          </div>
            </div>

              <b>Monologue 3</b>&ensp;
		         In the future, people will use space for
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	       Ben is a big fan of modern art.<br>
               Ben is not very keen on modern art
		          </div>
            </div>
            <b>Monologue 4</b>&ensp;
            The best title for the article would be:
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              Fatima prefers modern pop to 1980s pop.<br>
               Fatima prefers 1980s pop to modern pop.

		          </div>
            </div>
           </div>
      </div>


        
      `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E3",
    audio: "Audios/2.03 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    maxLength: 20,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name='2.03' src='Audios/2.03 tieu de.mp3'></headphone>&ensp;Listen again and complete the sentences from 
the monologues. Use them to <br>help check your answers to exercise 2.
								`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2.5'>
       <div><b>1</b>&ensp; Let’s # #this: I’m glad Katy liked it!</div>
       <div><b>2</b>&ensp; That was # #surprise – there was only one part.</div>
       <div><b>3</b>&ensp; With a lot of the pictures, it was # # # the point.</div>
       <div><b>4</b>&ensp; He claims it’s much better than today’s pop music, but # #.</div>


      </div>
        `,
        answer: [
          `just`,
          `say`,
          `no`,
          `big`,
          `hard`,
          `to`,
          `see`,
          `that's`,
          `ridiculous`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E4",
    audio: "Audios/2.04 audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E4answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        <headphone name='2.04' src='Audios/2.04 tieu de.mp3'></headphone>&ensp; Read the poem and listen to three speakers talking 
        about poetry. <br>Which speaker is talking about the poem below?
		`,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    questions: [
      {
        title: `
      <div style='line-height:2.5;margin-left:200px'>
        <div>Speaker # is talking about this poem.</div>
        <img src="img/FriendsPlus/Page58/E4/1.jpg" width='90%' />


      </div>
        `,
        answer: [`3`],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E5",
    audio: "Audios/2.04 audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E5answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 80,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        <headphone name='2.04' src='Audios/2.04 tieu de.mp3'></headphone>&ensp;Listen again. Match speakers 1–3 with sentences A–E There are two extra sentences.
		`,
        color: "black",
      },
    ],
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: [`_`, `1`, `2`, `3`],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2.5'>
        <div><b>The speaker:</b></div> 

        <div style='display:flex'><b>A</b>&ensp; wanted a career in poetry.<select id=0></select></div>

        <div style='display:flex'><b>B</b>&ensp; used a poem to apologise to somebody.  <select id=1></select> </div>

        <div style='display:flex'><b>C</b>&ensp; made a new friend as the result of reading a poem. <select id=2></select> </div>

        <div style='display:flex'><b>D</b>&ensp; tried to persuade somebody to like a particular poem, but failed. <select id=3></select> </div>

        <div style='display:flex'><b>E</b>&ensp; enjoyed poems without really understanding them. <select id=4></select> </div>
      </div>
        `,
        answer: [`1`, `3`, `2`, `_`, `_`],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P58-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page58/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Imagine that your school has decided to change the duration of lessons. They will now last for ninety minutes each. Write a letter to your friend and talk about this change.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div style='display:flex'><b>1</b>&ensp; <span>What has changed in your school? How long are the lessons in your school now and how long were they before?</span></div>
        <div><b>2</b>&ensp; What do you think the advantage of longer lessons is? What benefits do your teachers think it will have?</div>
        <div><b>3</b>&ensp; What do you not like about this change?</div>
        <div><b>4</b>&ensp; Speculate about the future of this change. Do you think it will last? Say why or why not.</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page58/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
