import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P21-E1",
    // audio: 'Audios/1.07.mp3',
    video: "",
    component: T6,
    inputSize: 150,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 9,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the sentences with the words below. 
				`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: ["treasure", "cache", "seriously", "literally", "logbook"],
        borderColor: "none",
        width: 700,
        fontColor: "#93338e",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,

      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				<hintbox id=0></hintbox>
				<div style='position: relative; font-size: 21px; margin-left: 40px;'>
				<img style="width:19cm; height:13cm;" src='img/FriendsPlus/Page21/E1/1.jpg' />
        <div style="display: flex; line-height: 35px; position: absolute; top: 65px; left: 71px; width: 600px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div><br><br>
							<div><b>2&ensp;&ensp; </b></div><br>
							<div><b>3&ensp;&ensp; </b></div><br>
							<div><b>4&ensp;&ensp; </b></div><br>
              <div><b>5&ensp;&ensp; </b></div>
						</div>
			
						<div>
              <span>The expedition decided to # some food 
							about 400 metres from the base camp so that they could have food on the way back.</span><br>
							<span>A lot of information about the shipwreck could be 
							traced thanks to the # found months later.</span><br>
							<span>‘Letterboxing’ has become so popular that I am # thinking about trying it this summer. </span><br>
							<span>There were # thousands of runners in the city marathon. </span><br>
							<span>Books have been # of mankind since the early days.</span><br>
							
						</div>
					</div>

				</div>
				</div>
        
				`,
        answer: [`cache`, `logbook`, `seriously`, `literally`, `treasure`],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "FGF11-U2-P21-E2",
    // audio: 'Audios/1.10.mp3',
    video: "",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",
    inputSize: 1,
    isHiddenCheck: true,
    // checkUppercase: true,
    component: T6,
    textAlign: "left",
    // totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title:
          '<span style="font-size: 24px">Read the article. Are the sentences true (T) or false (F)?</span>',
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 26,
      textTransform: "uppercase",
    },

    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    // selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questionImage: [],
    questions: [
      {
        title: `
				<div style='position: relative'>
					<img style="width:29cm; height:12cm" src='img/FriendsPlus/Page21/E2/1.jpg' />
        	<div style='font-size:20px; '>

        	  <div style='display: flex; margin-bottom: -25px; margin-top:10px'><b>1&ensp;</b>This sports organisation brings activities to school for 
						young people. &ensp;<select id=0></select></div>
				
        	  <div style='display: flex'><b>2&ensp;</b>The organisation believes that sport helps young people in 
						other areas of their lives. &ensp;<select id=1></select></div>
				
        	</div> 

				</div>
        `,
        answer: ["F", "T"],
      },
    ],
  },
  // 3: {
  // 	unit: 'Unit 2',
  // 	id: 'FGF11-U2-P21-E3',
  // 	audio: '',
  // 	video: '',
  // 	exerciseKey: 'img/FriendsPlus/Page21/Key/E3answerKey.png',
  // 	component: DesignUnderLine,
  // 	totalInput: 4,
  // 	isHiddenCheck: true,
  // 	titleQuestion: [
  // 		{
  // 			num: '3',
  // 			title: `
  //       Read the <span style='color: rgb(67,79,161)'>Reading Strategy</span>. Then circle the correct answers.
  // 		  `,
  // 			color: 'black',
  // 		},
  // 	],
  // 	questionImage: [
  // 		// IMG
  // 		//Q1
  // 		[{ url: 'img/FriendsPlus/Page21/E3/1.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/2.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/3.jpg', input: 1 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/4.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/5.jpg', input: 2 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/6.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/7.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/8.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/9.jpg', input: 1 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/10.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/11.jpg', input: 2, isCorrect: true },
  // 			{ url: 'img/FriendsPlus/Page21/E3/12.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/13.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/14.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/15.jpg', input: 1 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/16.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/17.jpg', input: 2 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/18.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/19.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/20.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/21.jpg', input: 1, isCorrect: true },
  // 			{ url: 'img/FriendsPlus/Page21/E3/22.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/23.jpg', input: 2 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/24.jpg' },
  // 		],
  // 		//Q2
  // 		[{ url: 'img/FriendsPlus/Page21/E3/25.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/26.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/27.jpg', input: 3 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/28.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/29.jpg', input: 4, isCorrect: true },
  // 			{ url: 'img/FriendsPlus/Page21/E3/30.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/31.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/32.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/33.jpg', input: 3, isCorrect: true },
  // 			{ url: 'img/FriendsPlus/Page21/E3/34.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/35.jpg', input: 4 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/36.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/37.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/38.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/39.jpg', input: 3 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/40.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/41.jpg', input: 4 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/42.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/43.jpg' }],
  // 		[
  // 			{ url: 'img/FriendsPlus/Page21/E3/44.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/45.jpg', input: 3 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/46.jpg' },
  // 			{ url: 'img/FriendsPlus/Page21/E3/47.jpg', input: 4 },
  // 			{ url: 'img/FriendsPlus/Page21/E3/48.jpg' },
  // 		],
  // 		[{ url: 'img/FriendsPlus/Page21/E3/49.jpg' }],
  // 	],
  // },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P21-E3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",
    audio: "Audios/1.22 audio.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color: rgb(67,79,161)'>Reading Strategy</span>. Then circle the correct answers.
			  `,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
          border: '2px solid white',
          padding:'0px 4px',
      
          },
          selectWordStyle: {
          padding:'0px 4px',
          border: '2px solid black',
          }, 
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
        ],
        answers: ["0-12", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
			<div style='font-size: 22px'>
			
            <b>1</b>&ensp;
            Nearly three quarters of teenagers 
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              aspire to play better sport.<br>have found the sport they are looking for.<br>haven’t played any sport.<br>would like to play sport if it was less expensive. 


            	</div>
            </div>

            <b>2</b>&ensp;
            The aim of the charity is to 
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      help communities to get fitter.<br>organise local sporting activities cheaply.<br>advise young people on how to succeed in life.<br>provide intensive sports training for teenagers.

		          </div>
            </div>

              <b>3</b>&ensp;
              Adventure sports were
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              chosen by boys only.<br>selected by both boys and girls.<br>more popular than dance classes.<br>not as popular as football.

		          </div>
            </div>

             <b>4</b>&ensp;
						 The writer wants  
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              to encourage teenagers to join the sports project.<br>young children to contact the organisation.<br>young people to pay for sporting activities.<br>to help young people at home.

		          </div>
            </div>
						
           </div>
					 </div>
					 <img style="width:15cm; height:9cm" src='img/FriendsPlus/Page21/E3/1.jpg' />
      `,
    },
  },
};

export default json;
