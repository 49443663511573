import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-U3-P24-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 100,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 10,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the labels.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `

				<div style="display: flex; font-size: 20px">
				<img style="width:11cm; height:17cm" src='img/FriendsPlus/Page24/E1/1.jpg' />
					<div style="display: flex; margin-top: 10px; margin-left: 50px">
						<div style='margin-right: 20px'>
							<div><b>1 </b> </div>
							<div><b>2 </b> </div>
							<div><b>3 </b> </div>
							<div><b>4 </b> </div>
							<div><b>5 </b> </div>
							<div><b>6 </b> </div>
							<div><b>7 </b> </div>
							<div><b>8 </b> </div>
						</div>
			
						<div>
							<span>s#</span><br>
							<span>f#</span><br>
							<span>l#</span><br>
							<span>c#</span><br>
							<span>j#</span><br>
							<span>c#</span><br>
							<span>e#</span><br>
							<span>e#</span>
						</div>
					</div>
					
						<div style="display: flex; margin-top: 10px; margin-left: 50px">
							<div style='margin-right: 20px'>
								<div><b>9 </b> </div>
								<div><b>10 </b> </div>
								<div><b>11 </b> </div>
								<div><b>12 </b> </div>
								<div><b>13 </b> </div>
								<div><b>14 </b> </div>
								<div><b>15 </b> </div>
								<div><b>16 </b> </div>
							</div>
				
							<div>
								<span>s#</span><br>
								<span>w#</span><br>
								<span>t#</span><br>
								<span>w#</span><br>
								<span>h#</span><br>
								<span>t#</span><br>
								<span>k#</span><br>
								<span>s#</span>
							</div>
						</div>
						<div style="display: flex; margin-top: 10px; margin-left: 50px">
							<div style='margin-right: 20px'>
								<div><b>17 </b> </div>
								<div><b>18 </b> </div>
								<div><b>19 </b> </div>
								<div><b>20 </b> </div>
								<div><b>21 </b> </div>
								<div><b>22 </b> </div>
								<div><b>23 </b> </div>
								<div><b>24 </b> </div>
							</div>
				
							<div>
								<span>e#</span><br>
								<span>n#</span><br>
								<span>b#</span><br>
								<span>s#</span><br>
								<span>t#</span><br>
								<span>c#</span><br>
								<span>a#</span><br>
								<span>h#</span>
							</div>
					</div>
				</div>
				`,
        answer: [
          `kull`,
          `orehead`,
          `ip`,
          `hin`,
          `aw`,
          `heek`,
          `yebrow`,
          `yelid`,
          `kin`,
          `rist`,
          `humb`,
          `aist`,
          `ip`,
          `high`,
          `nee`,
          `houlder`,
          `lbow`,
          `ail`,
          `ottom`,
          `hin`,
          `oe`,
          `alf`,
          `nkle`,
          `eel`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-U3-P24-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    isHiddenCheck: true,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page24/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the accidents and injuries with the verbs below.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "bang",
          "break",
          "bruise",
          "burn",
          "cut",
          "have",
          "have",
          "sprain",
          "twist",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
				<div style="display: flex; font-size: 22px; line-height: 50px">
					<div style="display: flex; margin-top: 10px; margin-right: 60px">
						<div style='margin-right: 10px'>
							<div><b>1 </b>&ensp;# </div>
							<div><b>2 </b>&ensp;# </div>
							<div><b>3 </b>&ensp;# </div>
							<div><b>4 </b>&ensp;# </div>
							<div><b>5 </b>&ensp;# </div>
							
						</div>
			
						<div>
							<span>your ankle</span><br>
							<span>your wrist</span><br>
							<span>a bone</span><br>
							<span>yourself</span><br>
							<span>a bad nosebleed</span><br>
						</div>
					</div>

					<div style="display: flex; margin-top: 10px">
						<div style='margin-right 10px'>
              <div><b>6 </b>&ensp;# </div>
							<div><b>7 </b>&ensp;# </div>
							<div><b>8 </b>&ensp;# </div>
							<div><b>9 </b>&ensp;# </div>
						</div>
			
						<div>
							<span>a black eye</span><br>
							<span>yourself</span><br>
							<span>your head</span><br>
							<span>yourself</span><br>
						</div>
					</div>
				</div>
        
				`,
        answer: [
          `twist`,
          `sprain`,
          `break`,
          `bruise / burn / cut`,
          `have`,
          `have`,
          `bruise / burn / cut`,
          `bang`,
          `bruise / burn / cut`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-U3-P24-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    isHiddenCheck: true,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page24/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Match the words below with the definitions.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "blood",
          "brain",
          "heart",
          "intestine",
          "kidneys",
          "lungs",
          "muscle",
          "ribs",
          "skull",
          "spine",
          "stomach",
          "throat",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
				<div style="font-size: 20px; line-height: 35px">
					<div style="display: flex; margin-top: 10px; margin-right: 60px">
						<div style='margin-right: 10px'>
							<div><b>1 </b>&ensp; </div>
							<div><b>2 </b>&ensp; </div>
							<div><b>3 </b>&ensp; </div>
							<div><b>4 </b>&ensp; </div>
							<div><b>5 </b>&ensp; </div>
              <div><b>6 </b>&ensp; </div>
							<div><b>7 </b>&ensp; </div>
							<div><b>8 </b>&ensp; </div>
							<div><b>9 </b>&ensp; </div>
							<div><b>10 </b>&ensp; </div>
							<div><b>11 </b>&ensp; </div>
							<div><b>12 </b>&ensp; </div>
						</div>
			
						<div>
							<span>You use it to think. #</span><br>
							<span>It allows you to move a part of your body. #</span><br>
							<span>It’s made of bone and it runs down your back. #</span><br>
							<span>The red liquid in your body. #</span><br>
							<span>It’s a bone that surrounds your brain. #</span><br>
              
              <span>The part of the neck where food and air go. #</span><br>
							<span>It’s in your chest and it pumps blood around your body. #</span><br>
							<span>When you eat, the food goes down your throat to this 
              place. #</span><br>
							<span>They’re in your chest. You use them to breathe. #</span><br>
							<span>They are bones that go round your chest and protect your 
              heart and lungs. #</span><br>
              <span>The long tube below your stomach that digests food and 
              gets rid of waste. #</span><br>
              <span>They clean your blood. #</span><br>
						</div>
					</div>

					
				</div>
        
				`,
        answer: [
          `brain`,
          `muscle`,
          `spine`,
          `blood`,
          `skull`,
          `throat`,
          `heart`,
          `stomach`,
          `lungs`,
          `ribs`,
          `intestine`,
          `kidneys`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FGF11-U3-P24-E3",
    audio: "Audios/1.12 audio.mp3",
    video: "",
    component: T6,
    inputSize: 700,
    isHiddenCheck: true,
    // checkUppercase: true,
    // maxLength: 90,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page24/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone name='1.12' src='Audios/1.12 tieu de.mp3'></headphone> Listen to three dialogues. What problem does each 
        person have?
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style='margin-top: 30px'><b>The patient has:</b>
        <div style="display: flex; margin-left: 40px; font-size: 22px;line-height: 50px">
          
        <div style='margin-right: 20px'>
            <div><b>1 </b></div>
            <div><b>2 </b></div>
            <div><b>3 </b></div>
            
          </div>
        
        <div>
          <span>#</span><br>
          <span>#</span><br>
          <span>#</span>
          
          
        </div>

        </div>
        </div>
				`,
        answer: [
          `complisprained her wrist`,
          `bruised his leg`,
          `burned herself`,
        ],
      },
    ],
  },
};

export default json;
